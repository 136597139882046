import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AddTicketCommentInterface, UpdateTicketCommentInterface } from '@simOn/ticket/comments/models';
import {
  ITicketComment,
  ITicketStatusUpdate,
  IUpdateTicket,
  Ticket,
} from '@simOn/ticket/element/models';
import { UserInfoCard } from '@simOn/user/showcard/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getTicket$(ticketId: string): Observable<Ticket> {
    return this.http.get<Ticket>(`${this._apiUrl}/Tickets/GetTicket?TicketId=${ticketId}`);
  }

  getTickets$(): Observable<Partial<Ticket[]>> {
    return this.http.get<Partial<Ticket[]>>(`${this._apiUrl}/Tickets/GetTickets`);
  }

  getUsersForTicket$(ticketId: string): Observable<UserInfoCard[]> {
    return this.http.get<UserInfoCard[]>(
      `${this._apiUrl}/Tickets/GetUsersAssignableToTicket${!!ticketId ? `?TicketId=${ticketId}` : ''}`
    );
  }
  createTicket$(payload: IUpdateTicket): Observable<string> {
    return this.http.post<string>(`${this._apiUrl}/Tickets/CreateTicket`, payload);
  }

  addTicketComment$(payload: AddTicketCommentInterface): Observable<ITicketComment> {
    return this.http.post<ITicketComment>(`${this._apiUrl}/Tickets/AddTicketComment`, payload);
  }

  updateTicket$(payload: IUpdateTicket): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Tickets/UpdateTicket`, payload);
  }

  markTicketAsRead$(ticketId: string): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Tickets/MarkTicketAsRead?TicketId=${ticketId}`, {});
  }

  updateTicketComment$(payload: UpdateTicketCommentInterface): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Tickets/UpdateTicketComment`, payload);
  }

  updateTicketStatus$(payload: ITicketStatusUpdate): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Tickets/UpdateTicketStatus`, payload);
  }

  deleteTicket$(ticketId: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Tickets/DeleteTicket?TicketId=${ticketId}`);
  }

  deleteAttachmentsFromTicket$(ticketId: string, attachmentsIds: string[]): Observable<string> {
    return this.http.delete<string>(
      `${this._apiUrl}/Tickets/DeleteAttachmentsFromTicket?TicketId=${ticketId}&AttachmentIds=${attachmentsIds}`
    );
  }

  deleteTicketComment$(ticketCommentId: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Tickets/DeleteTicketComment?TicketCommentId=${ticketCommentId}`);
  }
}
