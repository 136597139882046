<button
  id="user-panel-trigger"
  class="flex align-items-center justify-content-center user-panel-trigger"
  (click)="openPanel()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <div class="flex align-items-center justify-content-center user-panel-trigger__content">
    {{ userName() | initials }}
    <div class="flex align-items-center justify-content-center user-panel-trigger__content__arrow">
      <sim-icon [sizeInPx]="6" color="var(--ui-secondary-darker)" icon="down" />
    </div>
  </div>
</button>

@if(user(); as user){
<ng-template
  cdkConnectedOverlay
  cdkOverlayOuts
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="userPanelVisible"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'transparent-backdrop'"
  (backdropClick)="userPanelVisible = !userPanelVisible"
>
  <div class="logged-user-panel-content" [class.logged-user-panel-content-visible]="userPanelVisible">
    <div class="panel-text-place">
      <span class="sim-text-ellipsis panel-text" i18n="@@MAIN_SCREEN_USER_PANEL_TITLE">SIM-ON account</span>
      <span class="sim-text-ellipsis panel-bold-text">{{ user.name }}</span>
      <span class="sim-text-ellipsis panel-text">{{ user.email }}</span>
    </div>
    <div>
      <a
        id="user-panel-subscription"
        sim-button
        simColor="primary"
        class="panel-button"
        routerLink="/subscriptions"
        queryParamsHandling="preserve"
        (click)="hideAllPanels()"
        ><sim-icon icon="dollar"></sim-icon
        ><ng-container i18n="@@MAIN_SCREEN_USER_PANEL_SUBSCRIPTION_BUTTON">My subscription plan</ng-container></a
      >
      <button
        id="user-panel-preferences"
        sim-button
        simColor="primary"
        class="panel-button"
        (click)="openUserPreferences()"
      >
        <sim-icon icon="settings"></sim-icon>
        <ng-container i18n="@@MAIN_SCREEN_USER_PANEL_PREFERENCES_BUTTON">Personal preferences</ng-container>
      </button>
      <a
        [href]="simlabCloudRedirect"
        target="_blank"
        id="user-panel-cloud"
        sim-button
        simColor="secondary"
        class="panel-button"
      >
        <sim-icon icon="cloud"></sim-icon>
        <ng-container i18n="@@MAIN_SCREEN_USER_PANEL_CLOUD_BUTTON">SIMLAB cloud</ng-container>
      </a>
    </div>

    <hr class="panel-hr" />

    <a
      href="https://sim-on.com/contact-us-form/"
      target="_blank"
      id="user-panel-contact"
      sim-button
      simColor="secondary"
      class="panel-button"
    >
      <sim-icon icon="mail"></sim-icon>
      <ng-container i18n="@@MAIN_SCREEN_USER_PANEL_CONTACT_BUTTON">Contact us</ng-container>
    </a>

    <button i18n="@@GENERAL_LOG_OUT" id="user-panel-logout" sim-flat-button simColor="accent" (click)="logOut()">
      Log Out
    </button>
  </div>
</ng-template>
}
