import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ApartmentCreateInterface,
  ApartmentInterface,
  TApartmentAuthorizationResult,
  TApartmentOwnerInfo
} from '@simOn/space/information/models';
import { API_URL } from '@simOn/utils/tokens';
import { ModelSweep } from '@simlab/matterport/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApartmentsApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getUserApartments$(): Observable<ApartmentInterface[]> {
    return this.http.get<ApartmentInterface[]>(`${this._apiUrl}/apartments/GetApartments`);
  }

  getUserApartment$(): Observable<ApartmentInterface> {
    return this.http.get<ApartmentInterface>(`${this._apiUrl}/Apartments/GetApartment`);
  }

  updateApartment$(apartment: Partial<ApartmentCreateInterface>): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Apartments/UpdateApartment`, apartment);
  }

  deleteApartment$(apartmentId?: string): Observable<ApartmentCreateInterface> {
    let header;
    if (apartmentId) {
      header = new HttpHeaders().set('apartmentId', apartmentId);
    }
    return this.http.delete<ApartmentCreateInterface>(`${this._apiUrl}/Apartments/DeleteApartment`, {
      headers: header
    });
  }

  getUserApartmentShort$(): Observable<ApartmentInterface> {
    return this.http.get<ApartmentInterface>(`${this._apiUrl}/apartments/GetApartment?FetchRooms=1`);
  }

  createApartment$(apartment: ApartmentCreateInterface): Observable<TApartmentAuthorizationResult> {
    return this.http.post<TApartmentAuthorizationResult>(`${this._apiUrl}/apartments/CreateApartment`, apartment);
  }

  QueryParamsFromObject(object: {}): string {
    let result = '';
    try {
      Object.keys(object).forEach((propertyName) => {
        result += `?${propertyName}=${object[propertyName as keyof object]}`;
      });
    } catch (e) {
      console.error('[ERROR] simlabapiservice.interfaces, error: ', e);
    }
    return result;
  }

  updateScanModelScanPoints$(payload: { scanModelId: string; sweeps: ModelSweep[] }): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/MatterportModel/UpdateScanModelScanPoints`, payload);
  }

  getApartmentOwnerInfo$(): Observable<TApartmentOwnerInfo> {
    return this.http.get<TApartmentOwnerInfo>(`${this._apiUrl}/Apartments/GetApartmentOwnerInfo`);
  }
}
