import { ICustomComponent } from '@simlab/simlab-facility-management/scene-object';
import { TagComponent } from '../components/tag-component';
import { Observable, of, tap } from 'rxjs';
import { IStartPlacingConfig } from '@simlab/simlab-facility-management/common';
import { Injectable } from '@angular/core';

export interface IFeatureTags {
  get component(): ICustomComponent<TagComponent>;
  startPlacingComponent$: (config: IStartPlacingConfig) => Observable<boolean>;
}

@Injectable()
export class FeatureTagsService implements IFeatureTags {
  get component(): ICustomComponent<TagComponent> {
    throw new Error('Method not implemented.');
  }
  startPlacingComponent$(config: IStartPlacingConfig): Observable<boolean> {
    return of(true).pipe(tap((e) => console.log('elooo')));
  }
}
