import { Injectable, OnDestroy } from '@angular/core';
import { getElementPosition } from '@simlab/simlab-facility-management/scene-object';
import {
  Observable,
  catchError,
  concat,
  finalize,
  firstValueFrom,
  from,
  map,
  mapTo,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { Vector3 } from 'three';
import { MpSdk } from '../../../assets/bundle/sdk';
import { MatterportServiceBase } from '../base/matterport-base';
import { IPositionController, MoveToClosestSweepConfig, MoveToSweepConfig } from '../models/dto';
import { MatterportManagerService } from './matterport-manager.service';
import { MatterportSceneStateAccessService } from './matterport-scene-state-access.service';

@Injectable()
export class MatterportPositionControllerService
  extends MatterportServiceBase
  implements OnDestroy, IPositionController {
  private lastMode!: MpSdk.Mode.Mode;
  constructor(
    private readonly matterportManager: MatterportManagerService,
    private readonly matterportSceneStateAccess: MatterportSceneStateAccessService
  ) {
    super(matterportManager);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _init(): void { }

  private _onMatterMove = (pose: MpSdk.Camera.Pose) => {
    this.lastMode = pose.mode;
  };
  override ngOnDestroy(): void {
    this.sdk?.off(this.sdk.Camera.Event.MOVE, this._onMatterMove);
    super.ngOnDestroy();

    // console.log('MatterportPositionControllerService - destroy');
  }
  goToPositionAndLookAt$(position: Vector3): Observable<void> {
    this.sdk.on(this.sdk.Camera.Event.MOVE, this._onMatterMove);
    this.lastMode = this.sdk.Mode.Mode.TRANSITIONING;
    return from(
      this.sdk.Mattertag.add([
        {
          label: '',
          description: '',
          anchorPosition: {
            x: position.x,
            y: position.y,
            z: position.z,
          },
          iconId: 'INFO',
          stemVector: {
            x: 0,
            y: 0,
            z: 0,
          },
          color: {
            r: 0.0,
            g: 0.0,
            b: 1.0,
          },
          floorIndex: 0,
        },
      ])
    ).pipe(
      take(1),
      takeUntil(this._destroy),
      switchMap((addedTagSids: string[]) => {
        const addedTagSid = addedTagSids[0];
        return concat(
          from(
            this.sdk.Mattertag.navigateToTag(
              addedTagSid,
              this.sdk.Sweep.Transition.INSTANT
            )
          ),
          from(this.sdk.Mattertag.remove(addedTagSids))
        );
      }),
      catchError((e: any) => {
        console.log(e);
        return of(undefined);
      }),
      finalize(() =>
        this.sdk.off(this.sdk.Camera.Event.MOVE, this._onMatterMove)
      ),
      mapTo(undefined)
    );
  }

  moveToSweep$(config: MoveToSweepConfig): Observable<void> {
    const sweepId = config.id;
    const rotation = config.rotation;
    const transition = config.translation;
    const transitionTime = config.transitionTime;
    this.lastMode = this.sdk.Mode.Mode.TRANSITIONING;
    this.sdk.on(this.sdk.Camera.Event.MOVE, this._onMatterMove);

    return from(
      this.sdk.Sweep.moveTo(sweepId, {
        rotation: rotation,
        transition: transition,
        transitionTime: transitionTime,
      })
    ).pipe(
      take(1),
      takeUntil(this._destroy),
      catchError(() => of(undefined)),
      finalize(() => {
        this.sdk.off(this.sdk.Camera.Event.MOVE, this._onMatterMove);
      }),
      mapTo(undefined)
    );
  }

  moveToClosestSweep$(config: MoveToClosestSweepConfig): Observable<void> {
    if (!this.sdk) throw new Error('Sdk not loaded');
    const { position, rotation, translation, transitionTime } = config;
    this.sdk?.on(this.sdk.Camera.Event.MOVE, this._onMatterMove);
    this.lastMode = this.sdk.Mode.Mode.TRANSITIONING;

    return this._findClosestSweep$(position).pipe(
      take(1),
      takeUntil(this._destroy),
      switchMap((closestSweepId: string) =>
        from(
          this.sdk.Sweep.moveTo(closestSweepId, {
            rotation: rotation,
            transition: translation,
            transitionTime: transitionTime,
          })
        ).pipe(
          catchError((e) => {
            console.log(e);
            return of(undefined);
          })
        )
      ),
      catchError(() => of(undefined)),
      finalize(() => {
        this.sdk.off(this.sdk.Camera.Event.MOVE, this._onMatterMove);
      }),
      mapTo(undefined)
    );
  }

  moveToClosestSweepWithOffset$(
    config: MoveToClosestSweepConfig
  ): Observable<void> {
    const transformConverter = this.matterportManager.transformConverter;
    const { x, y, z } = transformConverter.toMatterportPosition(
      new Vector3(config.position.x, config.position.y, config.position.z)
    );
    config.position = new Vector3(x, y, z);
    config.rotation = transformConverter.toMatterportRotation(
      new Vector3(config.rotation.x, config.rotation.y)
    );

    return this.moveToClosestSweep$(config);
  }

  moveTo$(
    position: Vector3,
    normal: Vector3 = new Vector3(0, 0, 0),
    stem: number = 0
  ): Observable<void> {
    const transformConverter = this.matterportManager.transformConverter;
    const { x, y, z } = transformConverter.toMatterportPosition(position);
    const {
      x: xn,
      y: yn,
      z: zn,
    } = transformConverter.toMatterportPosition(normal);
    const calcPosition = getElementPosition(
      new Vector3(x, y, z),
      new Vector3(xn, yn, zn),
      stem
    );
    return this.goToPositionAndLookAt$(
      new Vector3(calcPosition.x, calcPosition.y, calcPosition.z)
    );
  }

  moveToMattertag$(mattertagId: string): Observable<string> {
    return from(
      this.sdk.Mattertag.navigateToTag(
        mattertagId,
        this.sdk.Sweep.Transition.INSTANT
      )
    );
  }
  private _findClosestSweep$(position: Vector3): Observable<string> {
    let distance = 999999999999;
    let closestSweepId = 'Not Found!';
    return this.matterportSceneStateAccess.getAvailableSweeps$().pipe(
      take(1),
      takeUntil(this._destroy),
      map((sweeps: MpSdk.Sweep.ObservableSweepData[] | null) => {
        if (sweeps)
          sweeps.forEach((sweep) => {
            if (sweep.enabled === true) {
              const s = sweep;
              const vx = position.x - s.position.x;
              const vy = position.y - s.position.y;
              const vz = position.z - s.position.z;
              const _distance = Math.sqrt(vx * vx + vy * vy + vz * vz);
              if (distance > _distance) {
                distance = _distance;
                closestSweepId = '_id' in s && typeof s._id === 'string' ? s['_id'] : s.id;
              }
            }
          });
        return closestSweepId;
      })
    );
  }

  async setSweepsActive(enable: boolean): Promise<void> {
    const sweeps = await firstValueFrom(
      this.matterportSceneStateAccess.getAvailableSweeps$()
    );
    if (sweeps) {
      const ids = sweeps.map((sweep) => '_id' in sweep && typeof sweep._id === 'string' ? sweep['_id'] : sweep.id);
      if (enable) {
        setTimeout(() => this.sdk.Sweep.enable(...ids), 500); //delay to prevent move forward
      } else this.sdk.Sweep.disable(...ids);
    }
  }

  setSweepsActive$(enable: boolean): Observable<void> {
    return this.matterportSceneStateAccess.getAvailableSweeps$().pipe(
      mergeMap((sweeps: MpSdk.Sweep.ObservableSweepData[] | null) => {
        if (sweeps) {
          const ids = sweeps.map((sweep) => '_id' in sweep && typeof sweep._id === 'string' ? sweep['_id'] : sweep.id);
          //NOTE: (olek) cannot use iif becouse iif(()=> true, from(...)) does not work. first you need to use of(true).pipe(...)
          return enable
            ? from(this.sdk.Sweep.enable(...ids))
            : from(this.sdk.Sweep.disable(...ids));
        }
        return of(undefined);
      })
    );
  }
}
