import {
  Directive,
  ElementRef,
  inject,
  Renderer2,
  viewChild,
} from '@angular/core';
import { THint } from '../types/hint';

@Directive({
  selector: '[simlabHint]',
  standalone: true,
})
export class HintDirective {
  readonly renderer = inject(Renderer2);
  readonly hintEle = viewChild.required('hint', { read: ElementRef });

  setHint(hint: THint) {
    const el = this.hintEle().nativeElement as HTMLElement;
    const { position, hintMessage, backgroundColor, fontColor } = hint;
    if (position) {
      if (position.top) {
        this.renderer.setStyle(el, 'top', position.top);
      }
      if (position.bottom) {
        this.renderer.setStyle(el, 'bottom', position.bottom);
      }
      if (position.left) {
        this.renderer.setStyle(el, 'left', position.left);
      }
      if (position.right) {
        this.renderer.setStyle(el, 'right', position.right);
      }
    }
    if (backgroundColor) {
      this.renderer.setStyle(el, 'background-color', backgroundColor);
    }
    if (fontColor) {
      this.renderer.setStyle(el, 'color', fontColor);
    }
    if (hintMessage) {
      if (!(hintMessage instanceof Element)) {
        (el as HTMLElement).innerHTML = hintMessage;
      } else {
        el.childNodes.forEach((node) => el.removeChild(node));
        el.appendChild(hintMessage);
      }
      this.renderer.setStyle(el, 'display', 'flex');
    } else {
      this.renderer.setStyle(el, 'display', 'none');
    }
  }
}
