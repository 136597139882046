import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SimIconComponent } from '@simOn/ui/sim-icon';
import { SimTooltipComponent } from '@simOn/ui/sim-tooltip';

@Component({
  selector: 'layout-sim-sidemenu-item',
  standalone: true,
  imports: [SimIconComponent, RouterModule, SimTooltipComponent],
  styleUrl: './sim-sidemenu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <a
      class="sim-sidemenu-item flex align-center gap-6"
      [class.sim-sidemenu-item--expanded]="!isShrinked()"
      [class.sim-sidemenu-item--disabled]="isDisabled()"
      [routerLink]="link()"
      routerLinkActive="sim-sidemenu-item--active"
    >
      <div class="sim-sidemenu-item__wrapper">
        <div class="sim-sidemenu-item__wrapper__icon-wrapper flex justify-content-center align-items-center">
          <sim-icon sizeType="medium" class="sim-sidemenu-item__icon" [icon]="icon()" />
        </div>
        <span class="sim-sidemenu-item__wrapper__content">
          <ng-content></ng-content>
        </span>
      </div>
    </a>
  `
})
export class SimSidemenuItemComponent {
  icon = input<string>();
  link = input<string>();
  isDisabled = input(false, { transform: booleanAttribute });
  isShrinked = input(false, { transform: booleanAttribute });
}
