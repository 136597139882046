import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { IScanControllerService, TScanControllerService, TScansFeatures } from '../models/scan-controller.interface';
import { IScanViewerComponent } from '../models/scan-viewer.interface';

export const LOAD_MATTERTAGS = new InjectionToken<string>('load mattertags');

export const SpaceBaseComponent = new InjectionToken<{ comp: Observable<Type<unknown>> }>('SpaceBase');
export const serviceBaseComponent = new InjectionToken<Observable<IScanControllerService<any, any>>>(
  'serviceBaseComponent'
);

export const IScanControllerToken = new InjectionToken<TScanControllerService<any, any, TScansFeatures>>(
  'IScanControllerToken'
);

export const spaceViewerLoaderDirective = new InjectionToken<IScanViewerComponent<any>>('spaceViewerLoaderDirective');

export const IScanTypeToken = new InjectionToken<'MATTERPORT' | 'NAVVIS'>('IScanTypeToken');
