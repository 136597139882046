import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AssetCreateInterface } from '@simOn/asset/element/models';
import { TAsset, TCreateAsset, TUpdateAsset } from '@simOn/asset/modifying/models';
import { TScanProvider } from '@simOn/common/scan';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetsApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getAsset(assetGuid: string): Observable<TAsset> {
    return this.http.get<TAsset>(
      `${this._apiUrl}/Assets/GetAsset?assetGuid=${assetGuid}&UserDateTime=${new Date().toJSON()}`
    );
  }

  getAssets(): Observable<TAsset[]> {
    return this.http.get<TAsset[]>(`${this._apiUrl}/Assets/GetAssets`);
  }

  getUsedScanTags(scanModelId: string, scanProviderType: TScanProvider): Observable<string[]> {
    let params = new HttpParams();
    params = params.append('id', scanModelId);
    params = params.append('scanProviderType', scanProviderType);
    return this.http.get<string[]>(`${this._apiUrl}/Assets/GetUsedScanTags?${params.toString()}`);
  }

  getNotAssignedAssets(apartmentGuid: string): Observable<TAsset[]> {
    return this.http.get<TAsset[]>(`${this._apiUrl}/Assets/GetNotAssignedAssets?apartmentGuid=${apartmentGuid}`);
  }

  createAsset(assetCreateData: TCreateAsset): Observable<TAsset> {
    return this.http.post<TAsset>(`${this._apiUrl}/Assets/CreateAsset`, assetCreateData);
  }
  createAssets(body: AssetCreateInterface[]): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Assets/CreateAssets`, {
      assets: body
    });
  }

  updateAsset(assetCreateData: TUpdateAsset): Observable<TAsset> {
    return this.http.put<TAsset>(`${this._apiUrl}/Assets/UpdateAsset`, assetCreateData);
  }

  deleteAsset(assetGuid: string): Observable<string> {
    //@TODO ŁM add type
    return this.http.delete<string>(`${this._apiUrl}/Assets/DeleteAsset?assetGuid=${assetGuid}`);
  }

  orderAssetsForRoom(roomGuid: string, ...assets: string[]): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Assets/OrderAssetsForRoom`, { roomGuid, assets });
  }
}
