import { CdkOverlayOrigin, Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { BreakpointService } from '@simOn/ui/breakpoint';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { UiBadgeModule } from '@simOn/ui/ui-badge';
import { UsersFacade } from '@simOn/user/last-visited/state';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { SimActiveUsersComponent } from './sim-active-users.component';

const OFFSET_TOP = '50px';
@Component({
  selector: 'user-online-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SimButtonModule, SimIconModule, UiBadgeModule, AsyncPipe, NgIf, SimActiveUsersComponent, OverlayModule],
  styleUrls: ['./sim-users-online.component.scss'],
  template: `
    <button
      class="users"
      data-cy="users-online-btn"
      *ngIf="onlineUsersCount$ | async; let count"
      sim-icon-button
      ui-badge
      [badge]="count"
      badgeColor="accent"
      (click)="openPanel()"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <sim-icon icon="people_alt" [color]="lightMode ? 'white' : '#676767'" sizeType="medium"></sim-icon>
      <span class="users__active"></span>
    </button>
  `
})
export class SimUserOnlineComponent implements OnInit, OnDestroy {
  private readonly _usersFacade = inject(UsersFacade);
  private readonly _breakpoint = inject(BreakpointService);
  private readonly _overlay: Overlay = inject(Overlay);
  private readonly _destroy = new Subject<void>();
  private _overlayRef!: OverlayRef;
  @Input() lightMode = false;
  @ViewChild('trigger') trigger!: CdkOverlayOrigin;

  readonly onlineUsersCount$ = this._usersFacade.onlineUsersCount$;
  readonly onlineUsers$ = this._usersFacade.onlineUsers$;
  ngOnInit(): void {
    this._overlayRef = this._overlay.create({
      backdropClass: 'transparent-backdrop',

      panelClass: ['scrollbar', 'overlay-background', 'active-users'],
      maxHeight: `calc(100dvh - ${OFFSET_TOP})`,
      hasBackdrop: true,
      disposeOnNavigation: true
    });
  }
  ngOnDestroy(): void {
    this._destroy.next();
    this._overlayRef.dispose();
  }
  async openPanel() {
    const globalPositionStrategy = this._overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically()
      .top(OFFSET_TOP);
    const mobile = await firstValueFrom(this._breakpoint.isMobileOrTablet$);

    const originPositionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(this.trigger.elementRef.nativeElement)
      .setOrigin(this.trigger.elementRef.nativeElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }
      ]);
    this._overlayRef
      .backdropClick()
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this._overlayRef.detach();
        this._destroy.next();
      });
    this._overlayRef.updatePositionStrategy(mobile ? globalPositionStrategy : originPositionStrategy);
    const activeUsersPortal = new ComponentPortal(SimActiveUsersComponent);
    const componentRef = this._overlayRef.attach(activeUsersPortal);
    this.onlineUsers$
      .pipe(takeUntil(this._destroy))
      .subscribe((activeUsers) => componentRef.setInput('apartmentUsers', activeUsers));
  }
}
