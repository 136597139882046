import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Ticket } from '@simOn/ticket/element/models';
import { State } from '@simOn/utils';
import * as TicketsReducer from './ticket.reducers';

export const SelectTicketState = createFeatureSelector<State<Ticket>>('tickets');

export const GetSelectedTicketId = createSelector(SelectTicketState, (state) => state.selectedId);
export const SelectAllTickets = createSelector(SelectTicketState, TicketsReducer.selectAll);
export const TicketsEntities = createSelector(SelectTicketState, (state) => state.entities);

export const GetSelectedTicket = createSelector(SelectAllTickets, GetSelectedTicketId, (notes, id) => {
  return notes.find((note) => note.id === id);
});

export const GetSelectedTicketById = (id: string) =>
  createSelector(SelectAllTickets, (notes) => {
    return notes.find((note) => note.id === id);
  });
export const GetAttachmentSelectedTicket = createSelector(GetSelectedTicket, (note) => {
  return note && note.attachments;
});
export const SelectPreventRefresh = createSelector(SelectTicketState, (state: State<Ticket>) => state.preventRefresh);
export const TicketsNotificationCounter = createSelector(SelectAllTickets, (notes) => {
  return notes.filter((note) => note.hasUpdates).length;
});
export const GetSelectedId = createSelector(SelectTicketState, ({ selectedId }) => {
  return selectedId;
});
