import { Injectable, OnDestroy, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { toSignal } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { UploadApiService } from '@simOn/common/upload-queue/data-access';
import { ApartmentInterface } from '@simOn/space/information/models';
import { ModelSweep } from '@simlab/matterport/api';
import { Observable, Subject, distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import {
  ClearApartmentState,
  DeleteApartment,
  GetApartment,
  GetApartmentShort,
  GetApartmentSuccess,
  GetApartments,
  GetApartmentsSuccess,
  LeaveApartment,
  RemoveApartment,
  SetSelectedId,
  SetSelectedSpaceModelId,
  UpdateApartment,
  UpdateApartmentProviderStatus,
  UpdateModelSweeps,
  UploadFileWithProgress
} from './space.actions';
import {
  ApartmentHomeCenters,
  DefaultSpaceModelId,
  GetApartmentPhotoUrl,
  GetApartmentProviders,
  GetMaximoProvider,
  GetSelectedApartmentId,
  GetSelectedSpaceModelId,
  Loading,
  SelectAllApartments,
  SelectSelectedApartment
} from './space.selectors';

@Injectable({
  providedIn: 'root'
})
export class SpaceFacade implements OnDestroy {
  private readonly store = inject(Store);
  private readonly action = inject(Actions);

  private readonly mediaService = inject(UploadApiService);
  private readonly _destroy$: Subject<void> = new Subject<void>();
  readonly loading$: Observable<boolean> = this.store.select(Loading);
  readonly apartmentLoaded$ = this.action.pipe(ofType(GetApartmentSuccess));
  readonly apartmentsLoaded$ = this.action.pipe(ofType(GetApartmentsSuccess));
  readonly allApartments$ = this.store.select(SelectAllApartments);
  readonly selectedApartment$ = this.store.select(SelectSelectedApartment);
  readonly scanType$ = this.selectedApartment$.pipe(
    map((e) => {
      if (!e || !e.scanModels) return 'UNKNOWN';
      const def = e.scanModels.find((s) => s.isDefault);

      return def ? def.scanProviderType : 'UNKNOWN';
    })
  );

  readonly scanModels = toSignal(
    this.selectedApartment$.pipe(
      filter((apartment) => !!apartment && !!apartment.scanModels),
      map((apartment) => {
        return apartment!.scanModels || [];
      })
    ),
    {
      initialValue: []
    }
  );

  readonly scanType = toSignal(this.scanType$, {
    initialValue: 'UNKNOWN'
  });
  readonly apartmentProviders$ = this.loading$.pipe(
    filter((loading) => !loading),
    switchMap(() => this.store.select(GetApartmentProviders))
  );
  readonly apartmentMaximoProvider$ = this.store.select(GetMaximoProvider);
  readonly apartmentPhotoUrl$ = this.store.select(GetApartmentPhotoUrl);
  readonly defaultSpaceModelId$ = this.store.select(DefaultSpaceModelId);
  readonly selectedSpaceModelId$: Observable<string | undefined> = this.store.select(GetSelectedSpaceModelId);
  readonly selectedSpaceModelIdChange$ = this.action.pipe(ofType(SetSelectedSpaceModelId));
  readonly selectedApartmentId$: Observable<string | undefined> = this.store.select(GetSelectedApartmentId);
  readonly apartmentIdUpdated$ = this.action.pipe(
    ofType(SetSelectedId),
    distinctUntilChanged((prev, curr) => prev.selectedId === curr.selectedId),
    filter((action) => action.selectedId !== null)
  );
  readonly apartmentHomeCenters$ = (apartmentId: string) => this.store.select(ApartmentHomeCenters(apartmentId));

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getApartment(): void {
    this.store.dispatch(GetApartment());
  }

  getApartments(): void {
    this.store.dispatch(GetApartments());
  }

  getApartmentShort(): void {
    this.store.dispatch(GetApartmentShort());
  }

  updateApartment(apartment: ApartmentInterface): void {
    this.store.dispatch(UpdateApartment({ apartment }));
  }

  deleteApartment(apartmentId?: string): void {
    this.store.dispatch(DeleteApartment({ apartmentId }));
  }

  uploadFileSimplifiedWithProgress(formData: FormData, fileName: string) {
    this.mediaService.fileToUpload = formData;
    this.store.dispatch(UploadFileWithProgress({ fileName }));
  }

  setSelectedId(selectedId: string | undefined) {
    this.store.dispatch(SetSelectedId({ selectedId }));
  }
  setSelectedSpaceModelId(selectedSpaceModelId: string | undefined): void {
    this.store.dispatch(SetSelectedSpaceModelId({ selectedSpaceModelId }));
  }

  removeApartment(apartmentId: string) {
    this.store.dispatch(RemoveApartment({ apartmentId }));
  }

  updateModelSweeps(state: { scanModelId: string; sweeps: ModelSweep[]; apartmentId: string }) {
    this.store.dispatch(UpdateModelSweeps({ state }));
  }
  clearState() {
    this.store.dispatch(ClearApartmentState());
  }

  updateApartmentProviderStatus(apartmentId: string, masterDeviceId: string, status: string): void {
    this.store.dispatch(UpdateApartmentProviderStatus({ apartmentId, masterDeviceId, status }));
  }
  leaveApartment() {
    this.store.dispatch(LeaveApartment());
    this.store.dispatch(SetSelectedId({ selectedId: undefined }));
  }
}

export { UploadFileWithProgressFailure, UploadFileWithProgressSuccess } from './space.actions';
