// import { MoveToClosestSweepConfig, MoveToSweepConfig } from '@simlab/matterport/src/lib/models/dto';
// import { Observable } from 'rxjs';
import { Vector3 } from 'three';

// export interface IPositionController {
//   goToPositionAndLookAt$: (position: Vector3) => Observable<void>;
//   moveToSweep$: (config: MoveToSweepConfig) => Observable<void>;
//   moveToClosestSweep$: (config: MoveToClosestSweepConfig) => Observable<void>;
//   moveToClosestSweepWithOffset$: (
//     config: MoveToClosestSweepConfig
//   ) => Observable<void>;
//   moveTo$: (
//     position: Vector3,
//     normal: Vector3,
//     stem: number
//   ) => Observable<void>;
//   moveToMattertag$: (mattertagId: string) => Observable<string>;
//   setSweepsActive$: (enable: boolean) => Observable<void>;
//   setSweepsActive: (enable: boolean) => Promise<void>;
// }

export interface IStartPlacingConfig {
  note: ITagNote;
  autoFinishByClick: boolean;
  mobile?: boolean;
}

export interface ITagNote {
  /**
   * This is ID from database
   * @type {string}
   */
  id: string; //indicates id in DB
  label?: string;
  position: Vector3;
  normal?: Vector3;
  noteType: ETagNoteTypes;
}

export enum ETagNoteTypes {
  INFO = 'INFO',
  INPROGRESS = 'INPROGRESS',
  RESOLVED = 'RESOLVED',
  PENDING = 'PENDING',
  UNRESOLVED = 'UNRESOLVED',
  A1 = 'A1',
  B1 = 'B1',
  C1 = 'C1',
  A2 = 'A2',
  B2 = 'B2',
  C2 = 'C2',
  PIN = 'PIN',
}

export type TTagNoteKeys = keyof typeof ETagNoteTypes;

export const ICONS: Record<TTagNoteKeys, string> = {
  INFO: 'assets/icons/icon_note_info_matterport.svg',
  INPROGRESS: 'assets/icons/icon_note_in_progress_matterport.svg',
  PENDING: 'assets/icons/icon_note_pending_matterport.svg',
  RESOLVED: 'assets/icons/icon_note_resolved_matterport.svg',
  UNRESOLVED: 'assets/icons/icon_note_unresolved_matterport.svg',
  A1: 'assets/icons/icon_1A.svg',
  A2: 'assets/icons/icon_2A.svg',
  B1: 'assets/icons/icon_1B.svg',
  B2: 'assets/icons/icon_2B.svg',
  C1: 'assets/icons/icon_1C.svg',
  C2: 'assets/icons/icon_2C.svg',
  PIN: 'assets/icons/pin.svg',
};
