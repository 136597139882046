import {
  BufferGeometry,
  DoubleSide,
  Euler,
  Mesh,
  MeshBasicMaterial,
  ReplaceStencilOp,
  Vector3,
} from 'three';
import {
  ObjectConfig,
  generateClassInstance,
} from '../types/custom-component.type';
import { DeviceCanvasTextTexture } from './canvas.render.helper';

import { ThreeObjectBase } from './three-object.base';
export class TextConfiguration implements ObjectConfig<TextComponent> {
  private _instance!: TextComponent;
  constructor(
    readonly config: {
      text: string;
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) {}
  init(threeContext: typeof import('three')) {
    this._instance = generateClassInstance<TextComponent, TextConfiguration>(
      TextComponent,
      threeContext,
      this
    );
  }
  get instance() {
    return this._instance;
  }
}
export class TextComponent extends ThreeObjectBase {
  set rotation(position: Euler) {
    throw new Error('Method not implemented.');
  }
  private _mesh!: Mesh<BufferGeometry, MeshBasicMaterial>;
  constructor(
    private readonly three: typeof import('three'),
    private readonly configuration: TextConfiguration['config']
  ) {
    super();

    this.init(three, configuration);
  }
  init(
    threeContext: typeof import('three'),
    configuration: TextConfiguration['config']
  ): void {
    const geometry = new threeContext.PlaneGeometry(1, 1);
    const material = new threeContext.MeshBasicMaterial({
      alphaTest: 0.2,
      polygonOffset: false,
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      side: DoubleSide,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    });
    this._mesh = new threeContext.Mesh(geometry, material);
    this._mesh.layers.set(3);
    this._mesh.visible =
      configuration.visible !== undefined ? configuration.visible : true;
    configuration.position && (this.position = configuration.position);
    configuration.scale && (this.scale = configuration.scale);
    this._text = configuration.text;
  }

  private set _text(text: string) {
    const canvasTexture = new DeviceCanvasTextTexture(
      { text },
      this._textLength(text)
    );
    const map = new this.three.CanvasTexture(canvasTexture.ctx.canvas);
    const material = this._mesh.material;
    if (material.dispose) material.dispose();
    material.map = map;
    material.needsUpdate = true;
    material.stencilWrite = true;
    material.stencilZPass = ReplaceStencilOp;
    this.configuration.text = text;
  }

  set text(text: string) {
    if (text !== this.configuration.text) {
      this._text = text;
    }
  }

  private _textLength(text: string): number {
    let textLength = 0;
    if (text) {
      textLength = text.length < 5 ? 5 : text.length;
    }
    return textLength;
  }
  set position(position: Vector3) {
    this._mesh.position.set(position.x, position.y, position.z);
  }
  set scale(scale: Vector3) {
    const { x, y, z } = scale;
    this._mesh.scale.set(x, y, z);
  }

  hide() {
    if (this._mesh.visible) {
      this._mesh.visible = false;
    }
  }

  show() {
    if (!this._mesh.visible) {
      this._mesh.visible = true;
    }
  }

  get object3D(): Mesh {
    return this._mesh;
  }
}
