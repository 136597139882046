import { Directive, OnDestroy, inject } from '@angular/core';
import { MatterportComponent } from '@simlab/matterport';
import { TagComponent } from '@simlab/simlab-facility-management/sub-features/tag';
import { TransformConverter } from '@simlab/transform';
import { IScanControllerToken } from '@simOn/common/scan';
import { Observable, Subject, distinctUntilChanged, takeUntil, tap } from 'rxjs';

@Directive()
export abstract class ComponentControllerBase implements OnDestroy {
  readonly scanManagerService = inject(IScanControllerToken);
  selectedModel!: Observable<string | undefined>;
  readonly _modeChange: Subject<string> = new Subject<string>();
  readonly _destroy: Subject<void> = new Subject<void>();
  abstract _nodes: Record<string, MatterportComponent | TagComponent>;
  set mode(context: string | string[]) {
    if (typeof context !== 'string') return;
    this._modeChange.next(context);
  }
  ngOnDestroy(): void {
    this.deleteAllMarkers();
    this._nodes = {};
    this._destroy.next();
    this._destroy.complete();
  }
  deleteAllMarkers() {
    Object.keys(this._nodes).map((key) => delete this._nodes[key]);
  }
  protected _deleteMarkerFromScene(id: string) {
    if (this._nodes[id]) {
      this.scanManagerService.component.deleteNote(id);
      delete this._nodes[id];
    }
  }

  get scanTranslator(): TransformConverter {
    return this.scanManagerService.transformConverter;
  }
  protected _modeChangeObserver(...visibleState: string[]) {
    this._modeChange
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        tap((state: string) => (visibleState.includes(state) ? this._showAll() : this._hideAll())),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  protected _hideAll(): void {
    Object.keys(this._nodes).forEach((compId: string) => {
      const node = this._nodes[compId];
      if ('comp' in node) node.comp.show();
      else node.hide();
    });
  }

  protected _showAll(): void {
    Object.keys(this._nodes).forEach((compId: string) => {
      const node = this._nodes[compId];
      if ('comp' in node) node.comp.show();
      else node.show();
    });
    // Object.keys(this._nodes).forEach((compId: string) => {
    //   const comp = this._nodes[compId];
    //   if ('children' in comp) {
    //     comp.comp.show();
    //   } else if ('objects' in comp.inputs) {
    //     comp.show();
    //   }
    // });
  }
}
