import { Mattertag } from '@simlab/matterport/api';
import { Observable } from 'rxjs';

import { ETagNoteTypes, IStartPlacingConfig, ITagNote } from '@simlab/simlab-facility-management/common';
import { Vector3 } from 'three';
import { MatterportComponent } from './matterport-tag-component.type';

export interface IMattertags {
  readonly noteClicked$: Observable<string>;
  readonly tagPlacementAccepted$: Observable<{
    tagNote: ITagNote;
    comp: MatterportComponent | undefined;
  }>;
  removeNote$: (noteId: string) => Observable<void | string[]>;

  addNote$: (note: ITagNote) => Observable<void>;
  addNoteWithOffset$: (
    noteId: string,
    markerPosition: Vector3,
    markerType: ETagNoteTypes
  ) => Observable<void>;
  editIcon: (noteId: string, noteType: ETagNoteTypes) => Observable<void>;
  clearAllTagNotes: () => void;
  abandonPlacingComponent: () => void;
  startPlacingComponent$: (
    placingConfig: IStartPlacingConfig
  ) => Observable<void>;
  loadMattertags$: (tags: Mattertag[]) => Observable<string[]>;
}
export enum ViewMode {
  INSIDE = 'mode.inside',
  OUTSIDE = 'mode.outside',
  DOLLHOUSE = 'mode.dollhouse',
  FLOORPLAN = 'mode.floorplan',
  TRANSITIONING = 'mode.transitioning',
}
