<div class="sim-sidemenu flex column align-center animated" [class.sim-sidemenu--expanded]="isExpanded()">
  <header class="sim-sidemenu__header flex">
    <button class="sim-sidemenu__logo" routerLink="./split-view">
      <div class="sim-sidemenu__logo__container flex align-items-center">
        <img [src]="logoSrc()" alt="Simon">
      </div>
    </button>
  </header>

  <button class="sim-sidemenu__expand-button" (click)="changeExpandStatus()">
    <sim-icon
      [icon]="!isExpanded() ? 'keyboard_arrow_right' : 'keyboard_arrow_left'"
      sizeType="small"
      color="var(--text-icon)"
    ></sim-icon>
  </button>

  <nav class="sim-sidemenu__menu flex column scrollbar" [class.sim-sidemenu__menu--expanded]="isExpanded()">
    <sim-tooltip
      class="sim-sidemenu__menu__home"
      [tooltip]="TOOLTIP_INFO['splitView']"
      [setPositionStrategy]="position"
      [isVisible]="!isExpanded()"
    >
      <div class="sim-sidemenu__menu__home">
        <layout-sim-sidemenu-item icon="grid" link="./split-view" [isShrinked]="!isExpanded()">
          <div class="sim-sidemenu__menu-item" i18n="@@LEFT_PANEL_MENU_3D_BROWSE_SPACES">All spaces</div>
        </layout-sim-sidemenu-item>
      </div>
    </sim-tooltip>

    <hr class="sim-sidemenu__menu__divider" />

    <div class="sim-sidemenu__menu__item--apartment">
      <sim-tooltip [tooltip]="apartmentData().name" [setPositionStrategy]="position" [isVisible]="!isExpanded()">
        <div class="sim-sidemenu__menu__item sim-sidemenu__menu__apartment">
          <img
            class="sim-sidemenu__menu__apartment__photo"
            [src]="apartmentPhotoUrl() || apartmentPlaceholderLogo"
            draggable="false"
            alt="Apartment photo"
          />
          @if (isExpanded()) {
          <div class="flex column">
            <span i18n="@@LEFT_PANEL_MENU_3D_SPACE" class="sim-sidemenu__menu__apartment__space">SPACE</span>
            <span class="sim-sidemenu__menu__apartment__name"> {{ apartmentData().name }} </span>
          </div>
          }
        </div>
      </sim-tooltip>
    </div>

    <sim-tooltip [tooltip]="TOOLTIP_INFO['apartment']" [setPositionStrategy]="position" [isVisible]="!isExpanded()">
      <layout-sim-sidemenu-item icon="union" link="./apartment" [isShrinked]="!isExpanded()">
        <div i18n="@@LEFT_PANEL_MENU_3D_WALK_BUTTON">Virtual walk</div>
      </layout-sim-sidemenu-item>
    </sim-tooltip>

    <sim-tooltip [tooltip]="TOOLTIP_INFO['iotManager']" [setPositionStrategy]="position" [isVisible]="!isExpanded()">
      <layout-sim-sidemenu-item icon="settings_remote" link="./dashboard" [isShrinked]="!isExpanded()">
        <div i18n="@@LEFT_PANEL_MENU_3D_IOT_MANAGER">IoT manager</div>
      </layout-sim-sidemenu-item>
    </sim-tooltip>

    <sim-tooltip [tooltip]="TOOLTIP_INFO['assets']" [setPositionStrategy]="position" [isVisible]="!isExpanded()">
      <layout-sim-sidemenu-item icon="folder" link="./dashboard/assets" [isShrinked]="!isExpanded()">
        <div i18n="@@LEFT_PANEL_MENU_3D_ASSETS">Assets</div>
      </layout-sim-sidemenu-item>
    </sim-tooltip>

    <sim-tooltip
      [tooltip]="TOOLTIP_INFO['integrations']"
      [setPositionStrategy]="position"
      [isVisible]="!isExpanded()"
    >
      <layout-sim-sidemenu-item
        icon="hub"
        link="./providers-settings"
        [isShrinked]="!isExpanded()"
        [isDisabled]="!canViewProviders"
      >
        <div i18n="@@LEFT_PANEL_MENU_3D_INTEGRATIONS">Integrations</div>
      </layout-sim-sidemenu-item>
    </sim-tooltip>

    @if (interiorDesignerVisible()) {
    <sim-tooltip
      [tooltip]="TOOLTIP_INFO['interiorEditor']"
      [setPositionStrategy]="position"
      [isVisible]="!isExpanded()"
    >
      <layout-sim-sidemenu-item icon="chair" link="./interior-designer" [isShrinked]="!isExpanded()">
        <div i18n="@@LEFT_PANEL_MENU_3D_INTERIOR_EDITOR">Interior editor</div>
      </layout-sim-sidemenu-item>
    </sim-tooltip>
    }

    <sim-tooltip
      [tooltip]="TOOLTIP_INFO['spaceSettings']"
      [setPositionStrategy]="position"
      [isVisible]="!isExpanded()"
    >
      <layout-sim-sidemenu-item icon="settings" link="./property-settings" [isShrinked]="!isExpanded()">
        <div i18n="@@LEFT_PANEL_MENU_3D_SPACE_SETTINGS">Space settings</div>
      </layout-sim-sidemenu-item>
    </sim-tooltip>
  </nav>

  @if (isExpanded()) {
  <footer class="sim-sidemenu__footer flex justify-content-center align-items-flex-end">
    <div>
      <img [src]="assetsImage.LogoSimlabBlack | imagePath" alt="Simlab" width="80" />
    </div>
  </footer>
  }
</div>
