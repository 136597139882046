import { ChangeDetectionStrategy, Component, computed, ElementRef, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SimIconComponent } from '@simOn/ui/sim-icon';
import { fromEvent, tap } from 'rxjs';

@Component({
  selector: 'horizontal-scroll',
  standalone: true,
  templateUrl: './horizontal-scroll.component.html',
  styleUrl: './horizontal-scroll.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'horizontal-scroll'
  },
  imports: [SimIconComponent]
})
export class HorizontalScrollComponent {
  private readonly _changes = signal<boolean>(false);
  private readonly _resize$ = toSignal(
    fromEvent(window, 'resize').pipe(
      tap(() => {
        this.updateChanges();
      })
    )
  );
  private scrollContainer = viewChild.required<ElementRef>('scrollContainer');

  readonly isScrollStart = computed(() => {
    this._changes();
    return this.scrollContainer().nativeElement.scrollLeft === 0;
  });
  readonly isScrollEnd = computed(() => {
    this._changes();
    return this.container.scrollLeft + this.container.clientWidth >= this.container.scrollWidth;
  });
  readonly isOverflowing = computed(() => {
    this._changes();
    return this.container.scrollWidth > this.container.clientWidth;
  });

  get container(): HTMLElement {
    return this.scrollContainer().nativeElement;
  }

  scroll(direction: 'left' | 'right'): void {
    const scrollAmount = 200;
    if (direction === 'left') {
      this.container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      this.container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  updateChanges() {
    this._changes.update((changed) => !changed);
  }
}
