import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import { Mattertag } from '@simlab/matterport/api';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import {
  ETagNoteTypes,
  IStartPlacingConfig,
  ITagNote,
  SupportedLinks,
  THint,
} from '@simlab/simlab-facility-management/common';
import {
  ComponentConfiguration,
  ICustomComponent,
} from '@simlab/simlab-facility-management/scene-object';
import { IBlueprint } from '@simlab/simlab-facility-management/sub-features/blueprint';
import { Camera, Mode, MpSdk, Size } from 'mpSdk';
import { Observable, defer } from 'rxjs';
import { Vector3 } from 'three';
import { MatterportIframeComponent } from '../components/matterport-iframe/matterport-iframe.component';
import {
  TDollhouseManager as IDollhouseManager,
  TDollhouseManager,
} from '../models/dollhouse.model';
import {
  IPositionController,
  MoveToClosestSweepConfig,
  MoveToSweepConfig,
  Pointer,
} from '../models/dto';
import { IMatterportEvents } from '../models/events.interface';
import { MatterportScanStatus } from '../models/matterport-data';
import { MatterportComponent } from '../models/matterport-tag-component.type';
import { IMattertags } from '../models/mattertags.interface';
import { IPortals, PortalConfiguration } from '../models/portal';
import { ISceneState } from '../models/scene-state.interface';
import { MatterportManagerService } from './matterport-manager.service';
@Injectable()
/**
 * @deprecated
 */
export class MatterportService
  implements
  IBlueprint<MatterportComponent>,
  ICustomComponent<MatterportComponent>,
  IPositionController,
  ISceneState,
  IMattertags,
  IMatterportEvents,
  IPortals,
  IDollhouseManager {
  readonly positionChange$ = defer(() => this._state.positionChange$);
  readonly noteClicked$ = defer(() => this._tags.noteClicked$);
  readonly sweepChange$ = defer(() => this._state.sweepChange$);
  readonly currentMode$ = defer(() => this._dollHouse.currentMode$);
  readonly currentFloor$ = defer(() => this._dollHouse.currentFloor$);
  readonly matterportClick$ = defer(() => this._events.matterportClick$);
  readonly matterportDetectClick$ = defer(
    () => this._events.matterportDetectClick$
  );
  readonly matterportDetectClickPointerUp$ = defer(
    () => this._events.matterportDetectClickPointerUp$
  );
  readonly matterportHold$ = defer(() => this._events.matterportHold$);
  readonly hasMatterport$ = defer(() => this.manager.hasMatterport$);
  readonly scanLoaded$ = defer(() => this.manager.scanLoaded$);
  readonly selectedNote$ = defer(() => this._component.selectedNote$);
  readonly componentClicked$ = defer(() => this._component.componentClicked$);
  readonly tagPlacementAccepted$ = defer(
    () => this._tags.tagPlacementAccepted$
  );
  readonly pointer$: Observable<Pointer> = defer(() => this._events.pointer$);

  readonly availableSweeps$ = defer(() => this._state.availableSweeps$);
  readonly transformConverter = () => this.manager.transformConverter;
  constructor(private readonly manager: MatterportManagerService) { }
  get currentFloor(): MpSdk.Floor.ObservableFloorData | null {
    return this._dollHouse.currentFloor;
  }
  fromMatterportEvent<T>(eventName: string): Observable<T> {
    return this._events.fromMatterportEvent(eventName);
  }

  private get _position(): IPositionController {
    return this.manager.position;
  }
  private get _tags(): IMattertags {
    return this.manager.tags;
  }
  private get _blueprint(): IBlueprint<MatterportComponent> {
    return this.manager.blueprint;
  }
  private get _portal(): IPortals {
    return this.manager.portal;
  }
  private get _component(): ICustomComponent<MatterportComponent> {
    return this.manager.component;
  }
  private get _state(): ISceneState {
    return this.manager.state;
  }
  private get _events(): IMatterportEvents {
    return this.manager.events;
  }

  private get _dollHouse(): TDollhouseManager {
    return this.manager.dollHouse;
  }

  readonly createAndOpenScan$ = (
    containerRef: ViewContainerRef,
    matterportScanId: string,
    matterportOffset?: string,
    showMattertags?: boolean,
    language: SupportedLinks = SupportedLinks.EN,
    enablePortals = false
  ): Observable<MatterportScanStatus> =>
    this.manager.createAndOpenScan$(
      containerRef,
      matterportScanId,
      matterportOffset,
      showMattertags,
      language,
      enablePortals
    );

  readonly setHint = (hint: THint) =>
    this.manager.setHint(
      hint.hintMessage,
      hint.position,
      hint.backgroundColor,
      hint.fontColor
    );

  readonly changeMode$ = (
    mode: MpSdk.Mode.Mode
  ): Observable<Mode.Mode | undefined> =>
    defer(() => this._dollHouse.changeMode$(mode));

  readonly changeFloor$ = (
    index: number
  ): Observable<number | void | undefined> =>
    defer(() => this._dollHouse.changeFloor$(index));

  readonly moveToPose$ = (
    mode: MpSdk.Camera.Pose
  ): Observable<Mode.Mode | undefined> => this._dollHouse.moveToPose$(mode);

  readonly screenshot = (
    resolution?: MpSdk.Renderer.Resolution,
    visibility?: Partial<MpSdk.Renderer.Visibility>
  ): Observable<string | undefined> =>
    this.manager.screenshot(resolution, visibility);
  readonly worldToScreen = (position: Vector3, windowSize: Size) =>
    this.manager.worldToScreen(position, windowSize);
  readonly create = (
    containerRef: ViewContainerRef,
    enablePortals = false
  ): ComponentRef<MatterportIframeComponent> =>
    this.manager.create(containerRef, enablePortals);
  readonly destroy = (): void => {
    this.manager.destroy();
  };
  readonly goToPositionAndLookAt$ = (position: Vector3) =>
    this._position.goToPositionAndLookAt$(position);
  readonly moveToSweep$ = (config: MoveToSweepConfig) =>
    this._position.moveToSweep$(config);
  readonly moveToClosestSweep$ = (config: MoveToClosestSweepConfig) =>
    this._position.moveToClosestSweep$(config);
  readonly moveToClosestSweepWithOffset$ = (config: MoveToClosestSweepConfig) =>
    this._position.moveToClosestSweepWithOffset$(config);
  readonly moveTo$ = (
    position: Vector3,
    normal: Vector3 = new Vector3(0, 0, 0),
    stem = 0
  ) => this._position.moveTo$(position, normal, stem);
  readonly removeNote$ = (noteId: string) => this._tags.removeNote$(noteId);
  readonly addNote$ = (note: ITagNote) => this._tags.addNote$(note);
  readonly moveToMattertag$ = (mattertagId: string) =>
    this._position.moveToMattertag$(mattertagId);
  readonly addNoteWithOffset$ = (
    noteId: string,
    markerPosition: Vector3,
    markerType: ETagNoteTypes
  ) => this._tags.addNoteWithOffset$(noteId, markerPosition, markerType);
  readonly editIcon = (noteId: string, noteType: ETagNoteTypes) =>
    this._tags.editIcon(noteId, noteType);
  readonly clearAllTagNotes = () => this._tags.clearAllTagNotes();

  readonly startPlacingComponent$ = (placingConfig: IStartPlacingConfig) =>
    this._tags.startPlacingComponent$(placingConfig);
  // /**
  //  * @deprecated
  //  * @returns
  //  */
  // readonly abandonPlacing = () => this._tags.abandonPlacing();
  // /**
  //  * @deprecated
  //  * @returns
  //  */
  // readonly acceptPlacingPosition$ = (): Observable<TagNote> =>
  //   this._tags.acceptPlacingPosition$();
  readonly createPortal = (payload: PortalConfiguration) =>
    this._portal.createPortal(payload);

  // readonly transition = (payload: PortalConfiguration) =>
  // this._dollHouse.createPortal(payload);
  readonly abandonPlacingComponent = () => this._tags.abandonPlacingComponent();
  readonly getAvailableSweeps$ = () => this._state.getAvailableSweeps$();
  readonly setSweepsActive$ = (enable: boolean) =>
    this._position.setSweepsActive$(enable);
  readonly setSweepsActive = (enable: boolean) =>
    this._position.setSweepsActive(enable);

  readonly lastKnownCameraPose = (cameraPose: Camera.Pose): Camera.Pose =>
    this._state.lastKnownCameraPose(cameraPose);

  readonly addComponent$ = (
    component: ComponentConfiguration
  ): Observable<MatterportComponent> =>
    this._component.addComponent$(component);
  readonly addComponentWithOffset$ = (
    component: ComponentConfiguration
  ): Observable<MatterportComponent> =>
    this._component.addComponentWithOffset$(component);
  readonly clearAllNotes = () => this._component.clearAllNotes();
  readonly deleteNote = (noteId: string) => this._component.deleteNote(noteId);
  readonly deleteNote$ = (noteId: string) =>
    this._component.deleteNote$(noteId);
  readonly clearAllNotes$ = () => this._component.clearAllNotes$();
  readonly updateNote$ = (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ) => this._component.updateNote$(component);
  readonly updateNote = (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ) => this._component.updateNote(component);
  readonly updatePositionWithOffset = (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) => this._component.updatePositionWithOffset(component);
  readonly updatePositionWithOffset$ = (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) => this._component.updatePositionWithOffset$(component);
  set selectedNote(noteId: string) {
    this._component.selectedNote = noteId;
  }

  readonly loadMattertags$ = (tags: Mattertag[]) =>
    this._tags.loadMattertags$(tags);
  readonly registerIcons$ = (icons: Record<string, string>) =>
    this.manager.registerIcons$(icons);

  readonly addBlueprint = (blueprint: ComponentConfiguration) => {
    return this._blueprint.addBlueprint(blueprint);
  };
  readonly deleteBlueprint = (blueprintId: string) => {
    return this._blueprint.deleteBlueprint(blueprintId);
  };
}
