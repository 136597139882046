import { createAction, props } from '@ngrx/store';
import { Pending } from '@simOn/common/media';
import { ApartmentCreateInterface, ApartmentInterface } from '@simOn/space/information/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';
import { ModelSweep } from '@simlab/matterport/api';

export const GetApartment = createAction('[Apartments] Get Apartment');

export const SetSelectedId = createAction('[Space] Set Selected Id', props<{ selectedId: string | undefined }>());

export const SetSelectedIdSuccess = createAction(
  '[Space][Success] Set Selected Id',
  props<{ selectedId: string | undefined }>()
);

export const GetApartmentSuccess = createAction(
  '[Apartments][Success] Get Apartment',
  props<{ apartment: ApartmentInterface }>()
);
export const GetApartmentFailure = createAction('[Apartments][Failure] Get Apartment', props<{ error: Error }>());

export const GetApartments = createAction('[Apartments] Get Apartments');
export const GetApartmentsSuccess = createAction(
  '[Apartments][Success] Get Apartments',
  props<{ apartments: ApartmentInterface[] }>()
);
export const GetApartmentsFailure = createAction('[Apartments][Failure] Get Apartments', props<{ error: Error }>());
export const SetSelectedSpaceModelId = createAction(
  '[Apartments] Selected Matterport Model',
  props<{ selectedSpaceModelId: string | undefined }>()
);
export const DeleteApartment = createAction('[Apartments] Delete Apartment', props<{ apartmentId?: string }>());
export const DeleteApartmentSuccess = createAction(
  '[Apartments][Success] Delete Apartment',
  props<{ apartment: ApartmentCreateInterface }>()
);
export const DeleteApartmentFailure = createAction('[Apartments][Failure] Delete Apartment', props<{ error: Error }>());

export const UpdateApartment = createAction(
  '[Apartments] Update Apartment',
  props<{ apartment: ApartmentInterface }>()
);
export const UpdateApartmentSuccess = createAction(
  '[Apartments][Success] Update Apartment',
  props<{ apartment: ApartmentInterface }>()
);
export const UpdateApartmentFailure = createAction('[Apartments][Failure] Update Apartment', props<{ error: Error }>());

export const GetApartmentShort = createAction('[Apartments] Get Apartment Short');
export const GetApartmentShortSuccess = createAction(
  '[Apartments][Success] Get Apartment Short',
  props<{ apartment: ApartmentInterface }>()
);
export const GetApartmentShortFailure = createAction(
  '[Apartments][Failure] Get Apartment Short',
  props<{ error: Error }>()
);
export const UploadFileWithProgress = createAction(
  '[Apartments] Upload File With Progress',
  props<{ fileName: string }>()
);
export const UploadFileWithProgressStarted = createAction('[Apartments][Started] Upload File With Progress');
export const UploadFileWithProgressCompleted = createAction('[Apartments][Completed] Upload File With Progress');
export const UploadFileWithProgressPending = createAction(
  '[Apartments][Pending] Upload File With Progress',
  props<{ state: Pending }>()
);
export const UploadFileWithProgressSuccess = createAction(
  '[Apartments][Success] Upload File With Progress',
  props<{ state: ApartmentInterface; fileName: string }>()
);
export const UploadFileWithProgressFailure = createAction(
  '[Apartments][Failure] Upload File With Progress',
  props<{ state: string }>()
);

export const UpdateModelSweeps = createAction(
  '[Apartments] Update model sweeps',
  props<{ state: { scanModelId: string; sweeps: ModelSweep[]; apartmentId: string } }>()
);
export const UpdateModelSweepsSuccess = createAction(
  '[Apartments][Success] Update model sweeps',
  props<{ state: { scanModelId: string; sweeps: ModelSweep[]; apartmentId: string } }>()
);
export const UpdateModelSweepsFailure = createAction(
  '[Apartments][Failure] Update model sweeps',
  props<{ error: Error }>()
);
export const UpdateApartmentProviderStatus = createAction(
  '[Apartments] Update Apartment Providers Status',
  props<{ apartmentId: string; masterDeviceId: string; status: string }>()
);
export const LeaveApartment = createAction('[Apartments] Leave Apartment');

export const RemoveApartment = createAction('[Apartments] Remove Apartment', props<{ apartmentId: string }>());
export const ClearApartmentState = CLEAR_STATE_ACTION('Apartments');
