import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { SimContentComponent, SimNavbarComponent } from '@simOn/common/containers';
import { SimPageTemplateComponent, SimSidenavComponent } from '@simOn/layout';
import { PrivilegesFacade } from '@simOn/privileges';
import { SpaceFacade } from '@simOn/space';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimFullscreenModule } from '@simOn/ui/sim-fullscreen';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SimSidenavModule } from '@simOn/ui/sim-sidenav';
import { SimUserOnlineComponent } from '@simOn/user/last-visited/ui';
import { LoggedUserComponent } from '@simOn/user/logged/ui';
import { filter, map } from 'rxjs';

@Component({
  selector: 'sim-layout-page-router-outlet',
  standalone: true,
  imports: [
    CommonModule,
    SimNavbarComponent,
    SimContentComponent,
    RouterModule,
    SimIconModule,
    SimButtonModule,
    SimSidenavModule,
    SimSidenavComponent,
    LoggedUserComponent,
    SimFullscreenModule,
    SimUserOnlineComponent,
    SimPageTemplateComponent
  ],
  template: `<layout-page-template #parent>
    <layout-sidenav
      [apartmentPhotoUrl]="apartmentPhotoUrl()?.photoUrl"
      [apartmentName]="apartmentPhotoUrl()?.name"
      [interiorDesignerVisible]="interiorVisible()"
      [providersDisabled]="providersDisabled()"
      (onClose)="parent.toggleMenu()"
    ></layout-sidenav>
  </layout-page-template> `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutPageRouterOutletComponent {
  private readonly _privilegesFacade = inject(PrivilegesFacade);
  private readonly _spaceFacade = inject(SpaceFacade);

  private readonly _interiorVisible = toSignal(
    this._privilegesFacade.hasGeneralAccessTo$('CanManageInteriorDesignerEditor')
  );
  readonly interiorVisible = computed(() => {
    const interiorVisible = this._interiorVisible();
    return interiorVisible || false;
  });

  private readonly _providersDisabled = toSignal(this._privilegesFacade.hasGeneralAccessTo$('CanViewProviders'));
  readonly providersDisabled = computed(() => {
    const providersDisabled = this._providersDisabled();
    return providersDisabled || false;
  });

  readonly apartmentPhotoUrl = toSignal(
    this._spaceFacade.selectedApartment$.pipe(
      filter(apartment => !!apartment),
      map(({ name, photoUrl }) => ({
        photoUrl,
        name
      }))
    )
  );
}
