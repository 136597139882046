import { createAction, props } from '@ngrx/store';
import { RoomInterface } from '@simOn/room/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const SetSelectedId = createAction('[Rooms] Set Selected Id', props<{ selectedId: string }>());
export const GetRooms = createAction('[Rooms] Get Rooms');
export const GetRoomsSuccess = createAction('[Rooms][Success] Get Rooms', props<{ state: RoomInterface[] }>());
export const GetRoomsFailure = createAction('[Rooms][Failure] Get Rooms', props<{ state: RoomInterface[] }>());

export const GetRoom = createAction('[Room] Get Room', props<{ state: string }>());
export const GetRoomSuccess = createAction('[Room][Success] Get Room', props<{ state: RoomInterface }>());
export const GetRoomFailure = createAction('[Room][Failure] Get Room', props<{ state: RoomInterface }>());

export const LoadRooms = createAction('[Rooms] Load Rooms', props<{ state: RoomInterface[] }>());
export const LoadRoomsSuccess = createAction('[Rooms][Success] Load Rooms', props<{ state: RoomInterface[] }>());
export const LoadRoomsFailure = createAction('[Rooms][Failure] Load Rooms', props<{ state: RoomInterface[] }>());

export const AddRoom = createAction('[Rooms] Add Room', props<{ state: RoomInterface }>());
export const AddRoomSuccess = createAction('[Rooms][Success] Add Room', props<{ state: RoomInterface }>());
export const AddRoomFailure = createAction('[Rooms][Failure] Add Room', props<{ state: RoomInterface }>());

export const UpdateRoom = createAction('[Rooms] Update Room', props<{ room: RoomInterface }>());
export const UpdateRoomSuccess = createAction('[Rooms][Success] Update Room', props<{ state: RoomInterface }>());
export const UpdateRoomFailure = createAction('[Rooms][Failure] Update Room', props<{ state: RoomInterface }>());

export const SetRoomCamera = createAction(
  '[Rooms] Set Room Camera',
  props<{
    roomId: string;
    roomCamera: {
      scanModelId: string;
      position: import('libs/simlab-matterport/assets/mpSdk').Vector3;
      rotation: import('libs/simlab-matterport/assets/mpSdk').Vector2;
    };
  }>()
);

export const SetRoomCameraSuccess = createAction(
  '[Rooms][Success] Set Room Camera',
  props<{
    roomId: string;
    roomCamera: {
      scanModelId: string;
      position: import('libs/simlab-matterport/assets/mpSdk').Vector3;
      rotation: import('libs/simlab-matterport/assets/mpSdk').Vector2;
    };
  }>()
);
export const SetRoomCameraFailure = createAction('[Rooms][Failure] Set Room Camera', props<{ error: Error }>());

export const UpdateSweepInRoom = createAction(
  '[Rooms] Update Sweep In Room',
  props<{ roomId: string; scanModelId: string; sweepId: string }>()
);
export const UpdateSweepInRoomSuccess = createAction('[Rooms][Success] Update Sweep In Room');
export const UpdateSweepInRoomFailure = createAction(
  '[Rooms][Failure] Update Sweep In Room',
  props<{ state: RoomInterface }>()
);

export const DeleteRoom = createAction('[Rooms] Delete Room', props<{ state: string }>());
export const DeleteRoomSuccess = createAction('[Rooms][Success] Delete Room', props<{ state: RoomInterface }>());
export const DeleteRoomFailure = createAction('[Rooms][Failure] Delete Room', props<{ state: RoomInterface }>());

export const UpdateRoomOnStore = createAction('[Rooms][Local Update] Update Room', props<{ room: RoomInterface }>());
export const ClearRoomsState = CLEAR_STATE_ACTION('Rooms');
export const LoadMasterRoom = createAction('[Master Room] Load Master Room', props<{ masterRoom: RoomInterface }>());

export const GetMasterRoom = createAction('[Master Room] Get Master Room');
export const GetMasterRoomSuccess = createAction(
  '[Master Room][Success] Get Master Room',
  props<{ masterRoom: RoomInterface }>()
);
export const GetMasterRoomFailure = createAction(
  '[Master Room][Failure] Get Master Room',
  props<{ masterRoom: RoomInterface }>()
);
