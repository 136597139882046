import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries

import { FileLoaderCacheService } from '@simlab/simlab-facility-management/common';
import {
  IBlueprint,
  _BlueprintBase,
} from '@simlab/simlab-facility-management/sub-features/blueprint';
import { MatterportComponent } from '../models/matterport-tag-component.type';
import { MatterportCustomComponentService } from './custom-component.service';

@Injectable()
export class BlueprintService
  extends _BlueprintBase<MatterportComponent, MatterportCustomComponentService>
  implements IBlueprint<MatterportComponent> {
  constructor(
    private readonly componentLoader: MatterportCustomComponentService,
    private readonly httpClient: HttpClient,
    private readonly fileLoaderCacheService: FileLoaderCacheService
  ) {
    super(componentLoader, httpClient, fileLoaderCacheService);
  }
}
