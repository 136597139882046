import { OverlayModule } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { SimInitialsPipe } from '@simOn/common/pipes';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimIconComponent } from '@simOn/ui/sim-icon';
import { UserPreferencesFacade } from '@simOn/user';
import { IdentityUrl } from '@simOn/user/auth/service';

import { UserPreferencesModalResultInterface } from '@simOn/user/preferences/model';
import { RELOAD_PAGE_AFTER_CHANGE_LANGUAGE, UserPreferencesModalComponent } from '@simOn/user/preferences/ui';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom, map, tap } from 'rxjs';

@Component({
  selector: 'user-logged-info',
  standalone: true,
  imports: [RouterModule, SimButtonModule, OverlayModule, SimInitialsPipe, SimIconComponent],
  templateUrl: './logged-user.component.html',
  styleUrls: ['./logged-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoggedUserComponent {
  private readonly _dialog = inject(MatDialog);
  private readonly _router = inject(Router);
  private readonly _oidcSecurityService = inject(OidcSecurityService);
  private readonly _userPreferencesFacade = inject(UserPreferencesFacade);
  protected readonly simlabCloudRedirect = inject(IdentityUrl);
  protected userPanelVisible = false;

  readonly user = toSignal(this._oidcSecurityService.userData$.pipe(map(({ userData }) => userData)));

  readonly userName = computed(() => {
    const user = this.user();
    return user.name ? user.name : `${user.given_name} ${user.family_name}`;
  });

  openPanel() {
    this.userPanelVisible = !this.userPanelVisible;
  }
  hideAllPanels() {
    this.userPanelVisible = false;
  }

  logOut() {
    firstValueFrom(this._oidcSecurityService.logoff());
    this.userPanelVisible = false;
  }

  async openUserPreferences(): Promise<void> {
    this.userPanelVisible = false;
    const modalRef = await this._openUserPreferencesDialog();
    firstValueFrom(
      modalRef.afterClosed().pipe(
        tap((modalResult: UserPreferencesModalResultInterface | undefined) => {
          if (!modalResult || !modalResult.payload || modalResult.action !== 'save') return;
          this._userPreferencesFacade.updateUserPreferences(modalResult.payload);
          firstValueFrom(
            this._userPreferencesFacade.successUserPreferencesUpdate.pipe(
              tap(() => {
                RELOAD_PAGE_AFTER_CHANGE_LANGUAGE(
                  modalResult.payload!.preferences.languageMode,
                  this._router.routerState.snapshot.url
                );
              })
            )
          );
        })
      )
    );
  }

  private async _openUserPreferencesDialog(): Promise<
    MatDialogRef<UserPreferencesModalComponent, UserPreferencesModalResultInterface>
  > {
    const modalComponent = await import('@simOn/user/preferences/ui').then((e) => e.UserPreferencesModalComponent);
    return this._dialog.open(modalComponent, {
      panelClass: 'custom-modal',
      width: '700px',
      height: 'min(680px,100%)',
      disableClose: true
    });
  }
}
