import { TPositionWithModel } from '@simOn/common/scan';

export interface LastSweepInterface {
  [key: string]: TPositionWithModel | undefined;
}

export function GetLastPosition(apartmentId: string): TPositionWithModel | undefined {
  const lastPos = localStorage.getItem('lastPositionInApartment');
  const lastKnownPosition: LastSweepInterface = lastPos && JSON.parse(lastPos);
  return lastKnownPosition !== null ? lastKnownPosition[apartmentId] : undefined;
}
