import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { RoomCamera, RoomInterface } from '@simOn/room/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoomsApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getRoom(id: string): Observable<RoomInterface> {
    let params = new HttpParams().append('id', id);
    return this.http.get<RoomInterface>(`${this._apiUrl}/Rooms/GetRoom?${params}`);
  }

  getRooms(): Observable<RoomInterface[]> {
    return this.http.get<RoomInterface[]>(`${this._apiUrl}/Rooms/GetRooms`);
  }

  getRoomsWithMasterRoom(): Observable<RoomInterface> {
    return this.http.get<RoomInterface>(`${this._apiUrl}/Rooms/getApartmentMasterRoom?FetchAssets=3&FetchDevices=3`);
  }

  createRoom(room: RoomInterface | any): Observable<RoomInterface> {
    return this.http.post<RoomInterface>(`${this._apiUrl}/Rooms/CreateRoom`, room); // matterportUrl required
  }

  putRoom(room: RoomInterface): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Rooms/PutRoom`, room);
  }

  updateSweepInRoom(id: string, scanModelId: string, sweepToAdd: string | null = null): Observable<void> {
    let params = new HttpParams();
    params = params.append('roomId', id);
    params = params.append('scanModelId', scanModelId);
    if (sweepToAdd != null) {
      params = params.append('scanPointToAddId', sweepToAdd);
    }

    return this.http.patch<void>(`${this._apiUrl}/Rooms/PatchRoom?${params}`, null);
  }

  deleteRoom(id: string): Observable<RoomInterface> {
    return this.http.delete<RoomInterface>(`${this._apiUrl}/Rooms/DeleteRoom?roomGuid=${id}`);
  }

  getMasterRoom(): Observable<RoomInterface> {
    return this.http.get<RoomInterface>(`${this._apiUrl}/rooms/getApartmentMasterRoom?FetchAssets=1&FetchDevices=1`);
  }

  setRoomCamera(roomCamera: RoomCamera): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Rooms/SetRoomCamera`, roomCamera);
  }
}
