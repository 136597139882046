import { AriaDescriber } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit, Renderer2, inject } from '@angular/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

let nextId = 0;
export interface CanDisable {
  /** Whether the component is disabled. */
  disabled: boolean;
}

export type Constructor<T> = new (...args: any[]) => T;

export type AbstractConstructor<T = object> = abstract new (...args: any[]) => T;
type CanDisableCtor = Constructor<CanDisable> & AbstractConstructor<CanDisable>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function mixinDisabled<T extends Constructor<{}>>(base: T): CanDisableCtor & T {
  return class extends base {
    private _disabled = false;

    get disabled(): boolean {
      return this._disabled;
    }
    set disabled(value: any) {
      this._disabled = coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}

// Boilerplate for applying mixins to badge.
/** @docs-private */
const _badgeBase = mixinDisabled(class {});

/** Allowed position options for badgePosition */
export type badgePosition =
  | 'above after'
  | 'above before'
  | 'below before'
  | 'below after'
  | 'before'
  | 'after'
  | 'above'
  | 'default';

/** Allowed size options for badgeSize */
export type badgeSize = 'small' | 'medium' | 'large';

const BADGE_CONTENT_CLASS = 'sim-badge-content';

/** Directive to display a text badge. */
@Directive({
  selector: '[ui-badge]',
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['disabled: ui-badgeDisabled'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'badge',
    '[class.badge-above]': 'isAbove()',
    '[class.badge-below]': '!isAbove()',
    '[class.badge-before]': '!isAfter()',
    '[class.badge-after]': 'isAfter()',
    '[class.badge-default]': 'isDefault()',
    '[class.badge-small]': 'size === "small"',
    '[class.badge-medium]': 'size === "medium"',
    '[class.badge-large]': 'size === "large"',
    '[class.badge-hidden]': 'hidden || !content',
    '[class.badge-disabled]': 'disabled',
    '[class.badge-round]': 'badgeRoundBackground'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BadgeDirective extends _badgeBase implements OnInit, OnDestroy {
  private _ngZone = inject(NgZone);
  private _elementRef = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
  private readonly elementRef = inject(ElementRef);
  private _ariaDescriber = inject(AriaDescriber);
  private _renderer = inject(Renderer2);
  private _animationMode = inject<string>(ANIMATION_MODULE_TYPE, { optional: true });
  private _overlap = true;
  private _content: string | number | undefined | null;
  private _color = 'primary';
  private _description = '';
  private _hidden = false;
  /** Whether the OnInit lifecycle hook has run yet */
  private _isInitialized = false;
  private _badgeRoundBackground = false;
  /** Visible badge element. */
  private _badgeElement: HTMLElement | undefined;
  /** The color of the badge. Can be `primary`, `accent`, or `warn`. */
  @HostBinding('class.sim-badge') class = true;
  @Input('badgeColor')
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._setColor(value);
    this._color = value;
  }

  /** Whether the badge should overlap its contents or not */
  @Input('badgeOverlap')
  get overlap(): boolean {
    return this._overlap;
  }
  set overlap(val: BooleanInput) {
    this._overlap = coerceBooleanProperty(val);
  }

  /**
   * Position the badge should reside.
   * Accepts any combination of 'above'|'below' and 'before'|'after' or 'default'
   */
  @Input('ui-badgePosition') position: badgePosition = 'default';

  /** The content for the badge */
  @Input('badge')
  get content(): string | number | undefined | null {
    return this._content;
  }
  set content(newContent: string | number | undefined | null) {
    this._updateRenderedContent(newContent);
  }

  /** Message used to describe the decorated element via aria-describedby */
  @Input('badgeDescription')
  get description(): string {
    return this._description;
  }
  set description(newDescription: string) {
    this._updateHostAriaDescription(newDescription);
  }

  /** Size of the badge. Can be 'small', 'medium', or 'large'. */
  @Input('ui-badgeSize') size: badgeSize = 'medium';

  /** Whether the badge is hidden. */
  @Input('badgeHidden')
  get hidden(): boolean {
    return this._hidden;
  }
  set hidden(val: BooleanInput) {
    this._hidden = coerceBooleanProperty(val);
  }

  @Input('badgeRoundBackground')
  get badgeRoundBackground(): boolean {
    return this._badgeRoundBackground;
  }
  set badgeRoundBackground(val: BooleanInput) {
    this._badgeRoundBackground = coerceBooleanProperty(val);
  }

  /** Unique id for the badge */
  _id: number = nextId++;

  constructor() {
    super();

    //   const hostElement = this.getHostElement();
    // hostElement.classList.add('sim-badge');
    // if (typeof ngDevMode === 'undefined' || ngDevMode) {
    //   const nativeElement = _elementRef.nativeElement;
    //   if (nativeElement.nodeType !== nativeElement.ELEMENT_NODE) {
    //     throw Error('badge must be attached to an element node.');
    //   }
    // }
  }

  /** Whether the badge is above the host or not */
  isAbove(): boolean {
    return this.position.indexOf('below') === -1;
  }

  /** Whether the badge is after the host or not */
  isAfter(): boolean {
    return this.position.indexOf('before') === -1;
  }

  /** Whether the badge is center after the host or not */
  isDefault(): boolean {
    return this.position.indexOf('default') !== -1;
  }

  /**
   * Gets the element into which the badge's content is being rendered. Undefined if the element
   * hasn't been created (e.g. if the badge doesn't have content).
   */
  getBadgeElement(): HTMLElement | undefined {
    return this._badgeElement;
  }

  ngOnInit() {
    // We may have server-side rendered badge that we need to clear.
    // We need to do this in ngOnInit because the full content of the component
    // on which the badge is attached won't necessarily be in the DOM until this point.
    this._clearExistingBadges();

    if (this.content && !this._badgeElement) {
      this._badgeElement = this._createBadgeElement();
      this._updateRenderedContent(this.content);
    }

    this._isInitialized = true;
  }

  ngOnDestroy() {
    // ViewEngine only: when creating a badge through the Renderer, Angular remembers its index.
    // We have to destroy it ourselves, otherwise it'll be retained in memory.
    if (this._renderer.destroyNode) {
      this._renderer.destroyNode(this._badgeElement);
    }

    this._ariaDescriber.removeDescription(this._elementRef.nativeElement, this.description);
  }

  private getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  /** Creates the badge element */
  private _createBadgeElement(): HTMLElement {
    const badgeElement = this._renderer.createElement('span');
    const activeClass = 'badge-active';

    badgeElement.setAttribute('id', `badge-content-${this._id}`);

    // The badge is aria-hidden because we don't want it to appear in the page's navigation
    // flow. Instead, we use the badge to describe the decorated element with aria-describedby.
    badgeElement.setAttribute('aria-hidden', 'true');
    badgeElement.classList.add(BADGE_CONTENT_CLASS);

    if (this._animationMode === 'NoopAnimations') {
      badgeElement.classList.add('_animation-noopable');
    }

    this._elementRef.nativeElement.appendChild(badgeElement);

    // animate in after insertion
    if (typeof requestAnimationFrame === 'function' && this._animationMode !== 'NoopAnimations') {
      this._ngZone.runOutsideAngular(() => {
        requestAnimationFrame(() => {
          badgeElement.classList.add(activeClass);
        });
      });
    } else {
      badgeElement.classList.add(activeClass);
    }

    return badgeElement;
  }

  /** Update the text content of the badge element in the DOM, creating the element if necessary. */
  private _updateRenderedContent(newContent: string | number | undefined | null): void {
    const newContentNormalized: string = `${newContent ?? ''}`.trim();

    // Don't create the badge element if the directive isn't initialized because we want to
    // append the badge element to the *end* of the host element's content for backwards
    // compatibility.
    if (this._isInitialized && newContentNormalized && !this._badgeElement) {
      this._badgeElement = this._createBadgeElement();
    }

    if (this._badgeElement) {
      this._badgeElement.textContent = newContentNormalized;
    }

    this._content = newContentNormalized;
  }

  /** Updates the host element's aria description via AriaDescriber. */
  private _updateHostAriaDescription(newDescription: string): void {
    this._ariaDescriber.removeDescription(this._elementRef.nativeElement, this.description);
    if (newDescription) {
      this._ariaDescriber.describe(this._elementRef.nativeElement, newDescription);
    }
    this._description = newDescription;
  }

  /** Adds css theme class given the color to the component host */
  private _setColor(colorPalette: string) {
    const classList = this._elementRef.nativeElement.classList;
    classList.remove(`sim-badge-${this._color}`);
    if (colorPalette) {
      classList.add(`sim-badge-${colorPalette}`);
    }
  }

  /** Clears any existing badges that might be left over from server-side rendering. */
  private _clearExistingBadges() {
    // Only check direct children of this host element in order to avoid deleting
    // any badges that might exist in descendant elements.
    const badges = this._elementRef.nativeElement.querySelectorAll(`:scope > .${BADGE_CONTENT_CLASS}`);
    for (const badgeElement of Array.from(badges)) {
      if (badgeElement !== this._badgeElement) {
        badgeElement.remove();
      }
    }
  }
}
