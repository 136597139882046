import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  inject,
  input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

let nextUniqueId = 0;
const INPUT_SUPPORTED_TYPES = ['text', 'number', 'password', 'email', 'time'];

@Component({
  selector: 'sim-input',
  templateUrl: './sim-input.component.html',
  styleUrls: ['./sim-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SimInputComponent
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class SimInputComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  private readonly _elementRef = inject(ElementRef);
  @HostBinding('class.sim-ui-input') class = true;
  @HostBinding('class.disable') get isDisabled(): boolean {
    return this._disabled;
  }
  /** Automatic uuid for control */
  protected _uid = `ui-input-${nextUniqueId++}`;

  @Input() prefix?: TemplateRef<any>;

  @Input() showPrefix: boolean = false;

  @Input() suffix?: TemplateRef<any>;

  readonly isError = input<boolean | ValidationErrors | null>();

  /** Information about focus */
  focused = false;

  /** Value section start*/
  @Input()
  get value(): string | number | undefined {
    return this._value;
  }

  set value(newValue: string | number | undefined) {
    this._value = newValue || '';
  }

  private _value: string | number | undefined;
  /** value section end */

  /** Required section start*/

  get required(): boolean {
    return this._required;
  }
  @Input()
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  protected _required = false;
  /** Required section end*/

  /** Readonly section start */

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;
  /** Readonly section end */

  /** Type element section start */

  get type(): string {
    return this._type;
  }

  @Input()
  set type(value: string) {
    this._type = value || 'text';
    this._validateType();
    (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
  }
  protected _type = 'text';
  /** Type element section end */

  /** Disabled element section start */
  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
    }
  }
  protected _disabled = false;
  /** Disabled element section end */

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */

  get inputId(): string {
    return this._inputId;
  }
  @Input()
  set inputId(value: string) {
    this._inputId = value || this._uid;
  }
  protected _inputId: string = this._uid;

  /**
   * Disabled autocomplete
   */
  @Input() set disableAutocomplete(value: boolean) {
    if (value) {
      this._autocomplete = 'new-password';
    } else {
      this._autocomplete = '';
    }
  }
  get autocomplete(): string {
    return this._autocomplete;
  }
  private _autocomplete = '';
  /**
   * Input placeholder
   */
  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }
  get placeholder() {
    return this._placeholder ?? '';
  }
  protected _placeholder?: string;

  @Input() set min(value: number | Date | undefined) {
    this._min = value;
  }
  get min(): number | Date | undefined {
    return this._min;
  }

  private _min?: number | Date;

  @Input() set max(value: number | Date | undefined) {
    this._max = value;
  }
  get max(): number | Date | undefined {
    return this._max;
  }

  private _max?: number | Date;

  @Output() readonly valueChange: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() readonly focusChange: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('input', { static: false }) private _input!: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onChange: (value: any) => void = () => {};

  /**
   * Implement as part of ControlValueAccessor - don't delete
   */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const element = this._input.nativeElement;
    if (this.value) {
      element.value = this.value;
    }
    if (this.placeholder) {
      element.setAttribute('placeholder', this.placeholder);
    }
  }

  /**
   * Sets control value. Part of the ControlValueAccessor interface
   * required to integrate with Angular's core forms API.
   * @param value
   */
  writeValue(value: string | number | undefined): void {
    this.value = value;
  }

  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /** Make sure the input is a supported type. */
  protected _validateType() {
    if (INPUT_SUPPORTED_TYPES.indexOf(this._type) === -1) {
      throw new Error(`Unsupported type ${this._type}`);
    }
  }

  /** Callback for the cases where the focused state of the input changes. */
  _focusChanged(isFocused: boolean) {
    if (!isFocused) {
      this.focusChange.emit();
    }
    if (isFocused !== this.focused) {
      this.focused = isFocused;
    }
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  emitChangeEvent(event: Event) {
    this.value = (event.target as HTMLInputElement).value;
    this._onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
