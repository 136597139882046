import {
  FrontSide,
  Mesh,
  NearestFilter,
  NearestMipmapNearestFilter,
  ReplaceStencilOp,
  Vector3,
  sRGBEncoding,
} from 'three';
import {
  ObjectConfig,
  generateClassInstance,
} from '../types/custom-component.type';
import { DeviceCanvasIconTexture } from './canvas.render.helper';
import { ThreeObjectBase } from './three-object.base';

export class MeshConfiguration implements ObjectConfig<MeshComponent> {
  private _instance!: MeshComponent;
  constructor(
    readonly config: {
      icon: {
        url: string;
        color: string;
      };
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) { }
  init(threeContext: typeof import('three')) {
    this._instance = generateClassInstance<MeshComponent, MeshConfiguration>(
      MeshComponent,
      threeContext,
      this
    );
  }
  get instance() {
    return this._instance;
  }
}

export class MeshComponent extends ThreeObjectBase {
  set rotation(position: THREE.Euler) {
    throw new Error('Method not implemented.');
  }
  private _mesh!: THREE.Mesh;
  constructor(
    private readonly three: typeof import('three'),
    private readonly configuration: MeshConfiguration['config']
  ) {
    super();
    this.init(three, configuration);
  }

  init(
    threeContext: typeof import('three'),
    configuration: MeshConfiguration['config']
  ): void {
    this._mesh = this.createObject(threeContext);
    this._icon = this.configuration.icon;
    this._mesh.layers.set(3);
    configuration.position ?? ({ x: 0, y: 0, z: 0 } as Vector3);
  }

  private _getTexture(
    iconPath: MeshConfiguration['config']['icon']
  ): Promise<THREE.Texture> {
    const image = new Image();
    return new Promise((resolve) => {
      if (image) {
        image.onload = () => {
          const canvasTextureIcon = new DeviceCanvasIconTexture(
            image,
            iconPath.color
          );
          const texture = new this.three.CanvasTexture(
            canvasTextureIcon.ctx.canvas
          );
          texture.minFilter = NearestFilter;
          texture.encoding = sRGBEncoding;
          resolve(texture);
        };
      }
      image.src = iconPath.url;
    });
  }
  private createObject(threeContext: typeof import('three')): Mesh {
    const geometry = new threeContext.PlaneGeometry(1, 1);

    const canvasTextureIcon = new DeviceCanvasIconTexture(
      new Image(),
      this.configuration.icon.color
    );

    const texture = new threeContext.CanvasTexture(
      canvasTextureIcon.ctx.canvas
    );
    texture.encoding = sRGBEncoding;
    const material = new threeContext.MeshBasicMaterial({
      map: texture,
      alphaTest: 0.2,
      polygonOffset: false,
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      side: FrontSide,
      toneMapped: false,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    });
    material.stencilWrite = true;
    material.stencilZPass = ReplaceStencilOp;
    return new threeContext.Mesh(geometry, material);
  }
  get object3D(): Mesh {
    return this._mesh;
  }
  set position(position: Vector3) {
    const { x, y, z } = this._mesh.position;
    if (x !== position.x || y !== position.y || position.z !== z) {
      this._mesh.position.set(position.x, position.y, position.z);
    }
  }

  private set _icon(iconPath: MeshConfiguration['config']['icon']) {
    (this._mesh.material as THREE.MeshBasicMaterial).dispose();
    const material = this._mesh.material as THREE.MeshBasicMaterial;
    this._getTexture(iconPath).then((texture: THREE.Texture) => {
      texture.minFilter = NearestFilter;
      texture.minFilter = NearestMipmapNearestFilter;
      material.map = texture;
      material.needsUpdate = true;
      this.configuration.icon.color = iconPath.color;
      this.configuration.icon.url = iconPath.url;
    });
  }

  set icon(iconPath: MeshConfiguration['config']['icon']) {
    if (
      this.configuration.icon.color !== iconPath.color ||
      this.configuration.icon.url !== iconPath.url
    ) {
      this._icon = iconPath;
    }
  }
  set scale(scale: Vector3) {
    const { x, y, z } = scale;
    this._mesh.scale.set(x, y, z);
  }

  hide() {
    if (this._mesh.visible) {
      this._mesh.visible = false;
    }
  }

  show() {
    if (!this._mesh.visible) {
      this._mesh.visible = true;
    }
  }
}
