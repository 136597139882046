import {
  ExtendedMap,
  SimlabCollider,
} from '@simlab/simlab-facility-management/common';
import type { IFeatureBase } from '@simlab/simlab-facility-management/features/models';
import { randomUUID } from '@simlab/simlab-facility-management/scene-object';
import { TransformConverter } from '@simlab/transform';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  fromEvent,
  map,
  merge,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { Camera, Event, Object3D, Scene, Vector3 } from 'three';
import { MeasurementMeshComponent } from '../components/measurement-mesh-component';

import {
  IMeasurementTool,
  MeasurementMode,
  TMeasurementElements,
  TRANSFORM_CONTROLS_EVENTS,
  Units,
} from '@simlab/simlab-facility-management/sub-features/measurement';
import { TransformControls } from '@simlab/three/TransformControls';
import { TMeasurementMesh } from '../types/line-measurement.interface';

export class MeasurementMeshFactory
  implements IMeasurementTool<MeasurementMeshComponent, TMeasurementMesh> {
  // private readonly _featureBase = inject(BASE_FEATURE_TOKEN);
  readonly camera: Camera = this._featureBase.cameraObject;
  readonly scene: Scene = this._featureBase.sceneObject;
  readonly offset: TransformConverter = this._featureBase.transformConverter;
  readonly domElement: HTMLElement = this._featureBase.renderer.domElement;
  readonly sceneColider: SimlabCollider = this._featureBase.collider;
  private _listenersEnabled = false;
  private _hiddenAreasOmit: undefined | string[] = undefined;
  private _sizeUnit: Units = Units.Metric;
  private readonly _destroy: Subject<void> = new Subject<void>();
  private readonly _removeListeners: Subject<void> = new Subject<void>();
  private readonly _measurementMap: ExtendedMap<
    string,
    MeasurementMeshComponent
  > = new ExtendedMap<string, MeasurementMeshComponent>();
  private readonly _selectedComponentId: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  private readonly _raycasterHelper = this.sceneColider;
  private readonly _transformControls: TransformControls =
    new TransformControls(this.camera, this.domElement);

  readonly transformationEvent$ = merge(
    fromEvent(this._transformControls, 'objectChange').pipe(
      map(() => TRANSFORM_CONTROLS_EVENTS.objectChange)
    ),
    fromEvent(this._transformControls, 'mouseDown').pipe(
      map(() => TRANSFORM_CONTROLS_EVENTS.mouseDown)
    ),
    fromEvent(this._transformControls, 'mouseUp').pipe(
      map(() => TRANSFORM_CONTROLS_EVENTS.mouseUp)
    ),
    fromEvent(this._transformControls, 'change').pipe(
      map(() => TRANSFORM_CONTROLS_EVENTS.change)
    )
  );

  readonly selectedMeasurement$ = this._selectedComponentId.asObservable();

  readonly selectedMeasurementMode$ = this._selectedComponentId.pipe(
    switchMap((selectedArea) => {
      if (!selectedArea) return of(undefined);
      const component = this._measurementMap.get(selectedArea);
      if (!component) return of(undefined);
      return component.mode$;
    }),
    distinctUntilChanged()
  );
  readonly selectedMeasurementChange$ = this._selectedComponentId.pipe(
    switchMap((selectedArea) => {
      if (!selectedArea) return of(undefined);
      const component = this._measurementMap.get(selectedArea);
      if (!component) return of(undefined);
      return component.measurementChange$;
    })
  );

  private _createAreaComponent = (data: TMeasurementMesh) => {
    if (this._measurementMap.has(data.id)) {
      console.error(`id = ${data.id} exist`);
      throw Error(`id = ${data.id} exist`);
    }
    const size = 0;
    const area = new MeasurementMeshComponent(this.camera, this.scene, this._featureBase.transformConverter, {
      ...data,
      size,
      unit: this._sizeUnit,
    });
    this._measurementMap.set(data.id, area);
  };
  get selectedComponent(): MeasurementMeshComponent | undefined {
    const id = this._selectedComponentId.getValue();
    if (!id) return;
    return this._measurementMap.get(id);
  }
  private _onVertexClick = (_component: MeasurementMeshComponent) => {
    const _vertexCollider = new SimlabCollider(this.camera, this.scene, false);
    _vertexCollider.layers.enableAll();
    const selected = this.scene.children.find(
      (child) => child.name === _component.id
    );
    _vertexCollider.objectsCb = () => {
      if (!selected) return [];
      return selected.children.filter((obj) => obj.type === 'Sprite');
    };
    const finish = _component.measurementChange$.pipe(
      filter((changes) => changes.source === 'created')
    );
    _vertexCollider.pointerDownCollisionListener$
      .pipe(
        filter(() => this._listenersEnabled === false),
        takeUntil(finish)
      )
      .subscribe(([intersect]) => {
        if (!intersect) {
          return;
        }
        _component.onComponentClick(this._transformControls, intersect);
      });
  };
  private _removePointListener = (): Observable<string | undefined> => {
    const _removePointColider = new SimlabCollider(
      this.camera,
      this.scene,
      false
    );
    _removePointColider.layers.enable(21);
    const _component = this.selectedComponent;
    if (!_component) return of(undefined);
    _component.mode = 'update';
    const tempSprite = _component.createTemporaryPoint('REMOVE_POINT');
    _component.disableLayers = {
      lines: true,
      mesh: true,
      points: false,
    };
    const selected = this.scene.children.find(
      (child) => child.name === _component.id
    );
    _removePointColider.objectsCb = () => {
      if (!selected) return [];
      return selected.children.filter((obj) => obj.type === 'Sprite');
    };
    _removePointColider.pointerMoveCollisionListener$
      .pipe(
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            tempSprite.visible = false;
          } else {
            tempSprite.visible = true;
          }
        }),
        filter((intersection) => !!intersection && intersection.length > 0),
        map((intersects) => {
          if (!intersects) return;
          return intersects[0];
        }),
        takeUntil(this._removeListeners),
        finalize(() => {
          _component.removeTemporaryPoint();
          _component.disableLayers = {
            lines: false,
            mesh: true,
            points: false,
          };
        })
      )
      .subscribe((intersectionObject) => {
        if (
          !intersectionObject ||
          (intersectionObject.object.name as TMeasurementElements) !==
          'AREA_POINT'
        ) {
          tempSprite.visible = false;
          return;
        }
        tempSprite.visible = true;
        tempSprite.position.copy(intersectionObject.object.position);
      });

    return _removePointColider.pointerDownCollisionListener$.pipe(
      filter((intersects) => !!intersects && intersects.length > 0),
      map((intersects) => {
        if (!intersects || !intersects.length) return;
        const intersect = intersects.find(
          (object) =>
            (object.object.name as TMeasurementElements) === 'AREA_POINT'
        );
        if (intersect) {
          const areaComponentId = intersect.object.parent?.name;
          if (!areaComponentId) return;
          const component = this._measurementMap.get(areaComponentId);

          if (!component) return;
          if (component.selected === false) {
            component.selected = true;
            return;
          }
          component.removePoint(intersect.object.userData['id']);

          this.removeListener();
          return intersect.object.userData['id'];
        }
      }),
      takeUntil(this._removeListeners)
    );
  };
  private _addPointListener = (): Observable<string | undefined> => {
    const _addPointListener = new SimlabCollider(
      this.camera,
      this.scene,
      false
    );
    _addPointListener.layers.enable(21);
    _addPointListener.params = {
      Line: { threshold: 0.1 },
    };
    const _component = this.selectedComponent;
    if (!_component) return of(undefined);
    _component.mode = 'update';
    const selected = this.scene.children.find(
      (child) => child.name === _component.id
    );
    _addPointListener.objectsCb = () => {
      if (!selected) return [];

      return selected.children.filter((obj) => obj.type === 'Line');
    };
    const tempSprite = _component.createTemporaryPoint('ADD_POINT');
    _component.disableLayers = {
      lines: false,
      mesh: true,
      points: true,
    };
    _addPointListener.pointerMoveCollisionListener$
      .pipe(
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            tempSprite.visible = false;
          } else {
            tempSprite.visible = true;
          }
        }),
        filter((intersection) => !!intersection && intersection.length > 0),
        map((intersects) => {
          if (!intersects) return;
          return intersects[0];
        }),
        takeUntil(this._removeListeners),
        finalize(() => {
          _component.removeTemporaryPoint();
          _component.disableLayers = {
            lines: false,
            mesh: true,
            points: false,
          };
        })
      )
      .subscribe((intersectionObject) => {
        if (!intersectionObject) return;
        const position = intersectionObject.point.add(
          new Vector3(
            intersectionObject.normal?.x || 0,
            intersectionObject.normal?.y || 0,
            intersectionObject.normal?.z || 0
          ).multiply(new Vector3(0.03, 0.03, 0.03))
        );
        const { x, y, z } = _component.getClosestPoint(
          intersectionObject.object.userData['id'],
          position
        );
        tempSprite.position.set(x, y, z);
      });
    return _addPointListener.pointerDownCollisionListener$.pipe(
      filter((intersects) => !!intersects && intersects.length > 0),
      map((intersects) => {
        if (!intersects || !intersects.length) return;
        const intersect = intersects[0];

        if (intersect) {
          const areaComponentId = intersect.object.parent?.name;
          if (!areaComponentId) return;
          const _component = this._measurementMap.get(areaComponentId);
          if (!_component) return;
          if (_component.selected === false) {
            _component.selected = true;
            return;
          }
          this.removeListener();

          return _component.addClosestPoint(
            intersect.object.userData['id'],
            intersect.point,
            intersect.normal || new Vector3()
          );
        }
        return undefined;
      }),
      takeUntil(this._removeListeners)
    );
  };
  private _areaCreationPointListener = (): Observable<string | undefined> => {
    const _component = this.selectedComponent;
    if (!_component) return of(undefined);
    const tempSprite = _component.createTemporaryPoint('CREATION_POINT');
    if (_component.mode !== 'create') {
      throw Error('You cannot add after creation end');
    }
    this._raycasterHelper.pointerMoveCollisionListener$
      .pipe(
        takeUntil(this._removeListeners),
        finalize(() => {
          _component.removeTemporaryPoint();
        })
      )
      .subscribe((intersectionObject) => {
        if (!intersectionObject || !intersectionObject.length) return;
        const { x, y, z } = intersectionObject[0].point;
        tempSprite.position.set(x, y, z);
      });

    return this._raycasterHelper.pointerDownCollisionListener$.pipe(
      first(),
      takeUntil(this._removeListeners),
      tap((pointer) => {
        _component.addPoint(
          pointer[0].point,
          pointer[0].normal || new Vector3()
        );
      }),
      map(() => {
        this.removeListener();

        return undefined;
      })
    );
  };
  private _onClickObserver = () => {
    const _onClickColider = new SimlabCollider(this.camera, this.scene, false);
    _onClickColider.layers.enable(21);
    _onClickColider.objectsCb = () => {
      const objects: Object3D<Event>[] = [];
      this.scene.traverse(
        (object) =>
          object.name.startsWith('AREA_BACKGROUND') && objects.push(object)
      );
      return objects;
    };
    _onClickColider.pointerDownCollisionListener$
      .pipe(
        filter(() => {
          const _component = this.selectedComponent;

          return (
            this._listenersEnabled === false &&
            (!_component || _component.mode === 'read')
          );
        }),
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            this._transformControls && this._transformControls.detach();
            return;
          }
          const [intersect] = intersects.filter((object) => {
            return object.object.parent?.visible;
          });
          if (intersect) {
            const areaComponentId = intersect.object.parent?.name;
            if (!areaComponentId) return;
            const component = this._measurementMap.get(areaComponentId);
            if (!component) return;
            if (component.selected === false) {
              component.selected = true;
              return;
            }
          } else {
            this._transformControls && this._transformControls.detach();
          }
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  };
  private _init = () => {
    this.scene.add(this._transformControls);
    this._measurementMap.mapChange$
      .pipe(
        switchMap((entries) => {
          const selectedId = this._selectedComponentId.getValue();
          const _component = selectedId && this._measurementMap.get(selectedId);
          _component && (_component.selected = true);
          return merge(
            ...Array.from(entries.values()).map((component) => {
              if (
                this._hiddenAreasOmit &&
                !this._hiddenAreasOmit.includes(component.id) &&
                selectedId !== component.id
              ) {
                component.hide();
              }
              return component.selectionChange$;
            })
          ).pipe(startWith(selectedId));
        }),
        takeUntil(this._destroy)
      )
      .subscribe((selectedComponentId: string | undefined) => {
        this._selectedComponentId.next(selectedComponentId);

        this._measurementMap.forEach((component) => {
          if (selectedComponentId !== component.id) component.selected = false;
        });
      });
    this._onClickObserver();
  };
  addMeasurement = (area: TMeasurementMesh): string => {
    //TODO:( olek) from unity -> threejs
    // const transformedPositions: Vertice[] = area.vertices.map(
    //   ({ x, y, z, index }) => {
    //     const calcPosition = offset.to3dPosition(new Vector3(x, y, z));
    //     return {
    //       x: calcPosition.x,
    //       y: calcPosition.y,
    //       z: -calcPosition.z,
    //       index,
    //     };
    //   }
    // );
    const transformedDate: TMeasurementMesh = {
      ...area,
      // vertices: transformedPositions,
    };
    this._createAreaComponent(transformedDate);

    return area.id;
  };
  constructor(private readonly _featureBase: IFeatureBase) {
    this._init();
  }
  set selectedMeasurementMode(mode: MeasurementMode) {
    throw new Error('Method not implemented.');
  }
  get selectedMeasurementMode(): MeasurementMode {
    throw new Error('Method not implemented.');
  }
  set selectedMeasurement(measurementId: string | undefined) {
    throw new Error('Method not implemented.');
  }
  get selectedMeasurement(): string | undefined {
    throw new Error('Method not implemented.');
  }
  get hiddenMeasurementsOmit(): string[] | undefined {
    throw new Error('Method not implemented.');
  }
  isTouchDevice() {
    return (
      ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        ('msMaxTouchPoints' in navigator &&
          ((navigator as any).msMaxTouchPoints as number) > 0)) &&
      !window.matchMedia('(pointer:fine)').matches
    );
  }
  createMeasurement = (): string => {
    const area: TMeasurementMesh = {
      id: randomUUID(),
      points: [],
      color: '#4374E4',
    };
    this._createAreaComponent(area);
    this.selectedArea = area.id;
    return area.id;
  };
  cancelMeasurementCreation = (): void => {
    const _component = this.selectedComponent;
    if (!_component) return;
    if (_component.mode === 'create') this.deleteSelectedMeasurement();
    if (_component.mode === 'update') _component.cancelAllChanges();
  };
  deleteMeasurement = (areaId: string): void => {
    const _component = this._measurementMap.get(areaId);
    if (_component) {
      _component.destroy();
      this._measurementMap.delete(areaId);
      this._transformControls.detach();
    }
  };
  deleteSelectedMeasurement = (): void => {
    const _selectedId = this._selectedComponentId.getValue();
    _selectedId && this.deleteMeasurement(_selectedId);
    this._selectedComponentId.next(undefined);
  };
  editSelectedMeasurement = (): void => {
    const _component = this.selectedComponent;
    if (!_component) return;
    this._onVertexClick(_component);
    _component && (_component.mode = 'update');
  };
  updateMeasurementColor = (id: string, color: string | undefined): void => {
    const _component = this._measurementMap.get(id);
    if (_component && color) {
      _component.setColor(color);
    }
  };
  updateSelectedMeasurementColor = (color: string | undefined): void => {
    const _component = this.selectedComponent;
    if (_component && color) {
      _component.setColor(color);
    }
  };
  addListener = (
    listenerActionType: 'ADD_POINT' | 'REMOVE_POINT' | 'CREATION_POINT'
  ): Observable<string | undefined> => {
    switch (listenerActionType) {
      case 'ADD_POINT': {
        this._listenersEnabled = true;
        this._transformControls && this._transformControls.detach();
        return this._addPointListener();
      }
      case 'REMOVE_POINT': {
        this._listenersEnabled = true;
        return this._removePointListener();
      }
      case 'CREATION_POINT': {
        this._listenersEnabled = true;
        return this._areaCreationPointListener();
      }
      default: {
        throw new Error('unknown listener type');
      }
    }
  };
  removeListener = (): void => {
    this._removeListeners.next();
    this._listenersEnabled = false;
  };

  hideMeasurements = (omit: string[] | undefined = []): void => {
    this._hiddenAreasOmit = omit;
    omit.forEach((omit) => {
      this._measurementMap.get(omit)?.show();
    });
    this._measurementMap.forEach(
      (component: MeasurementMeshComponent) =>
        !omit.includes(component.id) && component.hide()
    );
  };
  showMeasurements = (omit: string[] | undefined = []): void => {
    this._hiddenAreasOmit = undefined;
    this._measurementMap.forEach(
      (component: MeasurementMeshComponent) =>
        !omit.includes(component.id) && component.show()
    );
  };
  deleteAllMeasurements = (): void => {
    Array.from(this._measurementMap.keys()).forEach((id) =>
      this.deleteMeasurement(id)
    );
  };
  set sizeUnit(unit: Units) {
    this._measurementMap.forEach((component) => {
      component.unit = unit;
    });
    this._sizeUnit = unit;
  }
  get sizeUnit(): Units {
    return this._sizeUnit;
  }
  set selectedAreaMode(mode: MeasurementMode) {
    const component = this.selectedComponent;
    component && (component.mode = mode);
  }
  get selectedAreaMode(): MeasurementMode {
    return this.selectedComponent?.mode;
  }
  set selectedArea(id: TMeasurementMesh['id'] | undefined) {
    if (!id) {
      const selectedId = this._selectedComponentId.getValue();
      if (!selectedId) return;
      const selectedComponent = this._measurementMap.get(selectedId);
      selectedComponent && (selectedComponent.selected = false);
      return;
    }
    const component = this._measurementMap.get(id);

    if (component) {
      component.selected = true;
    } else {
      this._selectedComponentId.next(id);
    }
  }
  get selectedArea(): TMeasurementMesh['id'] | undefined {
    const id = this._selectedComponentId.getValue();
    return id;
  }
  undo = (): void => {
    const component = this.selectedComponent;
    component?.undo();
  };
  redo = (): void => {
    const component = this.selectedComponent;
    component?.redo();
  };
  finish = (): void => {
    this._transformControls && this._transformControls.detach();

    const _component = this.selectedComponent;
    if (!_component) return;
    _component.finish();
  };
  get canRedo(): boolean {
    const _component = this.selectedComponent;
    if (!_component) return false;
    return _component.canRedo;
  }
  get canUndo(): boolean {
    const _component = this.selectedComponent;
    if (!_component) return false;
    return _component.canUndo;
  }
  set segmentsVisibility(visible: boolean) {
    const _component = this.selectedComponent;
    if (!_component) return;
    _component.segmentsVisibility = visible;
  }
  get segmentsVisibility() {
    const _component = this.selectedComponent;
    if (!_component) return false;
    return _component.segmentsVisibility;
  }
  dispose = (): void => {
    this._destroy.next();
    this._removeListeners.next();
    this.deleteAllMeasurements();
    this._transformControls.detach();
    this.scene.remove(this._transformControls);
  };

  get transformControls() {
    return this._transformControls;
  }
  get hiddenAreasOmit() {
    return this._hiddenAreasOmit;
  }
  get listenersEnabled() {
    return this._listenersEnabled;
  }
  get count() {
    return this._measurementMap.size;
  }
}
