import { CdkPortalOutlet, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  InjectionToken,
  Renderer2,
  signal,
  Signal,
  viewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { SimSidemenuComponent } from '@simOn/layout';
import { PrivilegesFacade } from '@simOn/privileges';
import { SpaceFacade } from '@simOn/space';
import { ApartmentInterface } from '@simOn/space/information/models';
import { SidenavComponent, SimSidenavModule } from '@simOn/ui/sim-sidenav';
import { filter } from 'rxjs';

export const RightSidenavLayout = new InjectionToken<RightSidenavLayoutComponent>('RightSidenavLayoutComponent');
export interface IRightSidenavLayout {
  readonly _sidenavRight: Signal<SidenavComponent>;
  readonly _outlet: Signal<CdkPortalOutlet>;
  selectedPortal: ComponentPortal<any> | undefined;
  get sidenavRight(): SidenavComponent;
  get outlet(): CdkPortalOutlet;
  set panelWidth(width: string);
}
@Component({
  standalone: true,
  imports: [PortalModule, SimSidenavModule, RouterModule, SimSidemenuComponent],
  template: `
    <sim-sidenav-container [hasBackdrop]="hasBackdrop()">
      <sim-sidenav mode="over" #sidenavRight position="right" class="right-menu">
        <ng-template #outlet="cdkPortalOutlet" [cdkPortalOutlet]="selectedPortal"></ng-template>
      </sim-sidenav>
      <sim-sidenav-content>
        <div class="content-wrapper flex">
          <div class="content-wrapper__menu" [class.content-wrapper__menu--expanded]="isMenuExpanded()">
            <layout-sidemenu
              [apartmentData]="apartmentData()!"
              [interiorDesignerVisible]="interiorVisible()"
              [canViewProviders]="canViewProviders()"
              (expanded)="isMenuExpanded.set($event)"
            ></layout-sidemenu>
          </div>
          <div class="content-wrapper__outlet">
            <router-outlet></router-outlet>
          </div>
        </div>
      </sim-sidenav-content>
    </sim-sidenav-container>
  `,
  styleUrl: './right-sidenav-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: RightSidenavLayout,
      useExisting: RightSidenavLayoutComponent
    }
  ]
})
export class RightSidenavLayoutComponent implements IRightSidenavLayout {
  private readonly _privilegesFacade = inject(PrivilegesFacade);
  private readonly _spaceFacade = inject(SpaceFacade);
  private readonly _renderer = inject(Renderer2);
  private readonly _canViewProviders = toSignal(this._privilegesFacade.hasGeneralAccessTo$('CanViewProviders'));
  private readonly _interiorVisible = toSignal(
    this._privilegesFacade.hasGeneralAccessTo$('CanManageInteriorDesignerEditor')
  );

  readonly canViewProviders = computed(() => {
    return this._canViewProviders() || false;
  });

  readonly apartmentData = toSignal(
    this._spaceFacade.selectedApartment$.pipe(filter((apartment): apartment is ApartmentInterface => !!apartment))
  );

  readonly interiorVisible = computed(() => {
    const interiorVisible = this._interiorVisible();
    return interiorVisible || false;
  });

  readonly _sidenavRight = viewChild.required<SidenavComponent>('sidenavRight');
  readonly _sidenavRightElementRef = viewChild.required('sidenavRight', { read: ElementRef<SidenavComponent> });
  readonly _outlet = viewChild.required<CdkPortalOutlet>('outlet');
  selectedPortal: ComponentPortal<any> | undefined;
  readonly hasBackdrop = signal(true);

  readonly isMenuExpanded = signal<boolean>(false);

  get sidenavRight() {
    return this._sidenavRight();
  }

  get outlet() {
    return this._outlet();
  }

  set panelWidth(width: string) {
    this._renderer.setStyle(this._sidenavRightElementRef().nativeElement, 'width', width);
  }

  close() {
    this.sidenavRight.opened = false;
  }
}
