import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { RoomsApiService } from '@simOn/room/data-access';
import {
  AddRoom,
  AddRoomFailure,
  AddRoomSuccess,
  DeleteRoom,
  DeleteRoomFailure,
  DeleteRoomSuccess,
  GetMasterRoom,
  GetMasterRoomFailure,
  GetMasterRoomSuccess,
  GetRoom,
  GetRoomFailure,
  GetRoomSuccess,
  GetRooms,
  GetRoomsFailure,
  GetRoomsSuccess,
  SetRoomCamera,
  SetRoomCameraFailure,
  SetRoomCameraSuccess,
  UpdateRoom,
  UpdateRoomFailure,
  UpdateRoomSuccess,
  UpdateSweepInRoom,
  UpdateSweepInRoomFailure,
  UpdateSweepInRoomSuccess
} from './room.actions';

@Injectable()
export class RoomEffects {
  private readonly roomsApiService = inject(RoomsApiService);
  private readonly actions$ = inject(Actions);
  getRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRooms),
      mergeMap((result) =>
        this.roomsApiService.getRooms().pipe(
          map((response) => GetRoomsSuccess({ state: response })),
          catchError((error) => of(GetRoomsFailure(error)))
        )
      )
    )
  );

  getRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRoom),
      mergeMap((result) =>
        this.roomsApiService.getRoom(result.state).pipe(
          map((response) => GetRoomSuccess({ state: response })),
          catchError((error) => of(GetRoomFailure(error)))
        )
      )
    )
  );
  getMasterRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMasterRoom),
      mergeMap((result) =>
        this.roomsApiService.getMasterRoom().pipe(
          map((response) => GetMasterRoomSuccess({ masterRoom: response })),
          catchError((error) => of(GetMasterRoomFailure(error)))
        )
      )
    )
  );
  addRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddRoom),
      exhaustMap((result) => this.roomsApiService.createRoom(result.state)),
      mergeMap((response) => concat([AddRoomSuccess({ state: response })])),
      catchError((error) => of(AddRoomFailure(error)))
    )
  );

  updateRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateRoom),
      mergeMap((result) => this.roomsApiService.putRoom(result.room).pipe(map(() => result.room))),
      map((response) => UpdateRoomSuccess({ state: response })),
      catchError((error) => of(UpdateRoomFailure(error)))
    )
  );

  updateRoomCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetRoomCamera),
      mergeMap(({ roomId, roomCamera }) =>
        this.roomsApiService.setRoomCamera({ roomId, roomCamera }).pipe(
          map(() => SetRoomCameraSuccess({ roomId, roomCamera })),
          catchError((error) => of(SetRoomCameraFailure(error)))
        )
      )
    )
  );
  updateSweepInRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSweepInRoom),
      mergeMap((result) =>
        this.roomsApiService.updateSweepInRoom(result.roomId, result.scanModelId, result.sweepId).pipe(
          map(() => UpdateSweepInRoomSuccess()),
          catchError((error) => of(UpdateSweepInRoomFailure(error)))
        )
      )
    )
  );

  deleteRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRoom),
      exhaustMap((result) => this.roomsApiService.deleteRoom(result.state)),
      mergeMap((response) => concat([DeleteRoomSuccess({ state: response }), GetMasterRoom()])),
      catchError((error) => of(DeleteRoomFailure(error)))
    )
  );
}
