import { InjectionToken } from '@angular/core';
import { Subject, fromEvent, map, takeUntil, tap } from 'rxjs';
import {
  Camera,
  Event,
  Intersection,
  Object3D,
  Raycaster,
  RaycasterParameters,
  Scene,
  Vector2,
} from 'three';
export const SIMLAB_COLLIDER = new InjectionToken<SimlabCollider>(
  'Simlab collider'
);
export class SimlabCollider {
  private readonly _raycaster: Raycaster = new Raycaster();
  private readonly _pointer: Vector2 = new Vector2();
  private readonly _destroyer: Subject<void> = new Subject<void>();
  readonly pointerDownCollisionListener$ = fromEvent<PointerEvent>(
    window,
    'pointerdown'
  ).pipe(map((event) => this._pointerCollision(event)));

  readonly pointerMoveCollisionListener$ = fromEvent<PointerEvent>(
    window,
    'pointermove'
  ).pipe(map((event) => this._pointerCollision(event)));
  objectsCb?: () => Object3D<Event>[];

  constructor(
    private readonly _camera: Camera,
    private readonly _scene: Scene,
    private readonly _recursive: boolean = false
  ) {
    this._raycaster.layers.enable(3);
    // this._raycaster.params = {
    //   ...this._raycaster.params,
    //   Points: {
    //     threshold: 0.1,
    //   },
    // };
    this._pointerDownObserverver();
  }

  destroy() {
    this._destroyer.next();
  }
  set params(params: RaycasterParameters) {
    this._raycaster.params = {
      ...this._raycaster.params,
      ...params,
    };
  }
  get layers() {
    return this._raycaster.layers;
  }

  addObjectToWorld$() {
    return this.pointerMoveCollisionListener$.pipe(
      takeUntil(this.pointerDownCollisionListener$)
    );
  }

  private _pointerDownObserverver() {
    this.pointerDownCollisionListener$
      .pipe(
        tap((intersects) => this._globalCollision(intersects)),
        takeUntil(this._destroyer)
      )
      .subscribe();
  }
  private _pointerCollision(event: PointerEvent) {
    this._setPointer(event);
    this._raycaster.setFromCamera(this._pointer, this._camera);
    return this._raycaster.intersectObjects(
      this.objectsCb ? this.objectsCb() : this._scene.children,
      this._recursive
    );
  }
  private _globalCollision(
    intersects: Intersection<Object3D<import('three').Event>>[]
  ) {
    for (let i = 0; i < intersects.length; i++) {
      const object = intersects[i].object;
      if (!('collision' in object)) continue;
      object.dispatchEvent({ type: 'onClick' });
      break;
    }
  }

  private _setPointer(event: PointerEvent) {
    this._pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this._pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }
}
