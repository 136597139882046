<div class="overflow">
  <div class="overflow__wrapper" #scrollContainer (scroll)="updateChanges()">
    <ng-content></ng-content>
  </div>
  @if(isOverflowing()){
  <div class="overflow__arrows">
    <button class="overflow__arrow left" (click)="scroll('left')" [disabled]="isScrollStart()">
      <sim-icon icon="back" sizeType="small"></sim-icon>
    </button>
    <button class="overflow__arrow right" (click)="scroll('right')" [disabled]="isScrollEnd()">
      <sim-icon icon="next" sizeType="small"></sim-icon>
    </button>
  </div>
  }
</div>
