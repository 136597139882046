import { inject, Injectable } from '@angular/core';
import { MatterportComponent } from '@simlab/matterport';
import { ComponentConfiguration, SpriteConfiguration } from '@simlab/simlab-facility-management/scene-object';
import { TComponentController } from '@simOn/common/scan';
import { SceneFacade } from '@simOn/scene';
import { SceneShortcutBodyInterface } from '@simOn/scene/element/models';
import { catchError, concatAll, defer, filter, iif, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { Vector3 } from 'three';
import { ComponentControllerBase } from './component-controller-base';
@Injectable()
export class SceneComponentsControllerService extends ComponentControllerBase implements TComponentController {
  private readonly _sceneFacade = inject(SceneFacade);
  override get mode(): string {
    return 'scenes';
  }
  public set selectedMode(value: string) {
    value === this.mode ? this._showAll() : this._hideAll();
  }

  readonly _nodes: Record<string, MatterportComponent> = {};
  readonly components$: Observable<void> = defer(() => this.selectedModel).pipe(
    switchMap(() =>
      this._sceneFacade.simlabScenesShortcuts$.pipe(
        tap((scenes: SceneShortcutBodyInterface[]) => {
          this._removeDeletedScenes(scenes.map((scene) => scene.id));
        }),
        concatAll(),
        filter((scene: SceneShortcutBodyInterface) => {
          return !!scene.transformation?.position;
        }),
        mergeMap((scene: SceneShortcutBodyInterface) => {
          return iif(
            () => !!this._nodes[scene.id],
            of(true).pipe(
              tap(() => this._updateState(scene)),
              take(1)
            ),
            of(true).pipe(
              switchMap(() =>
                this._create3DComponent$(scene).pipe(
                  tap((component: any) => {
                    this._nodes[scene.id] = component;
                  }),
                  take(1)
                )
              ),
              take(1),
              catchError((e) => {
                console.log(e);
                return of(e);
              })
            )
          ).pipe(
            take(1),
            catchError((e) => {
              console.log(e);
              return of(e);
            })
          );
        })
      )
    )
  );

  private _removeDeletedScenes(scenes: string[]) {
    const currentAssets = Object.keys(this._nodes);
    if (currentAssets.length > 0) {
      const collection = [...currentAssets.filter((x) => !scenes.includes(x))];
      collection.forEach((id) => {
        if (this._nodes[id]) {
          this.scanManagerService.component.deleteNote(id);
          delete this._nodes[id];
        }
      });
    }
  }

  private _updateState(scene: SceneShortcutBodyInterface): void {
    this.scanManagerService.component.updatePositionWithOffset({
      id: scene.id,
      normal: scene.transformation.normalVector as Vector3,
      position: scene.transformation.position as Vector3,
      stemHeight: scene.transformation.stemLength!,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3
    });
  }
  private _create3DComponent$(scene: SceneShortcutBodyInterface) {
    const component: ComponentConfiguration = {
      id: scene.id,
      normal: scene.transformation.normalVector as Vector3,
      stemHeight: scene.transformation.stemLength!,
      position: scene.transformation.position as Vector3,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3,

      userData: {
        type: 'scenes',
        sceneId: scene.sceneId
      },
      autoScale: false,
      objects: [
        new SpriteConfiguration({
          icon: `/${$localize.locale}/assets/SCENES_ICON.png`
        })
      ]
    };
    return this.scanManagerService.component.addComponentWithOffset$(component).pipe(
      catchError((e) => {
        console.log(e);
        return of(e);
      })
    );
  }
}
