import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AddTicketCommentInterface, UpdateTicketCommentInterface } from '@simOn/ticket/comments/models';
import { ITicketStatusUpdate, IUpdateTicket, Ticket } from '@simOn/ticket/element/models';
import { map, Observable } from 'rxjs';
import { TicketAttachments } from '../models/ticket-attachments.model';
import {
  AddTicket,
  AddTicketComment,
  AddTicketSuccess,
  ClearTicketState,
  DeleteTicket,
  DeleteTicketComment,
  DeleteTicketFailure,
  DeleteTicketFromStore,
  DeleteTicketSuccess,
  DeleteUploadedFileSimplified,
  GetTicket,
  GetTickets,
  HandleTicketModified,
  HandleTicketModifiedSuccess,
  MarkTicketAsRead,
  MarkTicketAsReadSuccess,
  PreventTicketRefresh,
  SetSelectedId,
  UpdateStatus,
  UpdateStatusSuccess,
  UpdateTicket,
  UpdateTicketComment,
  UpdateTicketFailure,
  UpdateTicketSuccess
} from './ticket.actions';
import {
  GetAttachmentSelectedTicket,
  GetSelectedId,
  GetSelectedTicket,
  GetSelectedTicketById,
  GetSelectedTicketId,
  SelectAllTickets,
  SelectPreventRefresh,
  TicketsNotificationCounter
} from './ticket.selectors';

@Injectable({
  providedIn: 'root'
})
export class TicketFacade {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  readonly allTickets$: Observable<Ticket[]> = this.store
    .select(SelectAllTickets)
    .pipe(map((tickets) => tickets.filter(({ id }) => !!id)));
  readonly selectedTicket$: Observable<Ticket | undefined> = this.store.select(GetSelectedTicket);
  readonly selectedTicketAttachments$: Observable<TicketAttachments[] | undefined> =
    this.store.select(GetAttachmentSelectedTicket);
  readonly selectedTicketId$: Observable<string | undefined> = this.store.select(GetSelectedTicketId);
  readonly addTicketSuccess$ = this.actions$.pipe(ofType(AddTicketSuccess));
  readonly updateStatusSuccess$ = this.actions$.pipe(ofType(UpdateStatusSuccess));
  readonly markAsReadSuccess$ = this.actions$.pipe(ofType(MarkTicketAsReadSuccess));
  readonly updateTicketStatus$ = this.actions$.pipe(ofType(UpdateTicketSuccess, UpdateTicketFailure));
  readonly notesNotificationCounter$: Observable<number> = this.store.select(TicketsNotificationCounter);
  readonly preventRefresh$: Observable<boolean | undefined> = this.store.select(SelectPreventRefresh);
  readonly modified$ = this.actions$.pipe(ofType(HandleTicketModifiedSuccess));
  readonly deleteTicketStatus$ = this.actions$.pipe(ofType(DeleteTicketSuccess, DeleteTicketFailure));
  readonly selectedId$: Observable<string | undefined> = this.store.select(GetSelectedId);

  setSelectedId(selectedId: string): void {
    this.store.dispatch(SetSelectedId({ selectedId }));
  }

  selectedTicketById$ = (id: string): Observable<Ticket | undefined> => this.store.select(GetSelectedTicketById(id));

  getTicket(ticketId: string): void {
    this.store.dispatch(GetTicket({ state: ticketId }));
  }

  getTickets(apartmentId: string, autoUpdate: boolean): void {
    this.store.dispatch(GetTickets({ state: apartmentId, autoUpdate }));
  }

  addTicket(ticket: IUpdateTicket): void {
    this.store.dispatch(AddTicket({ state: ticket }));
  }

  handleTicketModified(ticketId: string): void {
    this.store.dispatch(HandleTicketModified({ ticketId }));
  }

  updateTicket(ticket: IUpdateTicket): void {
    this.store.dispatch(UpdateTicket({ state: ticket }));
  }

  updateStatus(state: ITicketStatusUpdate): void {
    this.store.dispatch(UpdateStatus({ state }));
  }

  deleteTicket(ticketId: string): void {
    this.store.dispatch(DeleteTicket({ state: ticketId }));
  }

  addTicketComment(ticketId: string, chatMessage: AddTicketCommentInterface): void {
    this.store.dispatch(AddTicketComment({ state: { ticketId: ticketId, ticketComment: chatMessage } }));
  }

  deleteTicketFromStore(ticketId: string): void {
    this.store.dispatch(DeleteTicketFromStore({ ticketId }));
  }

  updateTicketComment(ticketId: string, chatMessage: UpdateTicketCommentInterface): void {
    this.store.dispatch(UpdateTicketComment({ state: { ticketId: ticketId, ticketComment: chatMessage } }));
  }

  deleteTicketComment(commentId: string): void {
    this.store.dispatch(DeleteTicketComment({ state: commentId }));
  }

  deleteUploadFile(fileGuid: string): void {
    this.store.dispatch(DeleteUploadedFileSimplified({ state: { fileGuid } }));
  }

  preventRefresh(prevent: boolean): void {
    this.store.dispatch(PreventTicketRefresh({ prevent }));
  }

  markAsRead(ticketId: string): void {
    this.store.dispatch(MarkTicketAsRead({ ticketId: ticketId }));
  }

  clearState() {
    this.store.dispatch(ClearTicketState());
  }
}
