import {
  fromEvent,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

export function mobileTouch$(progress: HTMLElement, domElement: HTMLElement) {
  const el = domElement as HTMLCanvasElement;

  const mouseUp$ = fromEvent(el as HTMLCanvasElement, 'touchend').pipe(
    tap(() => {
      progress.style.display = 'none';
    })
  );
  const mouseMove$ = fromEvent(el as HTMLCanvasElement, 'touchmove').pipe(
    tap(() => {
      progress.style.display = 'none';
    })
  );
  const mouseDown$ = fromEvent<TouchEvent>(
    el as HTMLCanvasElement,
    'touchstart'
  ).pipe(
    switchMap((e: Event) =>
      timer(200).pipe(
        takeUntil(mouseUp$ || mouseMove$),
        mergeMap(() => of(e))
      )
    ),
    tap((e: Event) => {
      progress.style.display = 'flex';

      progress.style.top = `${(e as TouchEvent).changedTouches[0].clientY - 120
        }px`;
      progress.style.left = `${(e as TouchEvent).changedTouches[0].clientX - 50
        }px`;
    })
  );
  return mouseDown$.pipe(
    switchMap((down: Event) =>
      timer(1000).pipe(
        takeUntil(mouseUp$ || mouseMove$),
        mergeMap(() => of(down))
      )
    ),
    tap(() => {
      progress.style.display = 'none';
    })
  );

}
