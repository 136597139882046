import { ReplaceStencilOp, Sprite, Vector3 } from 'three';
import {
  generateClassInstance,
  ObjectConfig,
} from '../types/custom-component.type';
import { ThreeObjectBase } from './three-object.base';

export class SpriteConfiguration implements ObjectConfig<SpriteComponent> {
  private _instance!: SpriteComponent;
  constructor(
    readonly config: {
      icon: string;
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) { }
  init(threeContext: typeof import('three')) {
    this._instance = generateClassInstance<
      SpriteComponent,
      SpriteConfiguration
    >(SpriteComponent, threeContext, this);
  }
  get instance() {
    return this._instance;
  }
}
export class SpriteComponent extends ThreeObjectBase {
  set rotation(position: THREE.Euler) {
    throw new Error('Method not implemented.');
  }
  private _sprite!: THREE.Sprite;
  constructor(
    private readonly three: typeof import('three'),
    private readonly configuration: SpriteConfiguration['config']
  ) {
    super();
    this.init(three, configuration);
  }

  init(
    threeContext: typeof import('three'),
    configuration: SpriteConfiguration['config']
  ): void {
    this._sprite = this._createObject(threeContext);
    this._icon = this.configuration.icon;
    this._sprite.layers.set(3);
    configuration.position ?? ({ x: 0, y: 0, z: 0 } as Vector3);
  }

  private set _icon(iconPath: string) {
    const map = this._loadMaterial(iconPath);
    // map.encoding = sRGBEncoding;
    const material = this._sprite.material;
    if (material.dispose) material.dispose();
    material.map = map;
    material.needsUpdate = true;
    this.configuration.icon = iconPath;
  }

  set icon(iconPath: string) {
    if (iconPath !== this.configuration.icon) {
      this._icon = iconPath;
    }
  }

  private _createObject(threeContext: typeof import('three')): Sprite {
    const material = new threeContext.SpriteMaterial({
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      alphaTest: 0.2,
      toneMapped: false,
    });
    material.stencilWrite = true;
    material.stencilZPass = ReplaceStencilOp;
    return new threeContext.Sprite(material);
  }
  get object3D(): Sprite {
    return this._sprite;
  }
  set position(position: Vector3) {
    this._sprite.position.set(position.x, position.y, position.z);
  }
  set scale(scale: Vector3) {
    const { x, y, z } = scale;
    this._sprite.scale.set(x, y, z);
  }

  hide() {
    if (this._sprite.visible) {
      this._sprite.visible = false;
    }
  }

  show() {
    if (!this._sprite.visible) {
      this._sprite.visible = true;
    }
  }

  private _loadMaterial(iconPath: string) {
    return (new this.three.TextureLoader() as THREE.TextureLoader).load(
      iconPath
    );
  }
}
