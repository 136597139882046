import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
  ViewEncapsulation
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { MediaApiService } from '@simOn/common/media';
import { getUrlWithSmallSuffix } from '@simOn/common/upload-image';
import { ApartmentInterface } from '@simOn/space/information/models';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { AssetsImage } from '@simOn/ui/sim-footer';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SimTooltipComponent } from '@simOn/ui/sim-tooltip';
import { derivedAsync } from 'ngxtension/derived-async';
import { SimSidemenuItemComponent } from '../sim-sidemenu-item/sim-sidemenu-item.component';
import { ImagePathPipe } from '../sim-sidenav/image-path.pipe';

@Component({
  selector: 'layout-sidemenu',
  standalone: true,
  imports: [SimButtonModule, RouterModule, SimIconModule, ImagePathPipe, SimSidemenuItemComponent, SimTooltipComponent],
  templateUrl: './sim-sidemenu.component.html',
  styleUrl: './sim-sidemenu.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimSidemenuComponent {
  private readonly _mediaApiService = inject(MediaApiService);
  protected readonly assetsImage = AssetsImage;
  protected readonly TOOLTIP_INFO: Record<string, string> = {
    splitView: $localize`:@@LEFT_PANEL_MENU_3D_BROWSE_SPACES:All spaces`,
    apartment: $localize`:@@LEFT_PANEL_MENU_3D_WALK_BUTTON:Virtual walk`,
    iotManager: $localize`:@@LEFT_PANEL_MENU_3D_IOT_MANAGER:IoT manager`,
    assets: $localize`:@@LEFT_PANEL_MENU_3D_ASSETS:Assets`,
    integrations: $localize`:@@LEFT_PANEL_MENU_3D_INTEGRATIONS:Integrations`,
    interiorEditor: $localize`:@@LEFT_PANEL_MENU_3D_INTERIOR_EDITOR:Interior editor`,
    spaceSettings: $localize`:@@LEFT_PANEL_MENU_3D_SPACE_SETTINGS:Space settings`
  };

  protected readonly position: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 30,
      offsetY: -4,
      panelClass: 'sim-sidemenu__tooltip'
    }
  ];

  readonly apartmentData = input.required<ApartmentInterface>();
  readonly canViewProviders = input.required<boolean>();
  readonly interiorDesignerVisible = input.required<boolean>();
  readonly expanded = output<boolean>();
  readonly isExpanded = signal<boolean>(false);
  readonly logoSrcPrefix = 'assets/images/logo/';
  readonly apartmentPlaceholderLogo = 'assets/images/House_sketch.png';
  readonly logoSrc = computed(() => {
    return `${this.logoSrcPrefix}${this.isExpanded() ? 'Logo_simon_smaller.svg' : 'Logo_simon_smaller_o.svg'}`;
  });
  readonly apartmentPhotoUrl = derivedAsync(() => {
    const apartment = this.apartmentData();
    return this._mediaApiService.generateBlobUrlForDownload(getUrlWithSmallSuffix(apartment.photoUrl!));
  });

  protected changeExpandStatus(): void {
    this.isExpanded.set(!this.isExpanded());
    this.expanded.emit(this.isExpanded());
  }
}
