import { NavBarDataInterface, NavLinkInterface } from '@simOn/common/containers';
import { PrivilegesFacade } from '@simOn/privileges';
import { GeneralPrivilegeTypeStringify } from '@simOn/privileges/element/models';
import { combineLatest, map } from 'rxjs';

export const PROPERTY_SETTINGS_PAGE_DATA: NavBarDataInterface = {
  headerTitle: $localize`:@@SPACE_SETTINGS_MAIN_TITLE:Space settings`,
  shortTitle: $localize`:@@SPACE_SETTINGS_SHORT_TITLE:Settings`,
  navLinks: [
    {
      label: $localize`:@@SPACE_SETTINGS_GENERAL_INFO:General info`,
      name: `General info`,
      path: `general-informations`,
      icon: 'info'
    },
    {
      label: $localize`:@@SPACE_SETTINGS_ROOMS:Rooms`,
      name: 'Rooms',
      path: 'rooms',
      icon: 'rooms'
    },
    {
      label: $localize`:@@SPACE_SETTINGS_USERS:Users`,
      name: 'Users',
      path: `users`,
      icon: 'people_alt'
    },
    {
      name: `Role templates`,
      label: $localize`:@@SPACE_SETTINGS_ROLE_TEMPLATES:Role templates`,
      path: `role-templates`,
      icon: 'role_templates'
    },
    {
      name: `History log`,
      label: $localize`:@@SPACE_SETTINGS_HISTORY_LOG:History log`,
      path: `activity-log`,
      icon: 'history_log'
    }
  ],
  activeLinkIndex: 0
};

export function PROPERTY_SETTINGS_PAGE_DATA_WITH_PRIVILEGES(privilegesFacade: PrivilegesFacade) {
  const navBar = { ...PROPERTY_SETTINGS_PAGE_DATA };
  return combineLatest([privilegesFacade.generalPrivileges$, privilegesFacade.isOwner$]).pipe(
    map(([generalPrivileges, isOwner]: [GeneralPrivilegeTypeStringify[], boolean]) => {
      navBar.navLinks?.forEach((navLink: NavLinkInterface) => {
        if (navLink.name === 'Activity log') {
          navLink.hasPrivilege = generalPrivileges.includes('CanViewApartmentEvents') || isOwner;
        }
        if (navLink.name === 'Users' || navLink.name === 'Role templates') {
          navLink.hasPrivilege = generalPrivileges.includes('CanCreateRoomsAndUsersAndRoles') || isOwner;
        }
      });
      return navBar;
    })
  );
}
