import { Injectable, OnDestroy } from '@angular/core';
import { ExtendedMap } from '@simlab/simlab-facility-management/common';
import {
  RaycasterHelper,
  randomUUID,
} from '@simlab/simlab-facility-management/scene-object';

import {
  ILineMeasurementComponent,
  TLineMeasurementElements,
  TMeasurementMesh,
  TMeasurementMeshInputs,
} from '@simlab/simlab-facility-management/sub-features/line-measurement';
import {
  IMeasurementComponent,
  IMeasurementTool,
  MeasurementMode,
  TMeasurementListenersAction,
  TMeasurementMeshChange,
  Units,
} from '@simlab/simlab-facility-management/sub-features/measurement';
import { IDisposable, MpSdk } from 'mpSdk';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  defer,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  firstValueFrom,
  from,
  fromEvent,
  iif,
  map,
  merge,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { Object3D, Vector3 } from 'three';
import {
  TransformControls as ThreeTransformControls,
  TransformControls,
} from 'three/examples/jsm/controls/TransformControls';
import { MatterportServiceBase } from '../base/matterport-base';
import {
  lineMeasurementToolType,
  makeLineMeasurementToolComponentRenderer,
} from '../base/matterport-measurement-mesh';
import { mobileTouch$ } from '../helpers/mobile-event';
import { Pointer } from '../models/dto';

export type LineMeasurementComponent = {
  object: MpSdk.Scene.IObject;
  node: MpSdk.Scene.INode;
  comp: MpSdk.Scene.IComponent &
  TMeasurementMeshInputs &
  ILineMeasurementComponent;
};

@Injectable()
export class LineMeasurementToolService
  extends MatterportServiceBase
  implements
  OnDestroy,
  IMeasurementTool<LineMeasurementComponent, TMeasurementMesh> {
  transformationEvent$: Observable<
    'change' | 'mouseDown' | 'mouseUp' | 'objectChange' | undefined
  > = of(undefined);
  private _raycasterHelper!: RaycasterHelper;
  private readonly _measurementMap: ExtendedMap<
    string,
    LineMeasurementComponent
  > = new ExtendedMap<string, LineMeasurementComponent>();
  private readonly _componentRegistered: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _transformControls!: ThreeTransformControls;
  private readonly _selectedComponentId: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  readonly selectedMeasurement$: Observable<string | undefined> =
    this._selectedComponentId.asObservable();
  readonly selectedMeasurementMode$: Observable<MeasurementMode> = defer(() =>
    this.selectedMeasurement$.pipe(
      switchMap((selectedArea) => {
        if (!selectedArea) return of(undefined);
        const component = this._measurementMap.get(selectedArea);
        if (!component) return of(undefined);
        return component.comp.mode$;
      }),
      distinctUntilChanged()
    )
  );
  readonly selectedMeasurementChange$: Observable<
    TMeasurementMeshChange<TMeasurementMesh> | undefined
  > = defer(() =>
    this.selectedMeasurement$.pipe(
      switchMap((selectedArea) => {
        if (!selectedArea) return of(undefined);
        const component = this._measurementMap.get(selectedArea);
        if (!component) return of(undefined);
        return component.comp.measurementChange$;
      })
    )
  );
  private readonly _removeListeners: Subject<void> = new Subject<void>();
  private _listenersEnabled = false;
  private _component!: IDisposable | null | undefined;
  private _sizeUnit: Units | undefined;
  private _hiddenAreasOmit: undefined | string[] = undefined;
  get transformControls(): ThreeTransformControls | undefined {
    return this._transformControls;
  }
  get listenersEnabled(): boolean {
    return this._listenersEnabled;
  }
  get hiddenMeasurementsOmit() {
    return this._hiddenAreasOmit;
  }
  private _matTransformControl?: {
    node: MpSdk.Scene.INode;
    control: MpSdk.Scene.IComponent;
    transformControls: TransformControls;
  };

  get transformControl() {
    return this._transformControls;
  }
  get selectedComponent(): LineMeasurementComponent | undefined {
    const id = this._selectedComponentId.getValue();
    if (!id) return;
    return this._measurementMap.get(id);
  }
  set selectedMeasurementMode(mode: MeasurementMode) {
    const component = this.selectedComponent;
    component && (component.comp.mode = mode);
  }
  get selectedMeasurementMode(): MeasurementMode {
    return this.selectedComponent?.comp.mode;
  }
  addListener<T = any>(
    listenerActionType: TMeasurementListenersAction
  ): Observable<T> {
    this._transformControls && this._transformControls.detach();
    this._matterportManager.position.setSweepsActive(false);
    switch (listenerActionType) {
      case 'ADD_POINT': {
        this._listenersEnabled = true;

        return this._addPointListener() as Observable<T>;
      }
      case 'REMOVE_POINT': {
        this._listenersEnabled = true;
        return this._removePointListener() as Observable<T>;
      }
      case 'CREATION_POINT': {
        this._listenersEnabled = true;
        return this._areaCreationPointListener() as Observable<T>;
      }
      default: {
        throw new Error('unknown listener type');
      }
    }
  }
  finish() {
    const component = this.selectedComponent;
    if (!component) return;
    component.comp.finish();
    this._transformControls &&
      this._transformControls.detach() &&
      (component.comp.collider = false);
    setTimeout(() => {
      this._matterportManager.position.setSweepsActive(true);
    }, 100);
  }

  cancelMeasurementCreation() {
    this._removeListeners.next();
    const component = this.selectedComponent;
    if (!component) return;
    if (component.comp.mode === 'create') this.deleteSelectedMeasurement();
    if (component.comp.mode === 'update') component.comp.cancelAllChanges();
    this._transformControls &&
      this._transformControls.detach() &&
      (component.comp.collider = false);
    setTimeout(() => {
      this._matterportManager.position.setSweepsActive(true);
    }, 100);
  }
  set sizeUnit(unit: Units) {
    this._measurementMap.forEach((component) => {
      component.comp.unit = unit;
    });
    this._sizeUnit = unit;
  }

  get canRedo(): boolean {
    const component = this.selectedComponent;
    if (!component) return false;
    return component.comp.canRedo;
  }
  get canUndo(): boolean {
    const component = this.selectedComponent;
    if (!component) return false;
    return component.comp.canUndo;
  }
  get count(): number {
    return this._measurementMap.size;
  }
  dispose = () => {
    throw Error('Method not implemented');
  };
  private _removePointListener(): Observable<string | undefined> {
    const component = this.selectedComponent;
    if (!component) return of(undefined);
    component.comp.mode = 'update';
    const tempSprite = component.comp.createTemporaryPoint('REMOVE_POINT');
    component.comp.disableLayers = {
      lines: true,
      mesh: true,
      points: false,
    };
    this._matterportManager.events.pointer$
      .pipe(
        map((pointer) => {
          const intersect = this._getPointerIntersection(pointer);
          return intersect?.filter((object) => object.object.visible);
        }),
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            tempSprite.visible = false;
          } else {
            tempSprite.visible = true;
          }
        }),
        filter((intersection) => !!intersection && intersection.length > 0),
        map((intersects) => {
          if (!intersects) return;
          return intersects[0];
        }),
        takeUntil(this._removeListeners),
        finalize(() => {
          component.comp.removeTemporaryPoint();
          component.comp.disableLayers = {
            lines: false,
            mesh: true,
            points: false,
          };
        })
      )
      .subscribe((intersectionObject) => {
        if (
          !intersectionObject ||
          (intersectionObject.object.name as TLineMeasurementElements) !==
          'MEASUREMENT_POINT'
        ) {
          tempSprite.visible = false;
          return;
        }
        tempSprite.visible = true;
        tempSprite.position.copy(intersectionObject.object.position);
      });
    const isMobile = this.isTouchDevice();
    return iif(
      () => isMobile,
      this._onMatterportHold$(),
      this._onMatterportClick$()
    ).pipe(
      filter((intersects) => !!intersects && intersects.length > 0),
      map((intersects) => {
        if (!intersects || !intersects.length) return;
        const intersect = intersects.find(
          (object) =>
            (object.object.name as TLineMeasurementElements) ===
            'MEASUREMENT_POINT'
        );
        if (intersect) {
          const areaComponentId = intersect.object.parent?.name;
          if (!areaComponentId) return;
          const component = this._measurementMap.get(areaComponentId)?.comp;

          if (!component) return;
          if (component.selected === false) {
            component.selected = true;
            return;
          }
          component.removePoint(intersect.object.userData['id']);
          return intersect.object.userData['id'];
        }
      }),
      takeUntil(this._removeListeners)
    );
  }
  set segmentsVisibility(visible: boolean) {
    const selected = this.selectedComponent;
    if (!selected) return;
    selected.comp.segmentsVisibility = visible;
  }
  isTouchDevice() {
    return (
      ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        ('msMaxTouchPoints' in navigator &&
          ((navigator as any).msMaxTouchPoints as number) > 0)) &&
      !window.matchMedia('(pointer:fine)').matches
    );
  }
  private _areaCreationPointListener(): Observable<Pointer | undefined> {
    const component = this.selectedComponent;
    if (!component) return of(undefined);

    const tempSprite = component.comp.createTemporaryPoint('CREATION_POINT');
    if (component.comp.mode !== 'create') {
      throw Error('You cannot add after creation end');
    }
    this._matterportManager.events.pointer$
      .pipe(
        takeUntil(this._removeListeners),
        finalize(() => {
          component.comp.removeTemporaryPoint();
        })
      )
      .subscribe((intersectionObject) => {
        const { position } = intersectionObject;
        tempSprite.position.set(position.x, position.y, position.z);
      });
    const isMobile = this.isTouchDevice();

    return iif(
      () => isMobile,
      mobileTouch$(this.proggress, this.renderer.domElement),
      this._matterportManager.events.matterportClick$
    ).pipe(
      switchMap(() => this._matterportManager.events.pointer$.pipe(take(1))),
      first(),
      takeUntil(this._removeListeners),
      tap((pointer) => {
        component.comp.addPoint(pointer.position, pointer.normal);
      })
    );
  }
  private _addPointListener(): Observable<string | undefined> {
    const component = this.selectedComponent;
    if (!component) return of(undefined);
    component.comp.mode = 'update';
    const tempSprite = component.comp.createTemporaryPoint('ADD_POINT');
    component.comp.disableLayers = {
      lines: false,
      mesh: true,
      points: true,
    };
    this._matterportManager.events.pointer$
      .pipe(
        map((pointer: Pointer) => {
          const intersect = this._getPointerIntersection(pointer);
          return intersect?.filter((object) => object.object.visible);
        }),
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            tempSprite.visible = false;
          } else {
            tempSprite.visible = true;
          }
        }),
        filter((intersection) => !!intersection && intersection.length > 0),
        map((intersects) => {
          if (!intersects) return;
          return intersects[0];
        }),
        takeUntil(this._removeListeners),
        finalize(() => {
          component.comp.removeTemporaryPoint();
          component.comp.disableLayers = {
            lines: false,
            mesh: true,
            points: false,
          };
        })
      )
      .subscribe((intersectionObject) => {
        if (!intersectionObject) return;
        const position = intersectionObject.point.add(
          new Vector3(
            intersectionObject.normal?.x || 0,
            intersectionObject.normal?.y || 0,
            intersectionObject.normal?.z || 0
          ).multiply(new Vector3(0.03, 0.03, 0.03))
        );
        const { x, y, z } = component.comp.getClosestPoint(
          intersectionObject.object.userData['id'],
          position
        );
        tempSprite.position.set(x, y, z);
      });
    const isMobile = this.isTouchDevice();
    return iif(
      () => isMobile,
      this._onMatterportHold$(),
      this._onMatterportClick$()
    ).pipe(
      filter((intersects) => !!intersects && intersects.length > 0),
      map((intersects) => {
        if (!intersects || !intersects.length) return;
        const intersect = intersects.find(
          (object) =>
            (object.object.name as TLineMeasurementElements) ===
            'MEASUREMENT_LINE'
        );
        if (intersect) {
          const areaComponentId = intersect.object.parent?.name;
          if (!areaComponentId) return;
          const component = this._measurementMap.get(areaComponentId)?.comp;
          if (!component) return;
          if (component.selected === false) {
            component.selected = true;
            return;
          }

          return component.addClosestPoint(
            intersect.object.userData['id'],
            intersect.point,
            intersect.normal || new Vector3()
          );
        }
        return undefined;
      }),
      takeUntil(this._removeListeners)
    );
  }
  removeListener() {
    this._removeListeners.next();
    this._listenersEnabled = false;
    setTimeout(() => {
      this._matterportManager.position.setSweepsActive(true);
    }, 100);
  }
  hideMeasurements(omitIds: string[] = []) {
    this._hiddenAreasOmit = omitIds;
    omitIds.forEach((omit) => {
      this._measurementMap.get(omit)?.comp.show();
    });
    this._measurementMap.forEach(
      (component: LineMeasurementComponent) =>
        !omitIds.includes(component.comp.id) && component.comp.hide()
    );
  }
  showMeasurements(omitIds: string[] = []) {
    this._hiddenAreasOmit = undefined;
    this._measurementMap.forEach(
      (component: LineMeasurementComponent) =>
        !omitIds.includes(component.comp.id) && component.comp.show()
    );
  }
  deleteAllMeasurements() {
    Array.from(this._measurementMap.keys()).forEach((id) =>
      this.deleteMeasurement(id)
    );
  }

  undo() {
    this._transformControls && this._transformControls.detach();
    const component = this.selectedComponent;
    component && (component.comp.collider = false);
    component?.comp.undo();
  }
  redo() {
    this._transformControls && this._transformControls.detach();
    const component = this.selectedComponent;
    component && (component.comp.collider = false);
    component?.comp.redo();
  }

  protected async _init() {
    this._measurementMap.mapChange$
      .pipe(
        switchMap((entries) => {
          const selectedId = this._selectedComponentId.getValue();
          const component = selectedId && this._measurementMap.get(selectedId);
          component && (component.comp.selected = true);
          return merge(
            ...Array.from(entries.values()).map((component) => {
              if (
                this.hiddenMeasurementsOmit &&
                !this.hiddenMeasurementsOmit.includes(component.comp.id) &&
                selectedId !== component.comp.id
              ) {
                component.comp.hide();
              }
              return component.comp.selectionChange$;
            })
          ).pipe(startWith(selectedId));
        }),
        takeUntil(this._destroy)
      )
      .subscribe((selectedComponentId: string | undefined) => {
        this._selectedComponentId.next(selectedComponentId);

        this._measurementMap.forEach((component) => {
          if (selectedComponentId !== component.comp.id)
            component.comp.selected = false;
        });
      });
    this._component = await firstValueFrom(
      this.registerComponents$(
        lineMeasurementToolType,
        makeLineMeasurementToolComponentRenderer
      )
    ).catch(() => null);

    if (!this._component) return;
    this._componentRegistered.next(true);
    /////////////test
    // const transformedPositions: Vertice[] = mockData.vertices.map(
    //   ({ x, y, z, index }) => {
    //     const calcPosition = this.transformConverter.to3dPosition(
    //       new Vector(x, y, z)
    //     );
    //     return {
    //       x: calcPosition.x,
    //       y: calcPosition.y,
    //       z: calcPosition.z,
    //       index,
    //     };
    //   }
    // );
    // const transformedDate: TMeasurementMesh = {
    //   ...mockData,
    //   vertices: transformedPositions,
    // };
    // this.addArea(transformedDate);
    /////////////////////////////
    this._onMatterportClickObserver();
  }

  override ngOnDestroy(): void {
    this._component?.dispose();

    super.ngOnDestroy();
  }

  set selectedMeasurement(id: TMeasurementMesh['id'] | undefined) {
    if (!id) {
      const selectedId = this._selectedComponentId.getValue();
      if (!selectedId) return;
      const selectedComponent = this._measurementMap.get(selectedId);
      selectedComponent && (selectedComponent.comp.selected = false);
      return;
    }
    const component = this._measurementMap.get(id);
    if (component) {
      component.comp.selected = true;
    } else {
      this._selectedComponentId.next(id);
    }
  }
  get selectedMeasurement(): TMeasurementMesh['id'] | undefined {
    const id = this._selectedComponentId.getValue();
    return id;
  }
  editSelectedMeasurement() {
    const component = this.selectedComponent;
    if (!component) return;
    component && (component.comp.mode = 'update');
  }
  deleteMeasurement(id: TMeasurementMesh['id']) {
    const component = this._measurementMap.get(id);
    if (component) {
      component.node.stop();
      component.object.stop();
      component.comp.removeLabels();
      this._measurementMap.delete(id);
    }
  }
  deleteSelectedMeasurement() {
    const selectedId = this._selectedComponentId.getValue();
    selectedId && this.deleteMeasurement(selectedId);
    this._selectedComponentId.next(undefined);
  }
  updateMeasurementColor = (id: string, color: string | undefined) => {
    const component = this._measurementMap.get(id);
    if (component) {
      component.comp.inputs && (component.comp.inputs['color'] = color);
    }
  };

  updateSelectedMeasurementColor(color: string | undefined) {
    const component = this.selectedComponent;
    if (component) {
      component.comp.inputs && (component.comp.inputs['color'] = color);
    }
  }
  createMeasurement(): string {
    const area: TMeasurementMesh = {
      id: randomUUID(),
      points: [],
      color: '#4374E4',
    };

    firstValueFrom(this._createMeasurementNode$(area));
    this.selectedMeasurement = area.id;
    return area.id;
  }
  addMeasurement(data: TMeasurementMesh): string {
    const transformedPointsPositions = data.points.map(({ x, y, z }) => {
      const calcPosition = this.transformConverter.toMatterportPosition(
        new Vector3(x, y, z)
      );
      return {
        x: calcPosition.x,
        y: calcPosition.y,
        z: calcPosition.z,
      };
    });
    const transformedDate: TMeasurementMesh = {
      ...data,
      points: transformedPointsPositions,
    };
    firstValueFrom(this._createMeasurementNode$(transformedDate));
    return data.id;
  }
  private _onMatterportClick$() {
    return fromEvent(this.renderer.domElement, 'pointerdown').pipe(
      switchMap(() => this._matterportManager.events.pointer$.pipe(take(1))),
      map((pointer) => {
        const intersect = this._getPointerIntersection(pointer);
        return intersect?.filter((object) => object.object.visible);
      })
    );
  }
  private _onMatterportHold$() {
    return mobileTouch$(this.proggress, this.renderer.domElement).pipe(
      switchMap(() => this._matterportManager.events.pointer$.pipe(take(1))),
      map((pointer: Pointer) => {
        const intersect = this._getPointerIntersection(pointer);
        return intersect?.filter((object) => object.object.visible);
      })
    );
  }
  private _getPointerIntersection(pointer: Pointer) {
    if (!pointer || !this._raycasterHelper) return;
    this._raycasterHelper.selectedLayers = [21];
    return this._raycasterHelper.getIntersectObjectsWithPoint(pointer.position);
  }

  private _onMatterportClickObserver() {
    this._onMatterportClick$()
      .pipe(
        filter(() => {
          const component = this.selectedComponent;
          return (
            this._listenersEnabled === false &&
            (!component || component.comp.mode !== 'create')
          );
        }),
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            this._transformControls && this._transformControls.detach();
            const component = this.selectedComponent;
            component && (component.comp.collider = false);
            return;
          }
          const [intersect] = intersects.filter((object) => {
            return object.object.parent?.visible;
          });

          if (intersect) {
            const areaComponentId = intersect.object.parent?.name;
            if (!areaComponentId) return;
            const component = this._measurementMap.get(areaComponentId)?.comp;
            if (!component) return;
            if (component.selected === false) {
              component.selected = true;
              return;
            }
            if (!this._transformControls) {
              this._transformControls = this._transformControl;
              component.context.scene.add(this._transformControls);
            }
            this._matTransformControl &&
              this._matTransformControl.control.inputs &&
              (this._matTransformControl.control.inputs['selection'] =
                this._measurementMap.get(areaComponentId)?.node);
            this._transformControls.detach();

            component.onComponentClick(this._transformControls, intersect);
          } else {
            this._transformControls && this._transformControls.detach();
            const component = this.selectedComponent;
            component && (component.comp.collider = false);
          }
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  private _createMeasurementNode$(data: TMeasurementMesh) {
    return combineLatest([this.isOpen, this._componentRegistered]).pipe(
      filter(([isOpen, isRegistered]) => isOpen && isRegistered),
      first(),
      switchMap(() =>
        from(this.sdk.Scene.createObjects(1)).pipe(
          map(([object]: MpSdk.Scene.IObject[]) => {
            const node = object.addNode();
            const comp = node.addComponent(
              lineMeasurementToolType,
              {
                ...data,
                unit: this._sizeUnit,
              } as TMeasurementMeshInputs,
              data.id
            ) as MpSdk.Scene.IComponent &
              TMeasurementMeshInputs &
              IMeasurementComponent;
            comp.transform = this.transformConverter;
            node.start();
            if (!this._raycasterHelper) {
              this._raycasterHelper = new RaycasterHelper(
                comp.context.camera,
                comp.context.scene
              );
            }
            this._measurementMap.set(data.id, { object, node, comp });
            return { object, node, comp };
          }),
          take(1),
          catchError((e) => {
            console.log(e);
            return of(null);
          })
        )
      )
    );
  }

  private get _transformControl() {
    if (this._matTransformControl) {
      return this._matTransformControl.transformControls;
    }
    if (!this.selectedComponent) return;
    const node = this.selectedComponent.object.addNode();
    const control = node.addComponent('mp.transformControls');
    if (!control.inputs) return;
    control.inputs['selection'] = this.selectedComponent.node;
    control.inputs['mode'] = 'translate';
    node.start();
    const transformControls = (control as any)['transformControls'];
    transformControls.layers.set(21);
    transformControls.traverse((object: Object3D<Event>) => {
      ['X', 'Y', 'Z'].includes(object.name) && object.layers.enable(21);
    });

    this._matTransformControl = {
      node,
      transformControls,
      control,
    };
    return transformControls;
  }
}
