import { EventEmitter } from '@angular/core';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import { TransformConverter } from '@simlab/transform';
import { BehaviorSubject, Observable } from 'rxjs';
import { Vector3 } from 'three';
import {
  TransformControls as ThreeTransformControls,
  TransformControls,
} from 'three/examples/jsm/controls/TransformControls';
import { AreaMeshComponent } from '../components/area-mesh-component';
import { Units } from './units.type';

export type TMeasurementElements =
  | 'AREA_SURFACE_SIZE'
  | 'AREA_BACKGROUND'
  | 'AREA_POINT'
  | 'AREA_LINE';
export type TAreaMesh = {
  id: string;
  color?: string;
  vertices: Vertice[];
  triangles: MeshTriangle[];
  surfaceSize: number;
};
export type TAreaMeshInputs = TAreaMesh & {
  surfaceSizeUnit: Units | undefined;
};
export type MeshTriangle = {
  index: number;
  firstVertice: number;
  secondVertice: number;
  thirdVertice: number;
};
export type Vertice = {
  index: number;
  x: number;
  y: number;
  z: number;
};

export type IndexedVertice = Vertice & { id: string };
export type TUserData = Pick<IndexedVertice, 'id'>;
export type MeasurementMode = 'read' | 'update' | 'create' | undefined;
export type MeasurementMeshSource =
  | 'addPoint'
  | 'removePoint'
  | 'cancelAllChanges'
  | 'color'
  | 'undo'
  | 'redo'
  | 'created'
  | 'color'
  | 'pointPosition';
export class TMeasurementMeshChange<T> {
  constructor(
    public changes: Required<T>,
    public source: MeasurementMeshSource
  ) { }
}
export interface IFeaturesAreaMeasurement {
  get areaMesh(): IMeasurementTool<AreaMeshComponent, TAreaMesh>;
}
export interface IMeasurementComponent {
  id: string;
  mode$: BehaviorSubject<MeasurementMode>;
  mode: MeasurementMode;
  selected: boolean;
  selectionChange$: Observable<string | undefined>;
  transform: TransformConverter;
  measurementChange$: EventEmitter<TMeasurementMeshChange<TAreaMesh>>;
  color: string;
  needUpdate: boolean;
  recalculateTriangles: () => void;
  onComponentClick: (
    transformControlsRef: ThreeTransformControls,
    intersect: THREE.Intersection<THREE.Object3D<THREE.Event>>
  ) => boolean;
  get canUndo(): boolean;
  get canRedo(): boolean;
  set collider(colision: boolean);
  cancelAllChanges: () => void;
  undo: () => void;
  redo: () => void;
  hide: () => void;
  show: () => void;
  finish: () => void;
  createTemporaryPoint: (action: TMeasurementListenersAction) => THREE.Sprite;
  removeTemporaryPoint: () => void;
  surfaceSizeUnit: TAreaMeshInputs['surfaceSizeUnit'];
  addClosestPoint: (
    lineId: string,
    point: THREE.Vector3,
    normal: THREE.Vector3
  ) => string | undefined;
  removePoint: (objectId: string) => void;
  set disableLayers(layers: { points: boolean; lines: boolean; mesh: boolean });
  getClosestPoint: (
    lineId: string,
    position: Vector3
  ) => { x: number; y: number; z: number };
  addPoint: (
    point: THREE.Vector3,
    normal: THREE.Vector3,
    nextIndex?: number
  ) => THREE.Sprite | undefined;
  removeLabels: () => void;

  set segmentsVisibility(visible: boolean);
}
export type TAreaMeshUpdate = Pick<TAreaMesh, 'id' | 'color'>;

export const MESH_LISTENERS_ACTION = [
  'ADD_POINT',
  'REMOVE_POINT',
  'CREATION_POINT',
] as const;

export type TMeasurementListenersAction = typeof MESH_LISTENERS_ACTION[number];
export const TEMPORARY_POINT_ICONS: Record<
  TMeasurementListenersAction,
  { iconUrl: string; background?: string }
> = {
  ADD_POINT: {
    iconUrl: 'assets/icons/k_add.svg',
    background: '#4374E4',
  },
  REMOVE_POINT: {
    iconUrl: 'assets/icons/k_remove.svg',
    background: '#C42934',
  },
  CREATION_POINT: {
    iconUrl: 'assets/icons/k_add.svg',
    background: '#4374E4',
  },
};
export const TRANSFORM_CONTROLS_EVENTS = {
  /**Fires if any type of change (object or property change) is performed. Property changes are separate events you can add event listeners to. The event type is "propertyname-changed". */
  change: 'change',

  /**Fires if a pointer (mouse/touch) becomes active. */
  mouseDown: 'mouseDown',

  /**Fires if a pointer (mouse/touch) is no longer active. */
  mouseUp: 'mouseUp',

  /**Fires if the controlled 3D object is changed.] */
  objectChange: 'objectChange',
} as const;
export type TransformControlsEvents = keyof typeof TRANSFORM_CONTROLS_EVENTS;
export interface IMeasurementTool<T, W extends { id: string; color?: string }>
  extends Pick<
    IMeasurementComponent,
    'undo' | 'redo' | 'finish' | 'canRedo' | 'canUndo' | 'segmentsVisibility'
  > {
  readonly selectedMeasurement$: Observable<string | undefined>;
  readonly transformationEvent$: Observable<
    TransformControlsEvents | undefined
  >;
  readonly selectedMeasurementMode$: Observable<MeasurementMode>;
  readonly selectedMeasurementChange$: Observable<TMeasurementMeshChange<W> | undefined>;
  addMeasurement: (area: W) => string;
  createMeasurement: () => string;
  cancelMeasurementCreation: () => void;
  deleteMeasurement: (areaId: string) => void;
  deleteSelectedMeasurement: () => void;
  editSelectedMeasurement: () => void;
  isTouchDevice: () => boolean;
  updateMeasurementColor: (id: W['id'], color: W['color']) => void;
  updateSelectedMeasurementColor: (color: W['color']) => void;
  /**
   * @description add listener on measurement mesh click
   * @param listenerActionType - set listener action type
   */
  addListener: (
    listenerActionType: TMeasurementListenersAction
  ) => Observable<string | undefined>;
  /**
   * @description remove all listeners
   */
  removeListener: () => void;
  hideMeasurements: (omit?: string[]) => void;
  showMeasurements: (omit?: string[]) => void;

  deleteAllMeasurements: () => void;
  set sizeUnit(unit: Units);
  set selectedMeasurementMode(mode: MeasurementMode);
  get selectedMeasurementMode(): MeasurementMode;
  set selectedMeasurement(measurementId: string | undefined);
  get selectedMeasurement(): string | undefined;
  get selectedComponent(): T | undefined;
  get transformControls(): TransformControls | undefined;
  get hiddenMeasurementsOmit(): undefined | string[];
  get listenersEnabled(): boolean;
  get count(): number;

  dispose: () => void;
}
