import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileLoaderCacheService } from '@simlab/simlab-facility-management/common';
import {
  ComponentConfiguration,
  ICustomComponent,
  PlaneConfiguration,
} from '@simlab/simlab-facility-management/scene-object';
import { Observable, firstValueFrom, from, map } from 'rxjs';
import { Vector3 } from 'three';
import { TextureResolution } from '../types/blueprint.interface';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
const MAX_PHOTO_PLANE_SIDE_SIZE = 4;
const MAX_SVG_PLANE_SIDE_SIZE = 20;

export class _BlueprintBase<T, W extends ICustomComponent<any>> {
  constructor(
    private readonly _componentLoader: W,
    private readonly _httpClient: HttpClient,
    private readonly _fileLoaderCacheService: FileLoaderCacheService
  ) { }
  async addBlueprint(
    component: ComponentConfiguration
  ): Promise<T | undefined> {
    if (
      !component.objects.some((config) => config instanceof PlaneConfiguration)
    )
      throw new Error(
        'Cannot find any component configuration satisfied PlaneConfiguration'
      );
    const texturePath = (component.objects[0] as PlaneConfiguration).config
      .texture;
    const isSVG = texturePath.includes('.svg');
    const createComponent = (component: ComponentConfiguration) =>
      firstValueFrom(
        this._componentLoader.addComponentWithOffset$(
          {
            ...component,
            autoScale: false,
            isCollider: false,
            dollhouseView: false,
            userData: {
              type: 'blueprint',
            },
            lookAt: false,
          },
          true
        )
      );

    const file = await firstValueFrom(
      this._fileLoaderCacheService.getModel$(texturePath)
    );

    let componentCopy = {
      ...component,
    };
    if (file)
      (component.objects[0] as PlaneConfiguration).config.texture = file;
    if (isSVG) {
      return this._getSvgResolution(texturePath).then((prop) => {
        componentCopy = {
          ...componentCopy,
          scale: this._getSvgPlaneScale(prop, component.scale),
        };
        return createComponent(componentCopy);
      });
    } else {
      return this._getPhotoResolution(texturePath).then((prop) => {
        componentCopy = {
          ...componentCopy,
          scale: this._getPhotoPlaneScale(prop, component.scale),
        };
        return createComponent(componentCopy);
      });
    }
  }
  deleteBlueprint(blueprintId: string) {
    return this._componentLoader.deleteNote(blueprintId);
  }

  private _getPhotoPlaneScale(
    resolution: TextureResolution,
    baseScale: Vector3
  ): Vector3 {
    let { width, height } = resolution;
    const biggerSide = Math.max(width, height);
    const divideFactor = biggerSide / MAX_PHOTO_PLANE_SIDE_SIZE;
    width /= divideFactor;
    height /= divideFactor;
    width *= MAX_PHOTO_PLANE_SIDE_SIZE;
    height *= MAX_PHOTO_PLANE_SIDE_SIZE;
    return new Vector3(width * baseScale.x, height * baseScale.y, 0.001);
  }
  private _getSvgPlaneScale(
    resolution: TextureResolution,
    baseScale: Vector3
  ): Vector3 {
    let { width, height } = resolution;
    width /= MAX_SVG_PLANE_SIDE_SIZE;
    height /= MAX_SVG_PLANE_SIDE_SIZE;
    return new Vector3(width * baseScale.x, height * baseScale.y, 1.001);
  }
  private _getSvgResolution(svgPath: string): Promise<TextureResolution> {
    return firstValueFrom(this._getSvgResolution$(svgPath));
  }

  private _getSvgResolution$(svgPath: string): Observable<TextureResolution> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return this._httpClient
      .get(svgPath, {
        headers,
        responseType: 'text',
      })
      .pipe(
        map((svg: string) => {
          const div: HTMLDivElement = document.createElement('div');
          div.innerHTML = svg;
          const svgElement: SVGSVGElement | null = div.querySelector('svg');
          if (svgElement) {
            return {
              width: svgElement.viewBox.baseVal.width,
              height: svgElement.viewBox.baseVal.height,
            } as TextureResolution;
          }
          return {
            width: 0,
            height: 0,
          } as TextureResolution;
        })
      );
  }

  private _getPhotoResolution$(
    photoPath: string
  ): Observable<TextureResolution> {
    return from(this._getPhotoResolution(photoPath));
  }

  private _getPhotoResolution(photoPath: string): Promise<TextureResolution> {
    return new Promise<TextureResolution>((resolve) => {
      const photo = new Image();
      photo.onload = (e: Event) => {
        console.log(e);
        const { width, height }: HTMLImageElement =
          e.target as HTMLImageElement;
        resolve({ width, height });
      };
      console.log('photo.src =', photoPath);
      photo.src = photoPath;
    });
  }
}
