import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_STATE_REDUCER, State } from '@simOn/utils';

import { ApartmentInterface } from '@simOn/space/information/models';
import {
  ClearApartmentState,
  DeleteApartment,
  DeleteApartmentFailure,
  DeleteApartmentSuccess,
  GetApartment,
  GetApartmentFailure,
  GetApartments,
  GetApartmentsFailure,
  GetApartmentShort,
  GetApartmentShortFailure,
  GetApartmentShortSuccess,
  GetApartmentsSuccess,
  GetApartmentSuccess,
  LeaveApartment,
  RemoveApartment,
  SetSelectedId,
  SetSelectedSpaceModelId,
  UpdateApartment,
  UpdateApartmentFailure,
  UpdateApartmentProviderStatus,
  UpdateApartmentSuccess,
  UpdateModelSweeps,
  UpdateModelSweepsFailure,
  UpdateModelSweepsSuccess,
  UploadFileWithProgress,
  UploadFileWithProgressFailure,
  UploadFileWithProgressSuccess
} from './space.actions';

export const SPACE_FEATURE_KEY = 'spaces';
export type TSpaceState = State<ApartmentInterface> & {
  selectedSpaceModelId: string | undefined;
};
export const adapter: EntityAdapter<ApartmentInterface> = createEntityAdapter<ApartmentInterface>();

export const initialState: TSpaceState = adapter.getInitialState({
  selectedId: undefined,
  selectedSpaceModelId: undefined,
  isLoaded: false,
  isLoading: false
});

export const reducer = createReducer(
  initialState,

  on(GetApartment, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(GetApartmentSuccess, (state, action) => {
    return adapter.upsertOne(action.apartment, { ...state, isLoading: false, isLoaded: true });
  }),

  on(GetApartmentFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(GetApartmentShort, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(GetApartmentShortSuccess, (state, action) => {
    return adapter.upsertOne(action.apartment, { ...state, isLoading: false, isLoaded: true });
  }),

  on(GetApartmentShortFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(GetApartments, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(GetApartmentsSuccess, (state, action) => {
    return adapter.upsertMany(action.apartments, { ...state, isLoading: false, isLoaded: true });
  }),

  on(GetApartmentsFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(DeleteApartment, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(DeleteApartmentSuccess, (state, action) => {
    return adapter.removeOne(action.apartment.id!, { ...state, isLoading: false, isLoaded: true });
  }),

  on(RemoveApartment, (state, action) => {
    return adapter.removeOne(action.apartmentId, { ...state, isLoading: false, isLoaded: true });
  }),

  on(DeleteApartmentFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(UpdateApartment, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(UpdateApartmentSuccess, (state, action) => {
    return adapter.updateOne(
      { id: action.apartment.id!, changes: action.apartment },
      { ...state, isLoading: false, isLoaded: true }
    );
  }),
  on(SetSelectedId, (state, action) => {
    // TODO check performance
    return {
      ...state,
      selectedId: action.selectedId
    };
  }),
  on(UpdateApartmentFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(UploadFileWithProgress, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(UploadFileWithProgressSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true
    };
  }),
  on(UploadFileWithProgressFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(UpdateModelSweeps, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(SetSelectedSpaceModelId, (state, { selectedSpaceModelId }) => {
    return {
      ...state,
      selectedSpaceModelId
    };
  }),
  on(UpdateModelSweepsSuccess, (state, action) => {
    const updatedApartment = state.entities[action.state.apartmentId];
    if (!updatedApartment?.scanModels)
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    const update: Update<ApartmentInterface> = {
      id: action.state.apartmentId,
      changes: {
        scanModels: [
          ...updatedApartment.scanModels.filter((model) => model.id !== action.state.scanModelId),
          {
            ...updatedApartment.scanModels.find((model) => model.id === action.state.scanModelId)!,
            scanPoints: action.state.sweeps
          }
        ]
      }
    };

    return adapter.updateOne(update, { ...state, isLoading: false, isLoaded: true });
  }),
  on(UpdateModelSweepsFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(LeaveApartment, (state, action) => {
    return {
      ...state,
      selectedId: undefined,
      selectedscanModelId: undefined
    };
  }),
  CLEAR_STATE_REDUCER(ClearApartmentState, initialState),
  on(UpdateApartmentProviderStatus, (state, action) => {
    const updatedApartment: Update<ApartmentInterface> = {
      id: action.apartmentId,
      changes: {
        providerConnections: state.entities[action.apartmentId]!.providerConnections!.map((provider) =>
          provider.masterDeviceId === action.masterDeviceId
            ? { ...provider, providerConnectionStatus: action.status }
            : provider
        )
      }
    };
    return adapter.updateOne(updatedApartment, { ...state, isLoading: false, isLoaded: true });
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
