import {
  BlueprintComponent,
  Inputs,
  MeshComponent,
  SpriteComponent,
  TextComponent,
  addColliderToGroup,
  mixinCustomComponent,
  removeColliderFromGroup,
} from '@simlab/simlab-facility-management/scene-object';
import type THREE from 'three';
import type {
  BufferGeometry,
  Camera,
  Group,
  Line,
  LineBasicMaterial,
  Scene,
  Vector3,
} from 'three';

const _TagComponentBase = mixinCustomComponent(class { });

export class TagComponent extends _TagComponentBase {
  override scene: Scene;
  override camera: Camera;
  override cameraContainer: Camera;
  override _group: Group = new this._THREE.Group();
  override lineBasicMaterial: typeof LineBasicMaterial =
    this._THREE.LineBasicMaterial;
  override bufferGeometry: typeof BufferGeometry = this._THREE.BufferGeometry;
  override line: typeof Line = this._THREE.Line;
  onClick?: (
    event: { type: 'onClick' } & { target: Group },
    component: this
  ) => void;
  constructor(
    private readonly _camera: Camera,
    private readonly _scene: Scene,
    private readonly _input: Inputs,
    private readonly _THREE: typeof THREE
  ) {
    super();
    this.camera = _camera;
    this.cameraContainer = _camera;
    this.scene = _scene;

    this.inputs = {
      ...this.inputs,
      ..._input,
    };
    this.inputs.up && this._group.up.copy(this.inputs.up);
    this.onInit();
  }
  override onInit(): void {
    super.onInit();
    this._group.addEventListener('onClick', this._onClick.bind(this));
    this.collision = this.inputs.isCollider;
    this._scene.add(this._group);
    this.animate();
  }
  protected animate() {
    requestAnimationFrame(() => this.animate());
    this.animationFrame();
  }

  private _onClick(event: { type: 'onClick' } & { target: Group }) {
    this.onClick && this.onClick(event, this);
  }

  set collision(collision: boolean) {
    Object.defineProperty(this._group, 'collision', {
      value: collision,
      writable: true,
    });
    this._group.traverse((object) => {
      object.layers.set(collision ? 3 : 31);
    });

    collision
      ? addColliderToGroup(this._group)
      : removeColliderFromGroup(this._group);
  }
  createChildFromInstance(instance: SpriteComponent | TextComponent | MeshComponent | BlueprintComponent) {
    this.children.push(instance)
    this.addChild(
      instance.object3D,
      instance instanceof BlueprintComponent
    );
  }
  override _lookAt(vector: Vector3): void {
    this._group.lookAt(vector.x, vector.y, vector.z);
  }
  override destroy(): void {
    this._group.removeEventListener('onClick', this._onClick);
    super.destroy();
  }
}
