import { Vector3 } from 'three';

import { ICONS, ITagNote } from '@simlab/simlab-facility-management/common';
import {
  ComponentConfiguration,
  MeshConfiguration,
  SpriteConfiguration,
  TextConfiguration,
} from '@simlab/simlab-facility-management/scene-object';
import { Observable } from 'rxjs';
import { MpSdk, Vector2 } from '../../../assets/bundle/sdk';
import { Colliders, Transition } from './enums';

export interface Pointer {
  normal: Vector3;
  position: Vector3;
  object: Colliders;
}

export interface MoveToSweepConfig {
  id: string;
  rotation: Vector2;
  translation: MpSdk.Sweep.Transition;
  /**
   * This is in ms
   * @type {number}
   */
  transitionTime: number;
}

export interface IPositionController {
  goToPositionAndLookAt$: (position: Vector3) => Observable<void>;
  moveToSweep$: (config: MoveToSweepConfig) => Observable<void>;
  moveToClosestSweep$: (config: MoveToClosestSweepConfig) => Observable<void>;
  moveToClosestSweepWithOffset$: (
    config: MoveToClosestSweepConfig
  ) => Observable<void>;
  moveTo$: (
    position: Vector3,
    normal: Vector3,
    stem: number
  ) => Observable<void>;
  moveToMattertag$: (mattertagId: string) => Observable<string>;
  setSweepsActive$: (enable: boolean) => Observable<void>;
  setSweepsActive: (enable: boolean) => Promise<void>;
}

export interface MoveToClosestSweepConfig {
  position: Vector3;
  rotation: Vector2;
  translation: Transition;
  /**
   * This is in ms
   * @type {number}
   */
  transitionTime: number;
}

export class CustomSpriteComponent implements ComponentConfiguration {
  position: Vector3;
  normal: Vector3;
  stemHeight: number;
  id: string;
  visible?: boolean | undefined;
  renderOrder?: number | undefined;
  opacity?: number | undefined;
  scale: Vector3;
  isCollider?: boolean | undefined;
  autoScale?: boolean | undefined;
  userData?: any;
  depthTest?: boolean | undefined;
  transparent?: boolean | undefined;
  lookAt?: boolean | undefined;
  objects: (SpriteConfiguration | TextConfiguration | MeshConfiguration)[];
  constructor(tagNote: ITagNote) {
    this.position = tagNote.position;
    this.normal = tagNote.normal || new Vector3(0, 0, 0);
    this.stemHeight = 0;
    this.id = tagNote.id;
    this.scale = new Vector3(0.12, 0.12, 0.12);
    this.objects = [
      new SpriteConfiguration({
        icon: ICONS[tagNote.noteType],
      }),
    ];
  }
}
