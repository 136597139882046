import { InjectionToken } from '@angular/core';
import { Euler, EulerOrder, MathUtils, Matrix4, Quaternion, Spherical, Vector3 } from 'three';
import { Stem } from './stem';
import { Transform } from './transform';
export const TRANSFORM_CONVERTER = new InjectionToken<TransformConverter>(
  'Transform Converter'
);

export function calcCartesian(lat: number, lon: number, radius: number = 1) {
  const t = Math.PI / 2 - lat
  const e = lon
  const x = Math.sin(t) * Math.cos(e)
  const y = Math.sin(t) * Math.sin(e);
  const z = Math.cos(t);

  return new Vector3(x, y, z)

}
export class TransformConverter {
  private _transform!: Transform;

  public get transform(): Transform {
    return this._transform;
  }
  public set transform(value: Transform) {
    this._transform = value;
  }
  constructor(offset: string | undefined | Transform) {
    try {
      if (typeof offset === 'object') {
        this._transform = offset;
        return;
      }
      this._transform = offset
        ? new Transform(JSON.parse(offset))
        : Transform.default;
    } catch (e) {
      //NOTE: defaults to Transform.default when offset is invalid.
      this._transform = Transform.default;
      console.error(e);
    }
  }

  /**
   * @param value Inverse vector
   * @returns inverted vector
   */
  inverse(point: Vector3): Vector3 {
    return new Vector3(1 / point.x, 1 / point.y, 1 / point.z);
  }

  /**
   * Translates 3d vector position to matterport vector position
   * @param basePosition 3d vector position
   * @returns matterport vector position
   */

  toMatterportPosition(basePosition: Vector3): Vector3 {
    const {
      position: offsetPosition,
      rotation: offsetRotation,
      scale: offsetScale,
    } = this._transform;

    //objectPosition - offsetPosition;

    const pointMoved = basePosition.clone().sub(offsetPosition);

    //Quaternion.Inverse(offsetRotation) * movedVector;
    const pointRotated = pointMoved
      .clone()
      .applyQuaternion(offsetRotation.clone().invert());

    //Vector3.Scale(rotatedVector, InverseScale(offsetScale));
    const pointScaled = pointRotated.multiply(this.inverse(offsetScale));

    //new Vector3(-scaledVector.x, scaledVector.y, scaledVector.z)
    return new Vector3(-pointScaled.x, pointScaled.y, pointScaled.z);
  }

  /**
   * Translates matterport vector position to 3d vector position
   * @param basePosition matterport vector position
   * @param offset matterport vector position
   *
   * @returns 3d vector position
   */
  to3dPosition(basePosition: Vector3, offset?: Transform): Vector3 {
    const {
      position: offsetPosition,
      rotation: offsetRotation,
      scale: offsetScale,
    } = offset ?? this._transform;

    //new Vector3(-mtpPosition.x, mtpPosition.y, mtpPosition.z)
    const mtpFixedPoint = basePosition.clone().multiply(new Vector3(-1, 1, 1));

    //Vector3.Scale(mtpFixedVector, offsetScale);
    const pointScaled = mtpFixedPoint.multiply(offsetScale);

    //offsetRotation * scaledVector;
    const pointRotated = pointScaled
      .clone()
      .applyQuaternion(
        new Quaternion(
          offsetRotation.x,
          offsetRotation.y,
          offsetRotation.z,
          offsetRotation.w
        ).clone()
      );

    //rotatedVector + offsetPosition;
    const pointMoved = pointRotated.add(offsetPosition);

    return pointMoved;
  }

  /**
   * Translates matterport vector position to 3d vector position with stem correction
   * @param basePosition matterport vector position
   * @returns 3d vector position
   */
  to3dPositionWithStem(basePosition: Vector3, stem: Stem): Vector3 {
    const positionWithStemCorrection = new Vector3()
      .copy(stem.normal)
      .multiplyScalar(0.1)
      .add(basePosition);
    // .dotProduct(Vector3.back);

    return this.to3dPosition(
      new Vector3(
        positionWithStemCorrection.x,
        positionWithStemCorrection.z,
        -positionWithStemCorrection.y
      )
    );
  }

  /**
   * Translates 3d euler angles to matterport euler angles
   * @param baseRotation 3d euler angles (2 dimensional vector)
   * @returns matterport euler angles (2 dimensional vector)
   */
  toMatterportRotation(baseRotation: Vector3): Vector3 {
    const { rotation: offsetRotation } = this._transform;
    const euler = new Euler(
      MathUtils.degToRad(baseRotation.x),
      MathUtils.degToRad(baseRotation.y),
      MathUtils.degToRad(baseRotation.z),
      'YXZ'
    );

    const rotationSummed = new Quaternion().multiplyQuaternions(
      offsetRotation.clone().invert(),
      new Quaternion().setFromEuler(euler)
    );

    const translatedQuat = new Quaternion(
      -rotationSummed.x,
      rotationSummed.y,
      rotationSummed.z,
      -rotationSummed.w
    );
    const { x, y } = new Euler().setFromQuaternion(translatedQuat, 'YZX');
    return new Vector3(
      MathUtils.radToDeg(-x),
      MathUtils.radToDeg(y > 0 ? y - Math.PI : y + Math.PI)
    );
  }

  /**
   * Translates matterport euler angles to 3d euler angles
   * @param baseRotation matterport euler angles (2 dimensional vector)
   * @returns 3d euler angles
   */
  to3dRotation(baseRotation: Vector3): Vector3 {
    const { x: xr, y: yr } = baseRotation;
    const { rotation: offsetRotation } = this._transform;
    const orgEu = new Euler(
      MathUtils.degToRad(-xr),
      MathUtils.degToRad(yr > 0 ? -(-yr + 180) : yr + 180),
      0,
      'YZX'
    );
    const rotationMatterport = new Quaternion().setFromEuler(orgEu);
    //rotationOffset * mtpQuaternion;
    rotationMatterport.x = -rotationMatterport.x;
    rotationMatterport.w = -rotationMatterport.w;
    const rotationSummed = new Quaternion().multiplyQuaternions(
      offsetRotation.clone(),
      rotationMatterport
    );

    const uEu = new Euler().setFromQuaternion(rotationSummed, 'YZX');
    return new Vector3(
      MathUtils.radToDeg(uEu.x),
      MathUtils.radToDeg(uEu.y),
      MathUtils.radToDeg(uEu.z)
    );
  }

  /**
   * Translates matterport vector rotation to 3d vector rotation
   * @param baseRotation matterport vector rotation
   * @returns 3d vector rotation
   */
  to3dObjectRotation(baseRotation: Vector3): Vector3 {
    const { rotation: offsetRotation, scale: offsetScale } = this._transform;
    const { x, y, z } = baseRotation;
    const baseRotationQuaternion = new Quaternion().setFromEuler(
      new Euler(x, -y + Math.PI, z)
    );
    const offsetRotationQuaternion = new Quaternion(
      offsetRotation.x,
      offsetRotation.y,
      offsetRotation.z,
      offsetRotation.w
    );

    const pointRotated = new Quaternion().multiplyQuaternions(
      baseRotationQuaternion,
      offsetRotationQuaternion
    );

    const euler = new Euler().setFromQuaternion(pointRotated);
    return new Vector3(euler.x, euler.y, euler.z);
  }
  /**
   * Translates 3d vector rotation to matterport vector rotation
   * @param baseRotation 3d vector rotation
   * @returns matterport vector rotation
   */
  toMatterportObjectRotation(baseRotation: Vector3): Vector3 {
    const { rotation: offsetRotation } = this._transform;
    const { x, y, z } = baseRotation;
    const baseRotationQuaternion = new Quaternion().setFromEuler(
      new Euler(x, y, z)
    );
    const offsetRotationQuaternion = new Quaternion(
      offsetRotation.x,
      offsetRotation.y,
      offsetRotation.z,
      offsetRotation.w
    ).invert();

    const rotationSummed = new Quaternion().multiplyQuaternions(
      offsetRotationQuaternion,
      baseRotationQuaternion
    );

    //sumedRotation.eulerAngles;

    const euler = new Euler().setFromQuaternion(rotationSummed);
    return new Vector3(euler.x, -euler.y - Math.PI, euler.z);
  }

  /**
   * Translates 3d vector rotation to matterport vector rotation
   * @param baseRotation 3d vector rotation
   * @returns matterport vector rotation
   */
  toMatterportObjectRotationFromQuaternion(
    baseRotation: Quaternion
  ): Quaternion {
    const { rotation: offsetRotation } = this._transform;
    const { x, y, z, w } = baseRotation;
    const baseRotationQuaternion = new Quaternion(x, y, z, w);
    const offsetRotationQuaternion = new Quaternion(
      offsetRotation.x,
      offsetRotation.y,
      offsetRotation.z,
      offsetRotation.w
    ).invert();

    const rotationSummed = new Quaternion().multiplyQuaternions(
      baseRotationQuaternion,
      offsetRotationQuaternion
    );
    return rotationSummed;
  }

  toMatterportScale(baseScale: Vector3) {
    const { scale } = this._transform;

    const { x, y, z } = {
      x: baseScale.x * (1 / scale.x),
      y: baseScale.y * (1 / scale.y),
      z: baseScale.z * (1 / scale.z),
    };

    return new Vector3(x, y, z);
  }

  get offsetPosition() {
    const { x, y, z } = this.transform.position;
    return this.flipPositionZ(new Vector3(x, y, z));
  }

  get offsetRotationQuaternion() {
    return new Quaternion().setFromEuler(this.offsetRotation);
  }

  get offsetRotation() {
    const { x, y, z, w } = this.transform.rotation;
    const q = new Quaternion(x, y, z, w);

    return this.unityQuaternionToThreejsRotation(q);
  }
  flipEulerXAndY(v: Euler) {
    v.y *= -1;
    v.x *= -1;
  }

  unityEulerAngleToThreejsRotation(e: Vector3) {
    const euler = new Euler(
      MathUtils.degToRad(e.x),
      MathUtils.degToRad(e.y),
      MathUtils.degToRad(e.z),
      'YXZ'
    );
    this.flipEulerXAndY(euler);
    return euler;
  }

  threejsRotationToUnityEuler(e: Euler, order: EulerOrder = 'XYZ') {
    const quaternion = new Quaternion().setFromEuler(e.clone());
    const euler = new Euler().setFromQuaternion(quaternion, order);
    this.flipEulerXAndY(euler);
    return euler;
  }

  threejsRotationToUnityEulerAngle(e: Euler) {
    const euler = this.threejsRotationToUnityEuler(e, 'YXZ');

    return new Vector3(
      MathUtils.radToDeg(euler.x),
      MathUtils.radToDeg(euler.y),
      MathUtils.radToDeg(euler.z)
    );
  }
  threejsQuaternionToUnityEulerAngle(q: Quaternion) {
    const euler = new Euler().setFromQuaternion(
      new Quaternion(q.z, q.w, q.x, q.y),
      'YZX'
    );
    return new Vector3(
      MathUtils.radToDeg(euler.x),
      MathUtils.radToDeg(euler.y),
      MathUtils.radToDeg(euler.z)
    );
  }
  unityQuaternionToThreejsRotation(q: Quaternion) {
    const v = new Euler();
    v.order = 'YXZ';

    v.setFromQuaternion(q);
    this.flipEulerXAndY(v);

    return v;
  }

  flipPositionZ(p: Vector3) {
    /**
     * NOTE: need to invert z axis, because threejs coordinate system is different
     * Unity initial loading position is x-right, y-up, and z-backward
     */
    return new Vector3(p.x, p.y, -p.z);
  }


  sphericalToEuler(lat: number, lon: number, position: Vector3, up: Vector3 = new Vector3(0, 0, 1)) {

    return new Euler().setFromRotationMatrix(this._rotationMatrix(lat, lon, position, up))
  }
  sphericalToQuatetnion(lat: number, lon: number, position: Vector3, up: Vector3 = new Vector3(0, 0, 1)) {
    return new Quaternion().setFromRotationMatrix(this._rotationMatrix(lat, lon, position, up))
  }

  cartesianToSpherical(quaternion: Quaternion) {
    const lookAtVector = new Vector3(0, 0, -1);
    lookAtVector.applyQuaternion(quaternion);
    const sphere = new Spherical().setFromVector3(
      new Vector3(lookAtVector.z, lookAtVector.y, lookAtVector.x)
    );
    const phi = sphere.phi;
    const theta = sphere.theta;
    return { lon: -(phi - 0.5 * Math.PI), lat: theta }
  }

  private _rotationMatrix(lat: number, lon: number, position: Vector3, up: Vector3 = new Vector3(0, 0, 1)) {
    const vector = calcCartesian(lat, lon)
    const rotationMatrix = new Matrix4()
    const direction = new Vector3().copy(position).add(vector)
    return rotationMatrix.lookAt(position, direction, up)

  }


}
