import { filter, map, Observable, Subject } from 'rxjs';
export class EventDispatcher {
  private _dispatcher = new Subject<{ eventName: string; value: unknown }>();

  on$<T>(eventName: string): Observable<T> {
    return this._dispatcher.pipe(
      filter((value) => value.eventName === eventName),
      map(({ value }) => value as T)
    );
  }
  dispatchEvent = <T>(eventName: string, value: T) => {
    if (this._dispatcher.observed) {
      this._dispatcher.next({ eventName, value });
    } else {
      const interval = setInterval(() => {
        if (this._dispatcher.observed) {
          this._dispatcher.next({ eventName, value });
          clearInterval(interval);
        }
      }, 500);
    }
  };
}
