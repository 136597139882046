import { Injectable, OnDestroy } from '@angular/core';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import {
  BlueprintComponent,
  ChildConfiguration,
  ClickEventEmitter,
  ComponentConfiguration,
  ComponentTypes,
  ICustomComponent,
  Inputs,
  RaycasterHelper,
  UComponentType,
} from '@simlab/simlab-facility-management/scene-object';
import { moveThreejsPositionByOffset } from '@simlab/simlab-facility-management/sub-features/tag';
import { IDisposable, ISubscription, MpSdk, Size } from 'mpSdk';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs';
import { Vector3 } from 'three';
import { MatterportServiceBase } from '../base/matterport-base';
import {
  CustomMatteportComponent,
  makeCustomComponentRenderer,
  rootNoteType,
} from '../base/matterport-component-base';
import { ComponentInteractionType } from '../base/scene-component-base';
import { Pointer } from '../models/dto';
import {
  MatterportComponent,
  MatterportTagComponentInterface,
  TagComponentClickData,
} from '../models/matterport-tag-component.type';
import { ViewMode } from '../models/mattertags.interface';
import { MatterportEventsService } from './matterport-events.service';
import { MatterportManagerService } from './matterport-manager.service';

@Injectable()
export class MatterportCustomComponentService
  extends MatterportServiceBase
  implements OnDestroy, ICustomComponent<MatterportComponent> {
  private readonly _componentClicked: Subject<ClickEventEmitter> =
    new Subject<ClickEventEmitter>();
  private readonly _components: Record<
    string,
    MatterportTagComponentInterface<CustomMatteportComponent>
  > = {};
  private readonly _selectedComponent: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  readonly selectedNote$: Observable<string | undefined> =
    this._selectedComponent.asObservable();
  readonly componentClicked$: Observable<ClickEventEmitter> =
    this._componentClicked.asObservable().pipe(throttleTime(100));
  private _cameraPoseSubscription!: ISubscription;
  private _raycasterHelper!: RaycasterHelper;
  private _cameraPoseChange: Subject<void> = new Subject<void>();
  private _componentRegistered: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _disableClickEvents = false;
  private _component!: IDisposable | null | undefined;

  constructor(
    private readonly matterportManager: MatterportManagerService,
    private readonly matterportEvents: MatterportEventsService
  ) {
    super(matterportManager);
  }

  protected async _init(): Promise<void> {
    this._component = await firstValueFrom(
      this.registerComponents$(rootNoteType, makeCustomComponentRenderer)
    ).catch(() => null);
    this._matterportViewStateObserver();
    if (!this._component) return;
    this._componentRegistered.next(true);
    this._cameraPoseObserver();
    this._selectedNoteVisibilityObserver();
    this._onMatterportClickObserver();
  }
  private _onMatterportClickObserver() {
    this.matterportEvents.matterportClick$
      .pipe(
        switchMap((pointer: Pointer) => {
          const windowSize: Size = {
            h: window.innerHeight,
            w: window.innerWidth,
          };
          return this.matterportManager.worldToScreen(
            pointer.position,
            windowSize
          );
        }),
        tap((screenPos) => {
          if (
            screenPos &&
            this._raycasterHelper &&
            this._selectedComponent.getValue()
          ) {
            const noteId = this._selectedComponent.getValue() as string;
            if (!this._components[noteId]) return;

            const cameraPosition =
              this._components[noteId].comp.cameraContainer.position;
            const notePosition = this._components[noteId].comp.position;

            const intersect = this._raycasterHelper.getIntersectObjects(
              cameraPosition,
              notePosition,
              {
                x: (Math.abs(screenPos.x) / window.innerWidth) * 2 - 1,
                y: -(Math.abs(screenPos.y) / window.innerHeight) * 2 + 1,
              } as THREE.Vector2
            );
            if (
              this._disableClickEvents === false &&
              intersect.find((obj) => obj.object.name === noteId)
            ) {
              this._componentClicked.next({
                id: noteId,
                userData: this._components[noteId].comp.inputs.userData,
              });
              // this._selectedComponent.next(noteId);
            }
          }
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  setOpacity(noteId: string, opacity: number) {
    if (!this._components[noteId]) return;
    this._components[noteId].comp.opacity = opacity;
  }
  set selectedNote(value: string | undefined) {
    const noteId = this._selectedComponent.getValue();
    if (noteId) this.setOpacity(noteId, 1);
    this._selectedComponent.next(value);
  }

  override ngOnDestroy(): void {
    this.sdk?.off(this.sdk.Mode.Event.CHANGE_END, this._viewModeChangeCallback);
    this._cameraPoseSubscription?.cancel();
    this._raycasterHelper?.destroy();
    this.clearComponents();
    this._component?.dispose();
    super.ngOnDestroy();
  }

  set disableClickEvents(disableClickEvents: boolean) {
    this._disableClickEvents = disableClickEvents;
  }

  getComponentById(
    noteId: string
  ): MatterportTagComponentInterface<CustomMatteportComponent> | undefined {
    return this._components[noteId];
  }
  addComponentWithOffset$(
    component: ComponentConfiguration,
    isBlueprint = false
  ): Observable<MatterportComponent> {
    const translatedNode: ComponentConfiguration = {
      ...component,
      ...moveThreejsPositionByOffset(component, this.transformConverter, isBlueprint),
    };
    return this.addComponent$(translatedNode);
  }


  clearAllNotes() {
    for (const noteId in this._components) {
      if (!this.isNote(noteId)) return;
      this.deleteNote(noteId);
    }
  }

  isNote(noteId: string) {
    const type = this._components[noteId].comp.inputs.userData?.type;
    return !(type && ComponentTypes.includes(type as UComponentType));
  }
  clearAllNotes$(): Observable<void> {
    const notesId: string[] = Object.keys(this._components);
    if (notesId.length === 0) return of(undefined);
    return of(notesId).pipe(
      mergeMap((notesId: string[]) => {
        return forkJoin(
          notesId.map((noteId: string) => {
            if (this.isNote(noteId)) {
              return this.deleteNote$(noteId);
            } else {
              return of(undefined);
            }
          })
        );
      }),
      map(() => undefined)
    );
  }

  clearComponents() {
    for (const noteId in this._components) {
      this.deleteNote(noteId);
    }
  }
  addComponent$(
    component: ComponentConfiguration
  ): Observable<MatterportComponent> {
    if (this._components[component.id])
      throw new Error(`Note with id = ${component.id} already exist`);
    return this._componentRegistered.asObservable().pipe(
      filter((registered: boolean) => registered),
      switchMap(() =>
        from(this.sdk.Scene.createObjects(1)).pipe(
          map(([object]: MpSdk.Scene.IObject[]) => {
            const node = object.addNode();
            const comp = node.addComponent(
              rootNoteType,
              component as Inputs
            ) as MpSdk.Scene.IComponent & CustomMatteportComponent;
            this._registerClickEvent(comp);
            node.start();
            if (comp.layer) comp.layer = 9;

            comp.drawStem();

            return {
              object,
              node,
              comp,
            } as MatterportTagComponentInterface<CustomMatteportComponent>;
          }),

          tap((createdComponent) => {
            this._components[component.id] = createdComponent;
            if (!this._raycasterHelper) this._initRaycaster(component.id);
          }),
          tap(() => {
            component.objects.forEach((obj: ChildConfiguration) => {
              this.addChildToExistingComponent(component.id, obj);
            });
          }),
          map((createdComponent) => {
            return {
              comp: createdComponent.comp,
              node: createdComponent.node,
              children: component.objects.map(
                (comp: ChildConfiguration) => comp.instance
              ),
            };
          }),
          catchError((e) => {
            console.log(e);
            return of(e);
          })
        )
      ),
      take(1)
    );
  }

  async addChildToExistingComponent(
    componentId: string,
    objectConfiguration: ChildConfiguration
  ) {
    if (!this._components[componentId])
      throw new Error(`Note with id = ${componentId} does not exist`);
    objectConfiguration.init(this._components[componentId].comp.three);

    if (objectConfiguration.instance.object3D)
      objectConfiguration.instance.object3D.name = componentId;
    this._components[componentId].comp.addChild(
      objectConfiguration.instance.object3D,
      objectConfiguration.instance instanceof BlueprintComponent
    );
    const currentMode = await firstValueFrom(
      this.matterportEvents.currentMode$
    );
    currentMode;
    if (
      currentMode === ViewMode.DOLLHOUSE ||
      currentMode === ViewMode.FLOORPLAN
    ) {
      this._components[componentId].comp.lookAt = true;
      this._components[componentId].comp.viewMode = currentMode;
    }
  }

  private _registerClickEvent(
    comp: MpSdk.Scene.IComponent & CustomMatteportComponent
  ) {
    comp.notify = (
      eventType: ComponentInteractionType,
      eventData?: TagComponentClickData
    ) => this._eventListener(eventType, eventData);
  }
  private _eventListener(
    eventType: ComponentInteractionType,
    eventData: TagComponentClickData | undefined
  ): void {
    if (
      this._disableClickEvents === false &&
      eventType === ComponentInteractionType.CLICK
    ) {
      if (eventData) {
        this._componentClicked.next({
          id: eventData.collider.name,
          userData: eventData.userData,
        });
        // this._selectedComponent.next(eventData.collider.name);
        this.selectedNote = eventData.collider.name;
      }
    }
    if (eventType === ComponentInteractionType.HOVER) {
      this.matterportManager.componentRef.instance.pointerEvents(
        eventData?.hover || false
      );
    }
  }

  deleteNote(noteId: string) {
    if (this._components[noteId]) {
      this._components[noteId].node.stop();
      this._components[noteId].object.stop();
      delete this._components[noteId];
      this.selectedNote = undefined;
      return noteId;
    }
    return '';
  }

  deleteNote$(noteId: string): Observable<string> {
    return of(this.deleteNote(noteId));
  }

  updateNote$(
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ): Observable<void> {
    return of(this.updateNote(component));
  }
  updateNote(
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ) {
    if (this._components[component.id]) {
      const inputs = this._components[component.id].comp;

      inputs.inputs.normal = component.normal as unknown as Vector3;
      inputs.inputs.stemHeight = component.stemHeight;
      inputs.position = component.position as unknown as Vector3;
    }
    // if (this._components[component.id]) {
    //   const inputs = this._components[component.id].comp;
    //   // if (note.noteType) inputs.icon = note.noteType;
    //   if (component.position)
    //     inputs.position = component.position as unknown as Vector3;
    //   if (component.normal)
    //     inputs.normal = component.normal as unknown as Vector3;
    //   if (component.position)
    //     inputs.stem = component.stem as unknown as Vector3;
    // }
  }
  updatePositionWithOffset$(
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) {
    return of(this.updatePositionWithOffset(component));
  }
  updatePositionWithOffset(
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) {
    this.updateNote({
      ...component,
      ...moveThreejsPositionByOffset(component, this.transformConverter),
    });
  }

  private _selectedNoteVisibilityObserver() {
    combineLatest([
      this._selectedComponent.asObservable(),
      this._cameraPoseChange.asObservable(),
    ])
      .pipe(
        throttleTime(200),
        tap(([noteId]) => {
          if (noteId) this._setComponentOpacity(noteId);
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }
  private _cameraPoseObserver() {
    this._cameraPoseSubscription = this.sdk.Camera.pose.subscribe(() => {
      this._cameraPoseChange.next();
    });
  }

  private _matterportViewStateObserver() {
    this.sdk.on(this.sdk.Mode.Event.CHANGE_END, this._viewModeChangeCallback);
  }

  private _viewModeChangeCallback = (currentMode: string, newMode: string) => {
    if (newMode === ViewMode.DOLLHOUSE || newMode === ViewMode.FLOORPLAN) {
      Object.values(this._components).forEach((component) => {
        component.comp.lookAt = true;
        component.comp.viewMode = newMode;
      });
    } else {
      Object.values(this._components).forEach((component) => {
        component.comp.lookAt = false;
        component.comp.viewMode = newMode as ViewMode;
      });
    }
  };
  private _setComponentOpacity(noteId: string) {
    const intersectObjects = this.componentIntersect(noteId);
    if (intersectObjects.length > 0) {
      const element = intersectObjects.find(
        (element) => element.object.name === noteId
      );
      if (element) {
        const indexOfElement = intersectObjects.indexOf(element);
        if (indexOfElement > 0) {
          this.setOpacity(noteId, 0.35);
          return;
        }
      }
      this.setOpacity(noteId, 1);
    }
  }

  private _initRaycaster(noteId: string) {
    const camera = this._components[noteId].comp.camera;
    const scene = this._components[noteId].comp.scene;
    this._raycasterHelper = new RaycasterHelper(camera, scene);
  }
  componentIntersect(noteId: string) {
    if (!this._components[noteId]) return [];
    const cameraPosition =
      this._components[noteId].comp.cameraContainer.position;
    const notePosition = this._components[noteId].comp.position;
    if (!this._raycasterHelper) {
      this._initRaycaster(noteId);
    }
    return this._raycasterHelper.getIntersectObjects(
      cameraPosition,
      notePosition
    );
  }
}
