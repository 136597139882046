import {
  ComponentRef,
  Injectable,
  Injector,
  LOCALE_ID,
  NgZone,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { ICustomComponent } from '@simlab/simlab-facility-management/scene-object';
import { TransformConverter } from '@simlab/transform';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  filter,
  from,
  fromEvent,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

import { ICONS, SupportedLinks } from '@simlab/simlab-facility-management/common';
import { IViewManager } from '@simlab/simlab-facility-management/features/models';
import { IBlueprint } from '@simlab/simlab-facility-management/sub-features/blueprint';
import { TMeasurementMesh } from '@simlab/simlab-facility-management/sub-features/line-measurement';
import {
  IMeasurementTool,
  TAreaMesh,
} from '@simlab/simlab-facility-management/sub-features/measurement';
import {
  ConnectOptions,
  MpSdk,
  ShowcaseBundleWindow,
} from '../../../assets/bundle/sdk';
import { MatterportUtils } from '../base/matterport-utils';
import { MatterportIframeComponent } from '../components/matterport-iframe/matterport-iframe.component';
import { TDollhouseManager } from '../models/dollhouse.model';
import { IPositionController } from '../models/dto';
import { IMatterportEvents } from '../models/events.interface';
import { MatterportConfig } from '../models/matteport-config';
import { CustomPhases, MatterportScanStatus } from '../models/matterport-data';
import { MatterportComponent } from '../models/matterport-tag-component.type';
import { IMattertags } from '../models/mattertags.interface';
import { IObjectLoader } from '../models/object-loader.type';
import { IPortals } from '../models/portal';
import { ISceneState } from '../models/scene-state.interface';
import { LineMeasurementComponent } from './line-measurement-tool.service';
import { MatterportPortalsService } from './matterport-portals.service';
import { MeasurementComponent } from './measurement-tool.service';

declare const ngDevMode: boolean;

export interface IMatterportFeatures extends IViewManager {
  transformConverter: TransformConverter;
  readonly getTransformConverter: () => TransformConverter;
  get position(): IPositionController;
  get tags(): IMattertags;
  get portal(): IPortals;
  get events(): IMatterportEvents;
  get objects(): IObjectLoader;
  get blueprint(): IBlueprint<MatterportComponent>;
  get state(): ISceneState;
  get component(): ICustomComponent<MatterportComponent>;
  get lineMeasurementMesh(): IMeasurementTool<
    LineMeasurementComponent,
    TMeasurementMesh
  >;
  get areaMesh(): IMeasurementTool<MeasurementComponent, TAreaMesh>;
}
@Injectable()
export class MatterportManagerService
  extends MatterportUtils
  implements IMatterportFeatures {
  private readonly config: MatterportConfig = inject(MatterportConfig);
  private readonly ngZone: NgZone = inject(NgZone);
  private readonly injector: Injector = inject(Injector);
  private readonly _appId = inject(LOCALE_ID);
  protected _sdk: MpSdk | undefined;
  private _componentRef: ComponentRef<MatterportIframeComponent> | undefined;
  private _transformConverter!: TransformConverter;
  private _showMattertags = false;

  private readonly _sdkEmitter: BehaviorSubject<MpSdk | undefined> =
    new BehaviorSubject<MpSdk | undefined>(undefined);

  private readonly _destroy: Subject<void> = new Subject<void>();
  private readonly _hasMatterport: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _scanLoaded: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _currentPhase: BehaviorSubject<MpSdk.App.Phase | undefined> =
    new BehaviorSubject<MpSdk.App.Phase | undefined>(undefined);
  private _retry = 0;
  readonly scanLoaded$: Observable<boolean> = this._scanLoaded.asObservable();


  public get hasMatterport$(): Observable<boolean> {
    return this._hasMatterport.asObservable();
  }
  public get showMattertags() {
    return this._showMattertags;
  }
  public set showMattertags(value) {
    this._showMattertags = value;
  }

  public set transformConverter(value: TransformConverter) {
    this._transformConverter = value;
  }
  public get transformConverter(): TransformConverter {
    return this._transformConverter;
  }

  public get sdk$(): Observable<MpSdk | undefined> {
    return this._sdkEmitter.asObservable();
  }
  public get componentRef(): ComponentRef<MatterportIframeComponent> {
    return this._componentRef as ComponentRef<MatterportIframeComponent>;
  }
  checkInstanceExist() {
    if (!this._componentRef?.instance)
      throw new Error(
        'Open scan first or enable feature in openScan configuration'
      );
  }
  get position(): IPositionController {
    this.checkInstanceExist();
    return this.componentRef.instance.matterportPositionController;
  }
  get tags(): IMattertags {
    this.checkInstanceExist();
    return this.componentRef.instance.matterportTags;
  }
  get lineMeasurementMesh(): IMeasurementTool<
    LineMeasurementComponent,
    TMeasurementMesh
  > {
    this.checkInstanceExist();
    return this.componentRef.instance.lineMeasurementTool;
  }

  get portal(): IPortals {
    this.checkInstanceExist();
    return this.componentRef.instance.matterportPortalManagerService;
  }
  get dollHouse(): TDollhouseManager {
    this.checkInstanceExist();
    return this.componentRef?.instance?.dollHouseService;
  }

  get events(): IMatterportEvents {
    this.checkInstanceExist();
    return this.componentRef.instance.matterportEvents;
  }
  get objects(): IObjectLoader {
    this.checkInstanceExist();
    return this.componentRef.instance.objectLoaderService;
  }
  get state(): ISceneState {
    this.checkInstanceExist();
    return this.componentRef.instance.matterportSceneStateAccess;
  }
  get component(): ICustomComponent<MatterportComponent> {
    this.checkInstanceExist();
    return this.componentRef.instance.matterportCustomComponentService;
  }
  get blueprint(): IBlueprint<MatterportComponent> {
    this.checkInstanceExist();
    return this.componentRef.instance.blueprintService;
  }
  get areaMesh(): IMeasurementTool<MeasurementComponent, TAreaMesh> {
    this.checkInstanceExist();
    return this.componentRef.instance.measurementToolService;
  }

  create(
    containerRef: ViewContainerRef,
    enablePortals: boolean
  ): ComponentRef<any> {
    const portals = enablePortals
      ? [
        {
          provide: MatterportPortalsService,
          deps: [NgZone],
        },
      ]
      : [];
    const injector = Injector.create({
      parent: this.injector,
      providers: [...portals],
    });
    this._componentRef = containerRef.createComponent(
      MatterportIframeComponent,
      {
        injector,
      }
    );
    this._hasMatterport.next(true);

    return this._componentRef;
  }

  destroy(): void {
    if (this._componentRef) {
      try {
        this.component?.clearAllNotes();
      } catch (e) {
        console.log(e);
      }
      this._componentRef.destroy();
    }

    this._sdk?.disconnect();
    this._sdkEmitter.next(undefined);
    this._destroy.next();
    this._destroy.complete();
    this._hasMatterport.next(false);
    this._currentPhase.next(undefined);
    this._sdk = undefined;
    this._scanLoaded.next(false);
    this._componentRef = undefined;
  }
  getTransformConverter() {
    return this.transformConverter;
  }
  createAndOpenScan$(
    containerRef: ViewContainerRef,
    matterportScanId: string,
    matterportOffset?: string,
    showMattertags: boolean = false,
    language: SupportedLinks = SupportedLinks.EN,
    enablePortals: boolean = false
  ): Observable<MatterportScanStatus> {
    localStorage.setItem('language', SupportedLinks[language]);
    this._showMattertags = showMattertags;
    if (
      this._hasMatterport.value !== false ||
      this._currentPhase.value !== undefined
    ) {
      this.destroy();
    }
    this.create(containerRef, enablePortals);
    return this._openScan$(matterportScanId, matterportOffset);
  }

  private _openScan$(
    matterportScanId: string,
    matterportOffset: string = ''
  ): Observable<MatterportScanStatus> {
    this.transformConverter = new TransformConverter(matterportOffset);
    if (!this._componentRef)
      return of('appphase.error' as MatterportScanStatus);
    this._componentRef.instance.url = this._constructUrl(matterportScanId);
    this._componentRef.instance.hint = this.hint.asObservable();
    this._componentRef.instance.hintObserver();
    return fromEvent(
      this._componentRef.instance.iframeRef.nativeElement,
      'load'
    ).pipe(
      mergeMap(() => this._sdkConnect$()),

      tap((sdk: MpSdk) => {
        this._sdk = sdk;
        this._phaseObserver(sdk);
      }),

      switchMap((sdk: MpSdk) =>
        this._currentPhase.asObservable().pipe(
          filter(
            (phase: MpSdk.App.Phase | MatterportScanStatus | undefined) =>
              phase !== undefined
          ),
          switchMap(
            (phase: MpSdk.App.Phase | MatterportScanStatus | undefined) =>
              iif(
                () => phase !== CustomPhases.ERROR /* &&
                  phase !== CustomPhases.FORBIDDEN &&
                  phase !== CustomPhases.NOTFOUND*/,
                of(true).pipe(
                  switchMap(() =>
                    from(
                      sdk.App.state.waitUntil((state: MpSdk.App.State) => {
                        return state.phase === sdk.App.Phase.PLAYING;
                      })
                    )
                  ),
                  switchMap(() => this.registerIcons$(ICONS)), //NOTE: remove later..
                  map(() => phase)
                ),
                throwError(() => phase)
              )
          ),
          tap(() => {
            this._attachStyles();
            this._sdkEmitter.next(sdk);
          }),
          take(1)
        )
      ),
      tap(() => this._scanLoaded.next(true)),
      catchError((error: any) => {
        console.log(error);
        this._scanLoaded.next(false);
        return of(error);
      }),
      takeUntil(this._destroy),
      take(1)
    );
  }

  private _attachStyles(): void {
    const { hideFullscreen, hideHelp } = this.config;

    if (!(hideFullscreen || hideHelp)) {
      return;
    }

    const elements = [];

    elements.push('.model-view-container');

    if (hideFullscreen) {
      elements.push('.fullscreen-mode');
    }

    if (hideHelp) {
      elements.push('#footer > :nth-child(1), #footer > :nth-child(2)');
    }

    const matterportDocument = this._getMatterportDocument();
    const style = matterportDocument.createElement('style');
    style.textContent = `
    ${elements.join(',')}
    { display: none !important; }
    `;

    matterportDocument.head.appendChild(style);
  }

  private _getMatterportDocument(): Document {
    return this._componentRef?.instance.iframeRef.nativeElement.contentWindow
      .document;
  }

  private _getCollapsibleListItems(): Element[] {
    const matterportDocument = this._getMatterportDocument();

    return Array.from(
      <HTMLCollection>(
        matterportDocument?.getElementsByClassName('collapsible-list-items')[0]
          ?.children
      )
    );
  }

  private _measurementsButton(
    elements: Element[],
    callback: (active: boolean) => void
  ): void {
    const measurementsButton = this._findElementByClass(
      elements,
      'showcase-measurements-button'
    );

    //TODO: remove that listener when matterport change!
    measurementsButton?.firstChild?.addEventListener(
      'click',
      (element: Event) => {
        const isButton = this._hasClass(<Element>element.target, 'mp-nova-btn');
        const active = this._hasClass(
          isButton
            ? <Element>element.target
            : <Element>(element.target as Element).parentElement,
          'mp-nova-btn-active'
        );

        //NOTE: we have to push that to the end of the event queue
        return setTimeout(() => callback(active), 0);
      }
    );
  }

  private _hideFullscreenButton(elements: Element[]): void {
    if (this.config.hideFullscreen) {
      const fullscreenButton = this._findElementByClass(
        elements,
        'fullscreen-mode'
      );

      if (fullscreenButton) {
        this._hideElement(<HTMLElement>fullscreenButton);

        if (typeof ngDevMode === 'undefined' || ngDevMode) {
          console.log(
            '%c Fullscreen Button has been removed!',
            'background-color: yellow; color: purple;'
          );
        }
      }
    }
  }

  private _findElementByClass(
    elements: Element[],
    token: string
  ): Element | undefined {
    return elements.find((element: Element) =>
      element.classList.contains(token)
    );
  }

  private _hasClass(element: Element, token: string): boolean {
    return element.classList.contains(token);
  }

  private _hideElement(element: HTMLElement): void {
    element.style.display = element.style.pointerEvents = 'none';
  }

  private _phaseObserver(sdk: MpSdk): void {
    sdk.on(sdk.App.Event.PHASE_CHANGE, (appState) => {
      this._currentPhase.next(appState);
    });
  }

  /**
   * @description
   * @link https://support.matterport.com/s/article/URL-Parameters?language=en_US
   */
  private _constructUrl(scanId: string): string {
    const baseParams =
      '&title=0&play=1&mls=2&pin=0&search=0&qs=1&help=0&gt=0&brand=0&portal=0';

    return `assets/bundle/showcase.html?m=${scanId}&applicationKey=${this.config.key
      }${baseParams}${this.config.additionalParams ?? ''}&lang=${this._appId}`;
  }

  private _sdkConnect$(): Observable<MpSdk> {
    const nativeElement = this._componentRef?.instance.iframeRef.nativeElement;
    const contentWindow = (nativeElement as HTMLIFrameElement).contentWindow;

    const connectOptions: Partial<ConnectOptions> = {};
    if (sessionStorage.getItem('matterport-token')) {
      connectOptions.auth =
        sessionStorage.getItem('matterport-token') || undefined;
    }
    return this.ngZone.runOutsideAngular<Observable<MpSdk>>(() => {
      return from(
        (contentWindow as ShowcaseBundleWindow).MP_SDK.connect(
          nativeElement,
          connectOptions
        )
      ) as Observable<MpSdk>;
    });
  }
}
