@if(templateData(); as templateData){
<sim-sidenav-container>
  <sim-sidenav mode="over" #sidenavLeft position="left" class="left-menu">
    <ng-content></ng-content>
  </sim-sidenav>
  <sim-sidenav mode="over" #sidenavRight position="right" class="left-menu">
    <ng-template #outletRight="cdkPortalOutlet" [cdkPortalOutlet]="selectedPortal"></ng-template>
  </sim-sidenav>
  <sim-sidenav-content>
    <div
      [class]="templateData.breakpoint"
      class="sim-page-container"
      [class.toolbar-logo]="!templateData.toolbar?.navLinks"
    >
      <common-navbar class="navbar">
        @if(templateData.toolbar?.navLinks){
        <button id="sidenav-open" sim-icon-button class="navbar__sidenav" (click)="sidenav.toggle()">
          <sim-icon icon="grid" color="#707070" sizeType="large"></sim-icon>
        </button>
        <div class="navbar__title">
          {{ templateData.toolbar?.headerTitle }}
        </div>
        <horizontal-scroll>
          <nav class="navbar__links">
            @for(nav of templateData.toolbar?.navLinks; let i = $index; track $index){
            <a
              id="nav-bar-link-{{ i }}"
              sim-button
              [routerLink]="nav.path"
              routerLinkActive="active-link"
              [class.inactive-link]="activeRouterLink !== nav.name"
              (isActiveChange)="changeActiveRouterLink($event, nav.label)"
              [class.invisible-nav]="nav.isMobileInvisible"
              [class.sim-disabled]="nav.hasPrivilege === false"
              ><span class="navbar__links__container"
                ><sim-icon
                  [icon]="nav.icon || 'lights'"
                  [sizeType]="templateData.breakpoint === 'tablet' ? 'small' : 'medium'"
                  [color]="activeRouterLink !== nav.name ? '#A3A3A3' : 'var(--ui-primary-default)'"
                ></sim-icon>
                <span class="navbar__links__container--link">{{ nav.label }}</span></span
              >
            </a>
            }
          </nav>
        </horizontal-scroll>
        } @else {
        <img class="logo" src="assets/images/logo/Logo_simon.svg" />
        }
        <div class="navbar__user">
          <user-online-list [lightMode]="false" />
          <user-logged-info />
          <sim-fullscreen [lightMode]="false" />
        </div>
      </common-navbar>
      <common-content class="content" [style]="templateData.hideBottomToolbar && { '--temp-toolbar-h': 0 }">
        <router-outlet></router-outlet>
      </common-content>
    </div>
  </sim-sidenav-content>
</sim-sidenav-container>
}
