/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { _OptionBase } from '../../directives/options-base.directive';

@Component({
  selector: 'sim-option',
  exportAs: 'simOption',
  host: {
    role: 'option',
    '[attr.tabindex]': '_getTabIndex()',
    '[class.selected]': 'selected',
    '[class.option-multiple]': 'multiple',
    '[class.active]': 'active',
    '[id]': 'id',
    '[attr.aria-selected]': '_getAriaSelected()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '[class.option-disabled]': 'disabled',
    '(click)': '_selectViaInteraction()',
    '(keydown)': '_handleKeydown($event)',
    class: 'sim-option focus-indicator',
    '[class.status-option]': 'status()',
    '[class.error]': `status() === 'error'`,
    '[class.success]': `status() === 'success'`,
    '[class.warning]': `status() === 'warning'`,
    '[class.info]': `status() === 'info'`
  },
  styleUrls: ['option.component.scss'],
  templateUrl: 'option.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionComponent extends _OptionBase {}
