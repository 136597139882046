import {
  BehaviorSubject,
  forkJoin,
  from,
  map,
  mapTo,
  Observable,
  of,
  take,
} from 'rxjs';
import { Vector3 } from 'three';
import { MpSdk, Renderer, Size } from '../../../assets/bundle/sdk';
import { ISceneState } from '../models/scene-state.interface';
import { TTagNoteKeys } from '@simlab/simlab-facility-management/common';

export abstract class MatterportUtils {
  protected abstract _sdk: MpSdk | undefined;
  abstract state: ISceneState;
  protected hint: BehaviorSubject<
    | {
        hintMessage: string | undefined | Element;
        position:
          | {
              top?: string | undefined;
              bottom?: string | undefined;
              left?: string | undefined;
              right?: string | undefined;
            }
          | undefined;
        backgroundColor?: string;
        fontColor?: string;
      }
    | undefined
  > = new BehaviorSubject<
    | {
        hintMessage: string | undefined | Element;
        position:
          | {
              top?: string | undefined;
              bottom?: string | undefined;
              left?: string | undefined;
              right?: string | undefined;
            }
          | undefined;
      }
    | undefined
  >(undefined);

  worldToScreen(
    position: Vector3,
    windowSize: Size
  ): Observable<Vector3 | undefined> {
    return this.state.positionChange$.pipe(
      take(1),
      map((pose: MpSdk.Camera.Pose | null) => {
        if (pose && this._sdk) {
          const { x, y, z } = this._sdk.Conversion.worldToScreen(
            position,
            pose,
            windowSize
          );
          return new Vector3(x, y, z);
        } else {
          return undefined;
        }
      })
    );
  }

  setHint(
    hintMessage: string | undefined | Element,
    position:
      | {
          top?: string | undefined;
          bottom?: string | undefined;
          left?: string | undefined;
          right?: string | undefined;
        }
      | undefined,
    backgroundColor?: string,
    fontColor?: string
  ) {
    this.hint.next({ hintMessage, position, backgroundColor, fontColor });
  }

  screenshot(
    resolution: Renderer.Resolution = {
      height: 800,
      width: 600,
    },
    visibility: Partial<Renderer.Visibility> = {
      mattertags: true,
      sweeps: false,
    }
  ): Observable<string | undefined> {
    if (this._sdk) {
      return from(this._sdk.Renderer.takeScreenShot(resolution, visibility));
    } else {
      return of(undefined);
    }
  }

  registerIcons$(icon_collection: Record<string, string>): Observable<void> {
    const icons = [];
    if (!this._sdk) return of(undefined);
    for (const key of Object.keys(icon_collection)) {
      icons.push(
        from(
          this._sdk.Mattertag.registerIcon(
            key,
            icon_collection[key as TTagNoteKeys]
          )
        )
      );
    }
    return forkJoin(icons).pipe(mapTo(undefined));
  }

  registerIcons(icon_collection: Record<string, string>): void {
    for (const key of Object.keys(icon_collection)) {
      this._sdk?.Mattertag.registerIcon(
        key,
        icon_collection[key as TTagNoteKeys]
      );
    }
  }
}
