import { AssetHyperlinks, EAssetCategory, TRecurringEventTime } from '@simOn/asset/modifying/models';
import { AssetCategoryEnum } from '@simOn/common/categories';
import { valueof } from '@simOn/common/helpers';
import { TransformationInterface } from '@simOn/common/matterport';

export interface AssetResponseInterface {
  asset: AssetCategoryIconType;
  id: string;
  name: string;
  apartmentId: string;
  // apartment?: ApartmentInterface;
  roomId: string;
  // room?: RoomInterface;
  category?: EAssetCategory | null | undefined;
  textNote: string;
  hyperlinks: AssetHyperlinks[];
  attachedImages: AssetAttachment[];
  attachedDocuments: AssetAttachment[];
  events: AssetEvent[];
  transformation: TransformationInterface | undefined;
  positionDeleted?: boolean;
  matterTagContent: string | undefined;
  roomName?: string;
  containsBlockedAttachments: boolean;
}

export interface UpdateAsset {
  id: string;
  roomId: string;
  category?: string;
  name: string;
  textNote?: string;
  uploadedFiles?: string[];
  hyperlinks?: AssetHyperlinks[];
  events?: AssetEvent[];
  transformation?: TransformationInterface;
  positionDeleted?: boolean;
  matterTagContent?: string;
}

export type AssetIconsType =
  | 'Documents'
  | 'Ornaments'
  | 'Construction'
  | 'FinishingMaterials'
  | 'Equipment'
  | 'Art'
  | 'Plants'
  | 'Pipes'
  | 'Hobby'
  | 'Electric'
  | 'Furniture'
  | 'Other';

export interface AssetsCategoryInterface {
  name: AssetIconsType;
  icon: string;
}

export const AssetsCategory: AssetsCategoryInterface[] = [
  { name: 'Art', icon: 'Circle_Art.svg' },
  { name: 'Construction', icon: 'Circle_Construction.svg' },
  { name: 'Documents', icon: 'Circle_documents.svg' },
  { name: 'Electric', icon: 'Circle_Electric.svg' },
  { name: 'Equipment', icon: 'Circle_Equipment.svg' },
  { name: 'FinishingMaterials', icon: 'Circle_Finishing_materials.svg' },
  { name: 'Furniture', icon: 'Circle_Furniture.svg' },
  { name: 'Hobby', icon: 'Circle_Hobby.svg' },
  { name: 'Ornaments', icon: 'Circle_Ornaments.svg' },
  { name: 'Other', icon: 'Circle_Other.svg' },
  { name: 'Pipes', icon: 'Circle_Pipes.svg' },
  { name: 'Plants', icon: 'Circle_Plants.svg' }
];

export interface AssetsTagEntityInterface {
  [key: string]: string;
}

export interface AssetCreateInterface {
  id?: string; // for update action
  //do sprawdzenia zgodnie z starym interface
  apartmentId?: string;
  roomId: string;
  name: string;
  textNote?: string;
  uploadedFiles?: any;
  category?: string;

  position?: {
    x: number;
    y: number;
    z: number;
  };
  matterTagContent?: string;
}

export interface AssetAttachment {
  id: string;
  blobPath: string;
  name: string;
  isBlockedBySubscriptionLimit: boolean;
}

export type AssetEvent = {
  id: string; //Sprawdzić czy nie psuje
  dateTime: string;
  content: string;
  recurringEventType?: TRecurringEventTime;
};

export interface GetAssetsQueryParamsInterface {
  roomGuid?: string;
  FetchLong?: boolean;
  Category?: AssetCategoryEnum;
}

export interface AssetHyperlinksInterface {
  assetGuid: string;
  hyperlinks: AssetHyperlinks[];
}

export interface HyperlinkInterface {
  link: string;
  type: string;
}

export interface AddNewAssetModalResultInterface {
  action: AddNewAssetModalActions;
  payload?: AssetResponseInterface;
}

export type AddNewAssetModalActions = 'save' | 'close';

export type AssetModalActions =
  | 'createAssetMarker'
  | 'deleteAssetMarker'
  | 'goToAssetTag'
  | 'goToAssetDetails'
  | 'close';
export interface AssetModalResultInterface {
  action: AssetModalActions;
  payload?: {
    asset?: AssetResponseInterface;
  };
}

export interface AssetsListFilterFormInterface {
  assetCategory: string;
  search: string;
}

export interface AssetFormValueInterface {
  name: string;
  room: string;
  assetCategory: string;
}

export const AssetCategoryCollection: AssetCategoryCollectionInterface[] = [
  { value: 'Documents', name: $localize`:@@GENERAL_ASSET_CATEGORY_DOCUMENTS:Documents`, icon: 'documents' },
  { value: 'Ornaments', name: $localize`:@@GENERAL_ASSET_CATEGORY_ORNAMENTS:Ornaments`, icon: 'ornaments' },
  { value: 'Construction', name: $localize`:@@GENERAL_ASSET_CATEGORY_CONSTRUCTION:Construction`, icon: 'construction' },
  {
    value: 'FinishingMaterials',
    name: $localize`:@@GENERAL_ASSET_CATEGORY_FINISHING_MATERIALS:Finishing Materials`,
    icon: 'finishingmats'
  },
  { value: 'Equipment', name: $localize`:@@GENERAL_ASSET_CATEGORY_EQUIPMENT:Equipment`, icon: 'equipment' },
  { value: 'Art', name: $localize`:@@GENERAL_ASSET_CATEGORY_ART:Art`, icon: 'art' },
  { value: 'Plants', name: $localize`:@@GENERAL_ASSET_CATEGORY_PLANTS:Plants`, icon: 'plants' },
  { value: 'Pipes', name: $localize`:@@GENERAL_ASSET_CATEGORY_PIPES:Pipes`, icon: 'pipes' },
  { value: 'Hobby', name: $localize`:@@GENERAL_ASSET_CATEGORY_HOBBY:Hobby`, icon: 'hobby' },
  { value: 'Electric', name: $localize`:@@GENERAL_ASSET_CATEGORY_ELECTRIC:Electric`, icon: 'electric' },
  { value: 'Furniture', name: $localize`:@@GENERAL_ASSET_CATEGORY_FURNITURE:Furniture`, icon: 'furniture' },
  { value: 'Other', name: $localize`:@@GENERAL_ASSET_CATEGORY_OTHER:Other`, icon: 'assetOtherIcon' }
];

export const AssetCategoryEntity: AssetCategoryEntityInterface = AssetCategoryCollection.reduce(
  (acc: AssetCategoryEntityInterface, el: AssetCategoryCollectionInterface) => {
    acc[el.value] = el;
    return acc;
  },
  {} as AssetCategoryEntityInterface
);

export interface AssetCategoryCollectionInterface {
  value: AssetCategoryKeyType;
  name: AssetCategoryValueType;
  icon: AssetCategoryIconType;
}

export type AssetCategoryEntityInterface = {
  [key in AssetCategoryKeyType]: AssetCategoryCollectionInterface;
};

export type AssetCategoryIconType =
  | 'documents'
  | 'ornaments'
  | 'construction'
  | 'finishingmats'
  | 'equipment'
  | 'art'
  | 'plants'
  | 'pipes'
  | 'hobby'
  | 'electric'
  | 'furniture'
  | 'assetOtherIcon';

export type AssetCategoryKeyType = keyof typeof AssetCategoryEnum;
export type AssetCategoryValueType = valueof<AssetCategoryEnum>;

export interface AssetMatterTagInterface {
  id?: string;
  roomId: string;
  category?: string;
  name: string;
  textNote?: string;
  icon?: string | undefined;
  uploadedFiles?: string[];
  hyperlinks?: AssetHyperlinks[];
  attachments?: AssetAttachment[];
  events?: AssetEvent[];
  transformation?: TransformationInterface;
  positionDeleted?: boolean;
  matterTagContent?: string;
  stagesImportedNoteId?: string;
  matterTagId?: string;
  scanModelId?: string;
  scanId?: string;
}

export type TUpdateAssetFileName = {
  id: string;
  name: string;
};

export const UpdateAssetFile = {
  autoDetect: 1,
  image: 2,
  document: 3
} as const;

export type TUpdateAssetFile = keyof typeof UpdateAssetFile;

export const RecurringEventTime: Record<RecurringEventTimeType, TRecurringEventTimeValue> = {
  Day: { label: $localize`:@@GENERAL_DAY:Day`, value: 'Day' },
  Week: { label: $localize`:@@GENERAL_WEEK:Week`, value: 'Week' },
  Month: { label: $localize`:@@GENERAL_MONTH:Month`, value: 'Month' },
  Year: { label: $localize`:@@GENERAL_YEAR:Year`, value: 'Year' }
};

export type RecurringEventTimeType = 'Day' | 'Week' | 'Month' | 'Year';

export type TRecurringEventTimeValue = { label: string; value: RecurringEventTimeType };

export interface UploadEventsInterface {
  dateTime: Date | string;
  content: string;
  recurringEventType: TRecurringEventTime;
}

export interface DeleteAssetIdInterface {
  id: string;
}

export interface DeleteAssetHyperlinkInterface {
  hyperlinkGuid: string[];
}

export interface DeleteUploadedEventsInterface {
  fileGuid: string[];
}
